import RCSlider from "rc-slider";
import { type FC } from "react";

import "./slider.scss";

interface SliderProps {
    className?: string;
    marks?: Record<number, string>;
    min?: number;
    max?: number;
    defaultValue?: number[];
    value?: number[];
    onChange?: (value: number[]) => void;
    step?: number;
    draggableTrack?: boolean;
}

export const Slider: FC<SliderProps> = ({
    className,
    marks,
    min,
    max,
    defaultValue,
    value,
    onChange,
    step,
    draggableTrack,
}) => {
    return (
        <div className={className}>
            <RCSlider
                range
                marks={marks}
                min={min}
                max={max}
                step={step}
                defaultValue={defaultValue}
                value={value}
                // @ts-ignore
                onChange={onChange}
                allowCross={false}
                draggableTrack={draggableTrack}
            />
        </div>
    );
};
