import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { VerificationsApi } from "../../../api/verificationsApi";
import { QueryKeys } from "../../../helpers";
import { type VerificationDto } from "../../../models";
import { Spinner } from "../../basic";

interface Props {
    verification: VerificationDto;
}

export const SingleVerificationFiles: FC<Props> = ({ verification }) => {
    const filesQuery = useQuery({
        queryKey: [QueryKeys.VERIFICATION_FILES, verification.verificationGuid],
        queryFn: () => VerificationsApi.getFiles(verification.verificationGuid),
    });

    if (filesQuery.isLoading) return <Spinner>Loading Files..</Spinner>;

    if (!filesQuery.data || filesQuery.data.length === 0) return <h2>No Files Found</h2>;

    return (
        <section>
            <div className="m-4 flex gap-4">
                {filesQuery.data?.map((fileLink) => (
                    <div key={fileLink.url} className="rounded-sm border-2 border-indigo-500 p-5 text-center">
                        <a href={fileLink.url} target="_blank" rel="noreferrer">
                            {fileLink.name}
                            {fileLink.ext === "png" && (
                                <div>
                                    <img
                                        src={fileLink.url + (fileLink.url.includes("?fileName=") ? "&sas=true" : "")}
                                        alt={fileLink.name}
                                    />
                                </div>
                            )}
                        </a>
                    </div>
                ))}
            </div>
        </section>
    );
};
