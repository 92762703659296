import { type ChangeEvent, type FC, type HTMLProps, type LegacyRef, useCallback } from "react";

import { styles } from "../../../helpers";

interface CheckboxProps extends HTMLProps<HTMLInputElement> {
    label?: string;
    changeHandler?: (checked: boolean, id: string) => void;
    inline?: boolean;
    ref?: LegacyRef<HTMLInputElement>;
}

export const Checkbox: FC<CheckboxProps> = (props) => {
    const { label, changeHandler, inline, ...rest } = props;

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => changeHandler && changeHandler(e.target.checked, e.target.id),
        [changeHandler],
    );

    return (
        <div className={!inline ? "flex flex-col justify-start" : "flex items-center"}>
            <label htmlFor={props.id} className="label">
                {label}
            </label>
            <input
                onChange={handleChange}
                {...rest}
                type="checkbox"
                className={styles(props.className ?? "", "checkbox")}
                ref={props.ref}
            />
        </div>
    );
};
