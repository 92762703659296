import { Severity, logger } from "../helpers";
import { doGet } from "./httpHandler";

const controller = "/api/Map";

export class MapApi {
    public static autocomplete = (input: string) => {
        try {
            return doGet<string[]>(controller, "Autocomplete", { input });
        } catch (e) {
            logger(Severity.Error, e);
        }
    };
}
