import { ChannelsDropdown } from "../../dropdowns/channelsDropdown";
import { ClientsDropdown } from "../../dropdowns/clientsDropdown";
import { StatesDropdown } from "../../dropdowns/statesDropdown";
import { type SegmentFilterProps } from "../types";

export default function SegmentFilters(props: SegmentFilterProps) {
    const { visible, filters, selectVal } = props;

    if (!visible) return null;

    return (
        <>
            <ClientsDropdown value={filters.clients} selectVal={selectVal} isMulti autoHide isClearable />
            <ChannelsDropdown value={filters.channels} selectVal={selectVal} isMulti autoHide isClearable />
            <StatesDropdown value={filters.states} selectVal={selectVal} isMulti autoHide isClearable />
        </>
    );
}
