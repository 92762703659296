import { type GridResult, type RepresentativeDto, type RepresentativesFilter } from "../models";
import { doGet, doPost } from "./httpHandler";

const controller = "/api/Representatives";

interface ToggleData {
    activate: boolean;
    ids: number[];
}

export class RepresentativesApi {
    public static getRepresentatives = (
        signal: AbortSignal,
        itemsPerPage = 10,
        currentPage = 1,
        filters?: Partial<RepresentativesFilter>,
    ) => {
        filters = filters || {};
        return doPost<GridResult<RepresentativeDto>, Partial<RepresentativesFilter>>(
            controller,
            "GetAll",
            { itemsPerPage, currentPage },
            filters,
            { signal },
        );
    };

    public static getRepresentative = (repId: string) => {
        return doGet<RepresentativeDto>(controller, "get", { repId });
    };

    public static getAllBasicInfo = () => {
        return doGet<RepresentativeDto[]>(controller, "GetAllBasicInfo");
    };

    public static saveRepresentative = (rep: RepresentativeDto) => {
        return doPost<RepresentativeDto, RepresentativeDto>(controller, "save", undefined, rep);
    };

    public static toggle = (data: ToggleData) => {
        return doPost<RepresentativeDto, ToggleData>(controller, "toggle", undefined, data);
    };
}
