import { type FC, useContext } from "react";

import { CheckInContext } from "../../contexts/checkInContext";
import { TextOrLoading } from "../basic/loading/textOrLoading";

interface CountsProps {
    title?: string;
    batchId: number;
}

export const Counts: FC<CountsProps> = (props) => {
    const { batchSummary, loading } = useContext(CheckInContext);

    return (
        <>
            <h2 className="headline">{props.title ? props.title : "Counts"}</h2>
            <dl className="card grid grid-cols-1 gap-2 sm:grid-cols-2">
                <dt className="title">Pages</dt>
                <dd className="subtitle">
                    <TextOrLoading text={batchSummary?.pages ?? 0} loading={loading} />
                </dd>
                <dt className="title">Good TPV</dt>
                <dd className="subtitle">
                    <TextOrLoading text={batchSummary?.goodSales ?? 0} loading={loading} />
                </dd>
                <dt className="title">Failed TPV</dt>
                <dd className="subtitle">
                    <TextOrLoading text={batchSummary?.noSales ?? 0} loading={loading} />
                </dd>
                <dt className="title">Missing TPV</dt>
                <dd className="subtitle">
                    <TextOrLoading text={batchSummary?.missing ?? 0} loading={loading} />
                </dd>
            </dl>
        </>
    );
};
