import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { type FC } from "react";
import { RouterProvider } from "react-router-dom";

import { AlertsBanner, ToastProvider } from "./components/basic";
import { Footer } from "./components/footer";
import { UserProvider } from "./contexts/userContext";
import { MS_IN, setCookieName } from "./helpers";
import { router } from "./routes";

function handleDarkMode() {
    if (
        localStorage.theme === "dark" ||
        (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
}
export const App: FC = () => {
    const queryClient = new QueryClient({
        defaultOptions: { queries: { staleTime: MS_IN.MINUTE * 5, retry: false } },
    });

    setCookieName("avlLoginPayload");
    handleDarkMode();

    return (
        <QueryClientProvider client={queryClient}>
            <UserProvider>
                <ToastProvider>
                    <AlertsBanner />
                    <RouterProvider router={router} />
                    <Footer />
                </ToastProvider>
            </UserProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};
