import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import { ReportingApi } from "../../../api/reportingApi";
import { QueryKeys } from "../../../helpers";
import { type ReportingFilter } from "../../../models";
import { ChartSkeleton, DATE_FORMAT } from "../../basic";
import { StackedBarChart } from "../../basic";

export interface Props {
    filterData: ReportingFilter;
}

export function AllDispositionsWidget(props: Props) {
    const { filterData } = props;
    const [axis, setAxis] = useState<string[]>([]);
    const [series, setSeries] = useState<{ name: string; data: number[] }[]>([]);

    const reportQuery = useQuery({
        queryKey: [QueryKeys.CAMPAIGN_REPORTS, filterData],
        queryFn: () => {
            return ReportingApi.getCampaignReportData(filterData);
        },
    });

    useEffect(() => {
        if (!reportQuery.data?.dispositionCategories) return;

        const axis = reportQuery.data.dispositionCategories.map((item) => format(item.date, DATE_FORMAT));
        const axisSet = new Set(axis);
        const newAxis = Array.from(axisSet);

        const map = new Map<string, number[]>();

        reportQuery.data?.dispositionCategories.forEach((item) => {
            // if this gets slow, we could do an index of binary search, but does not seem to currently be a problem
            const index = newAxis.indexOf(format(item.date, DATE_FORMAT));

            if (map.has(item.name)) {
                const counts = map.get(item.name);

                if (counts) counts[index]! += item.count;

                map.set(item.name, counts ?? []);
            } else {
                const counts = Array(newAxis.length).fill(0) as number[];
                counts[index] = item.count;
                map.set(item.name, counts);
            }
        });

        setAxis(newAxis);
        setSeries(Array.from(map.entries()).map(([name, count]) => ({ name, data: count })));
    }, [reportQuery.data?.dispositionCategories]);

    if (reportQuery.isLoading) return <ChartSkeleton />;

    if (!reportQuery.data) return null;
    if (!reportQuery.data.dispositionCategories || reportQuery.data.dispositionCategories.length === 0) return null;

    return <StackedBarChart id="all-dispositions-stacked-bar" title="All Dispositions" axis={axis} data={series} />;
}
