import { useQuery, useQueryClient } from "@tanstack/react-query";
import { type FC, useMemo, useState } from "react";

import { GridFiltersApi } from "../../api/gridFiltersApi";
import { QueryKeys } from "../../helpers";
import { type RepresentativeDto, type RepresentativesFilter } from "../../models";
import { Button, Dialog } from "../basic";
import { CreatePrintFormBatch } from "./createPrintFormBatch";
import { useToggleRepresentative } from "./useToggleRepresentative";

interface SelectedActionsProps {
    reps: RepresentativeDto[];
    filterData: RepresentativesFilter;
}

export const SelectedActions: FC<SelectedActionsProps> = (props) => {
    const [openGenerateForms, setOpenGenerateForms] = useState<boolean>(false);
    const activeReps = props.reps.filter((r) => r.active).map((r) => r.id);
    const inactiveReps = props.reps.filter((r) => !r.active).map((r) => r.id);
    const queryClient = useQueryClient();

    const campaignQuery = useQuery({ queryKey: [QueryKeys.CAMPAIGNS], queryFn: () => GridFiltersApi.getCampaigns() });

    const toggle = useToggleRepresentative();

    const campaignId = useMemo(() => {
        if (props.filterData?.campaigns?.length === 1) return props.filterData.campaigns[0];
        if (campaignQuery.data?.length === 1) return campaignQuery.data[0]?.value;
        return undefined;
    }, [campaignQuery.data, props.filterData?.campaigns]);

    const isLoading = !!queryClient.isMutating({ mutationKey: [QueryKeys.REPS_TOGGLE_MUTATION] });

    return (
        <>
            <div className="flex gap-2 p-4">
                {inactiveReps.length > 0 ? (
                    <Button isLoading={isLoading} variant="secondary" onClick={() => toggle(inactiveReps, true)}>
                        Activate
                    </Button>
                ) : null}
                {activeReps.length > 0 ? (
                    <Button isLoading={isLoading} variant="secondary" onClick={() => toggle(activeReps, false)}>
                        Deactivate
                    </Button>
                ) : null}
                {campaignId ? (
                    <Button isLoading={isLoading} variant="secondary" onClick={() => setOpenGenerateForms(true)}>
                        Generate Forms
                    </Button>
                ) : null}
            </div>
            <Dialog
                open={openGenerateForms}
                setOpen={() => undefined}
                title="Generate Print Form"
                body={
                    <CreatePrintFormBatch
                        repIds={props.reps.map((r) => r.id)}
                        setOpen={setOpenGenerateForms}
                        create
                        campaignId={campaignId}
                    />
                }
            />
        </>
    );
};
