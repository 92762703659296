import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { type FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AuditApi } from "../api/auditApi";
import { FormApi } from "../api/formApi";
import { Counts } from "../components/audit/counts";
import { MultiAudit } from "../components/audit/multiAudit";
import { SingleAudit } from "../components/audit/singleAudit";
import { BarcodeScanner } from "../components/barcode/barcodeScanner";
import { Button, useErrorToast } from "../components/basic";
import { QueryKeys } from "../helpers";
import { type AuditScanResponseDto } from "../models";

export const AuditPage: FC = () => {
    const { id } = useParams<{ id: string }>();
    const [barcode, setBarcode] = useState<string>("");
    const [input, setInput] = useState<string>("");
    const [results, setResults] = useState<AuditScanResponseDto | undefined>(undefined);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const errorToast = useErrorToast();
    const batchId = useMemo(() => parseInt(id ?? "0"), [id]);

    const campaignQuery = useQuery({
        queryKey: [QueryKeys.CAMPAIGN, batchId],
        queryFn: () => FormApi.getCampaignByBatchId(batchId),
    });

    const summaryQuery = useQuery({
        queryKey: [QueryKeys.AUDIT_BATCH_SUMMARY, batchId, barcode],
        queryFn: () => {
            if (!batchId) throw new Error("No batch id provided");

            return AuditApi.getBatchSummary({ batchId });
        },
    });

    const auditMutation = useMutation({
        mutationFn: (code: string) => {
            if (!batchId) throw new Error("No batch id provided");

            return AuditApi.scan({ batchId, formLinkGuid: code });
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AUDIT] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AUDIT_BATCH_SUMMARY] });
            setResults(data);
        },
        onError: (error: AxiosError) => {
            setInput("");
            errorToast("Scan Failed", error.message ?? "Please try that last scan again.");
        },
    });

    const completeBatchMutation = useMutation({
        mutationFn: () => {
            if (!id) throw new Error("No batch id");
            return AuditApi.completeBatch({ batchId: batchId });
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AUDIT] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.PREVIOUS_AUDIT] });
            navigate(-1);
        },
        onError: () => {
            errorToast("Finish Audit Failed", "Please try again in a little bit");
        },
    });

    useEffect(() => {
        // Barcode is a guid, so just make sure it's long enough
        if (input.length > 16) {
            const newBarcode = input.substring(input.length - 36);

            setBarcode(newBarcode);

            auditMutation.mutate(newBarcode);
        }
        // disable because we want to run this on every input change but not on the other dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    if (!batchId) return null;

    return (
        <div>
            <h2 className="headline">
                {campaignQuery.data?.type === "Petition" ? "Petition" : "VR"} Campaign:{" "}
                <span className="italic">{campaignQuery.data?.name}</span>
            </h2>
            <h2 className="title">Audit</h2>
            <p className="body">
                Scan in a {campaignQuery.data?.type.toLowerCase()} that you wish to audit. The TPV will appear below,
                then select if it passes the audit or how it fails.
            </p>
            <BarcodeScanner value={input} setValue={setInput} />
            <div className="mb-8 mt-2">
                <div className="card flex items-center justify-between">
                    <p className="body">
                        {(results?.disposition?.length ?? 0) > 0
                            ? "Scan another to continue or click finish to wrap up."
                            : "Scan a barcode to begin."}
                    </p>
                    <Button onClick={() => completeBatchMutation.mutate()}>Finish Audit</Button>
                </div>
            </div>
            {results?.scanLine ? (
                <MultiAudit
                    results={results}
                    barcode={barcode}
                    setResults={setResults}
                    setBarcode={setBarcode}
                    batchId={batchId}
                />
            ) : (
                <SingleAudit
                    results={results}
                    barcode={barcode}
                    setResults={setResults}
                    setBarcode={setBarcode}
                    batchId={batchId}
                />
            )}
            <Counts summary={summaryQuery.data} loading={auditMutation.isPending} />
        </div>
    );
};
