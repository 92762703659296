import { ChevronDownIcon, ChevronRightIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { type QueryKey, useQuery } from "@tanstack/react-query";
import {
    type ColumnDef,
    type ExpandedState,
    type Row,
    type RowPinningState,
    type RowSelectionState,
    type Table,
    createColumnHelper,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { type ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { hasValue, styles, useBreakpoint } from "../../../helpers";
import { type GridResult, type IFilter, KeyedArray } from "../../../models";
import { IndeterminateCheckbox } from "../inputs";
import { Loading } from "./loading";
import { DataGridMobile } from "./mobile";
import { type Column } from "./models/column";
import { DataGridPagination } from "./pagination";
import { DataGridTableBody } from "./tableBody";
import { DataGridTableHead } from "./tableHead";
import { mapColumn } from "./utils";

interface Props<T> {
    queryKey: QueryKey;
    getData: (signal: AbortSignal) => Promise<GridResult<T> | undefined>;
    columns: Column<T>[];
    page: number;
    itemsPerPage: number;
    setItemsPerPage: (itemsPerPage: number) => void;
    setPage: (page: number) => void;
    pageSizeOptions?: number[];
    setSortFilter?: (sortFilter: IFilter) => void;
    options?: (row: T) => ReactNode;
    rightOptions?: (row: T) => ReactNode;
    selectedActions?: (rows: T[]) => ReactNode;
    expandable?: boolean;

    pinnable?: boolean;

    renderSubRow?: string;
    renderSubComponent?: (props: { row: Row<T> }) => ReactNode;
    getRowCanExpand?: (row?: Row<T>) => boolean;
    getRowClassNames?: (row: Row<T>) => string;
    getRowId?: (item: T) => string;
    mapColumnName?: (colName: any) => string;

    pinnedIds?: string[];
    setPinnedIds?: (ids: string[]) => void;
}

export type CustomTable<TData> = Table<TData> & {
    options?: {
        meta?: {
            getRowClassNames?: (row: Row<TData>) => string;
        };
    };
};

export function DataGrid<T extends object>(props: Props<T>) {
    const {
        queryKey,
        getData,
        columns,
        page,
        itemsPerPage,
        setItemsPerPage,
        setPage,
        pageSizeOptions,
        setSortFilter,
        options,
        rightOptions,
        selectedActions,
        expandable,
        renderSubRow,
        renderSubComponent,
        getRowCanExpand,
        getRowClassNames,
        mapColumnName,
    } = props;
    const gridDataQuery = useQuery({ queryKey: [...queryKey], queryFn: ({ signal }) => getData(signal) });
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [totalItems, setTotalItems] = useState<number>(0);

    const selectColumn = useMemo(getSelectColumn, [selectedActions]);
    const expandColumn = useMemo(getExpandableColumn, [expandable]);
    const [pinnedItems, setPinnedItems] = useState(() => new KeyedArray<T>([], props.getRowId ?? (() => "")));
    const [rowPinning, setRowPinning] = useState<RowPinningState>({
        top: [],
        bottom: [],
    });

    const pinItem = useCallback(
        (row: Row<T>) => {
            const item = row.original;
            const newPinned = pinnedItems.copy();
            if (row.getIsPinned() === "top") {
                row.pin(false);
                newPinned.remove(item);
            } else {
                row.pin("top");
                newPinned.set(item);
            }
            setPinnedItems(newPinned);

            if (props.setPinnedIds) {
                props.setPinnedIds(newPinned.data.map((item) => (props.getRowId ? props.getRowId(item) : "")));
            }
        },
        [props, pinnedItems, setPinnedItems],
    );

    const pinColumn = useMemo(getPinColumn, [props.pinnable, pinItem]);

    const columnHelper = createColumnHelper<T>();

    const mappedColumns: ColumnDef<T, string>[] = useMemo(() => {
        const baseColumns = columns.map(mapColumn(columnHelper));

        if (mapColumnName) baseColumns.forEach((c) => (c.header = mapColumnName(c.header)));

        return [selectColumn, pinColumn, expandColumn, ...baseColumns].filter(hasValue) as ColumnDef<T, string>[];
    }, [columns, columnHelper, mapColumnName, selectColumn, pinColumn, expandColumn]);

    function getSelectColumn(): ColumnDef<T, string> | undefined {
        if (!selectedActions) return undefined;
        return {
            id: "select",
            meta: { isIcon: true },
            header: ({ table }) => (
                <IndeterminateCheckbox
                    id="select-all"
                    aria-label={"Select all rows"}
                    checked={table.getIsAllRowsSelected()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    onChange={table.getToggleAllRowsSelectedHandler()}
                    className="invisible sm:visible"
                />
            ),
            cell: ({ row }) => (
                <IndeterminateCheckbox
                    id={`select-${row.id}`}
                    aria-label={`Select row ${row.id}`}
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    indeterminate={row.getIsSomeSelected()}
                    onChange={row.getToggleSelectedHandler()}
                />
            ),
        };
    }
    function getExpandableColumn(): ColumnDef<T, string> | undefined {
        if (!expandable) return undefined;
        return {
            id: "expand",
            meta: { isIcon: true },
            header: () => null,
            cell: ({ row }) =>
                row.getCanExpand() ? (
                    <button onClick={row.getToggleExpandedHandler()} className="hidden cursor-pointer sm:block">
                        {row.getIsExpanded() ? (
                            <ChevronDownIcon className="w-4" />
                        ) : (
                            <ChevronRightIcon className="w-4" />
                        )}
                    </button>
                ) : null,
        };
    }
    function getPinColumn(): ColumnDef<T, string> | undefined {
        if (!props.pinnable) return undefined;
        return {
            id: "pin",
            meta: { isIcon: true },
            header: () => null,
            cell: ({ row }) => {
                const isPinned = row.getIsPinned();
                return (
                    <div className={styles(isPinned && "")}>
                        <button onClick={() => pinItem(row)} className="hidden cursor-pointer sm:block">
                            {isPinned ? (
                                <MinusCircleIcon className="w-4" fontSize="small" />
                            ) : (
                                <PlusCircleIcon className="w-4" fontSize="small" />
                            )}
                        </button>
                    </div>
                );
            },
        };
    }

    useEffect(() => {
        if (gridDataQuery.isFetching) return;

        setTotalItems(gridDataQuery.data?.totalItems ?? 0);
    }, [gridDataQuery.data?.totalItems, gridDataQuery.isFetching]);

    const data = useMemo(() => {
        const queryItems = gridDataQuery.data?.items;

        if (!queryItems) return [];
        if (!pinnedItems.any()) return queryItems;
        return [...queryItems, ...pinnedItems.data];
    }, [gridDataQuery.data?.items, pinnedItems]);

    const table = useReactTable<T>({
        data,
        columns: mappedColumns,
        state: {
            rowSelection,
            expanded,
            rowPinning,
        },
        getRowId: props.getRowId,
        enableRowSelection: !!selectedActions,
        onRowSelectionChange: setRowSelection,
        onExpandedChange: setExpanded,
        getSubRows: renderSubRow ? (row) => row[renderSubRow as keyof typeof row] as T[] : undefined,
        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand,
        manualSorting: true,
        manualPagination: true,
        pageCount: Math.ceil(totalItems / (itemsPerPage ?? 10)),
        getCoreRowModel: getCoreRowModel(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
        meta: {
            getRowClassNames,
        },
        enableRowPinning: props.pinnable,
        keepPinnedRows: props.pinnable,
        onRowPinningChange: setRowPinning,
    }) as CustomTable<T>;

    const selectedRows = table.getSelectedRowModel().rows.map(({ original }) => original);
    const { isSm } = useBreakpoint("sm");

    return (
        <div className="sm:card !p-0">
            <div className="overflow-x-auto">
                <div className={table.getSelectedRowModel().rows.length === 0 ? "hidden" : "card my-2 p-0"}>
                    {selectedActions ? selectedActions(selectedRows) : null}
                </div>
                {!isSm ? (
                    <table className="card hidden min-w-full divide-y divide-gray-300 sm:table">
                        <DataGridTableHead<T>
                            table={table}
                            options={options}
                            rightOptions={rightOptions}
                            setSortFilter={setSortFilter}
                        />
                        {gridDataQuery.isFetching ? (
                            <Loading table={table} />
                        ) : (
                            <DataGridTableBody<T>
                                table={table}
                                options={options}
                                rightOptions={rightOptions}
                                renderSubComponent={renderSubComponent}
                            />
                        )}
                    </table>
                ) : (
                    <section className="min-w-full divide-y divide-gray-300 sm:hidden">
                        {selectedActions ? (
                            <section>
                                <div>
                                    <div className="p-4">
                                        <IndeterminateCheckbox
                                            id="select-all"
                                            aria-label={"Select all rows"}
                                            checked={table.getIsAllRowsSelected()}
                                            indeterminate={table.getIsSomeRowsSelected()}
                                            onChange={table.getToggleAllRowsSelectedHandler()}
                                            className="visible sm:invisible"
                                            label="Select All"
                                            inline
                                        />
                                    </div>
                                </div>
                            </section>
                        ) : null}
                        <DataGridMobile<T>
                            table={table}
                            options={options}
                            rightOptions={rightOptions}
                            renderSubComponent={renderSubComponent}
                            expandable={expandable}
                        />
                    </section>
                )}
            </div>
            <DataGridPagination<T>
                table={table}
                page={page}
                setPage={setPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                pageSizeOptions={pageSizeOptions ? pageSizeOptions : [10, 50, 100]}
                totalItems={totalItems}
            />
        </div>
    );
}
