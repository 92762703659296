import { type ColumnDef, type ColumnHelper } from "@tanstack/react-table";

import { type Column } from "./models/column";

export function mapColumn<T>(columnHelper: ColumnHelper<T>) {
    return function (column: Column<T>): ColumnDef<T> {
        if (column.subColumns.length)
            return columnHelper.group({
                id: `${column.propertyName ?? ""}|${column.name}|${column.id}`,
                header: column.name,
                columns: column.subColumns.map(mapColumn(columnHelper)),
            });
        return columnHelper.accessor(column.propertyName as any, {
            id: `${column.propertyName ?? ""}|${column.name}|${column.id}`,
            header: column.name,
            enableSorting: column.sortable,
            minSize: column.minWidth,
            sortDescFirst: true,
            cell: (row) => {
                return (
                    column.displayFunction
                        ? column.displayFunction(column.propertyName ? row.getValue() : row.row.original)
                        : row.getValue()
                ) as string;
            },
        });
    };
}
