import { Button, TextBox } from "../../basic";
import { type TimeFilterProps } from "../types";

export default function TimeFilters(props: TimeFilterProps) {
    const { visible, filters, changeHandler } = props;

    function setValues(start?: string, end?: string) {
        changeHandler(start, "startTime");
        changeHandler(end, "endTime");
    }

    function setDates(startHour?: number, endHour?: number) {
        const start = startHour ? `${startHour.toString().padStart(2, "0")}:00` : undefined;
        const end = endHour ? `${endHour.toString().padStart(2, "0")}:00` : undefined;

        setValues(start, end);
    }

    function setLastHour() {
        const date = new Date().getHours();
        const today = new Date().getHours() + 1;
        setDates(date, today);
    }

    function setMorning() {
        const date = 8;
        const today = 12;
        setDates(date, today);
    }

    function setAfternoon() {
        const date = 12;
        const today = 17;
        setDates(date, today);
    }

    function set8to5() {
        const startDate = 8;
        const endDate = 17;
        setDates(startDate, endDate);
    }

    function setAllTime() {
        setValues(undefined, undefined);
    }

    if (!visible) return null;

    return (
        <div className="row-span-2">
            <div className="grid grid-cols-2 gap-2">
                <TextBox
                    label={"Start Time"}
                    type="time"
                    value={filters.startTime}
                    onChange={(e) => setValues(e.target.value, filters.endTime)}
                />
                <TextBox
                    label={"End Time"}
                    type="time"
                    value={filters.endTime}
                    onChange={(e) => setValues(filters.startTime, e.target.value)}
                />
            </div>
            <div className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-5">
                <Button variant="secondary" onClick={setLastHour}>
                    Last Hour
                </Button>
                <Button variant="secondary" onClick={setMorning}>
                    Morning
                </Button>
                <Button variant="secondary" onClick={setAfternoon}>
                    Afternoon
                </Button>
                <Button variant="secondary" onClick={set8to5}>
                    Work Day
                </Button>
                <Button variant="secondary" onClick={setAllTime}>
                    All
                </Button>
            </div>
        </div>
    );
}
