/* eslint-disable */
import { clone, hasValue } from "../helpers";

export type KeyFn<T> = (obj: T) => string | number;

/**
 * Essentially a sorted dictionary.  Has most functionality of Array, plus O[1] lookup
 * (at expense of slower initialization and sorting)
 */
export class KeyedArray<T> {
    data: T[];
    keys: any = {};
    keyFn: KeyFn<T>;

    constructor(data: T[], keyFn: KeyFn<T>) {
        data = data || [];
        this.data = data.map((d) => d);
        this.keyFn = (o: T) => o && keyFn(o);
        this.data.forEach((o, i) => (this.keys[keyFn(o)] = i));
    }

    protected newArray(data: T[]) {
        return new KeyedArray(data, this.keyFn);
    }

    /** Adds to list, or updates if exists */
    set(obj: T) {
        const key = this.keyFn(obj);
        if (this.keys[key] === undefined) {
            this.keys[key] = this.data.length;
            this.data.push(obj);
        } else {
            this.data[this.keys[key]] = obj;
        }
    }

    remove(item: T) {
        return this.removeId(this.keyFn(item));
    }

    removeId(id: string | number) {
        if (this.get(id)) {
            const index = this.keys[id];
            delete this.keys[id];
            this.data.splice(index, 1);
            return true;
        }
        return false;
    }

    /** O[1] accessor (gets by id) */
    get(id: string | number) {
        return this.data[this.keys[id]];
    }

    /** O[1] accessor (gets by id) */
    has(id: string | number) {
        return hasValue(this.keys[id]);
    }

    sort(compareFn: (a: T, b: T) => number) {
        const sortedData = this.data.map((d) => d).sort(compareFn);
        return this.newArray(sortedData);
    }

    map<TOut>(callbackfn: (value: T, index: number, array: T[]) => TOut) {
        return this.data.map(callbackfn);
    }

    filter(callbackfn: (value: T, index: number, array: T[]) => boolean) {
        return this.newArray(this.data.filter(callbackfn));
    }

    count() {
        return (this.data && this.data.length) || 0;
    }

    any() {
        return this.count() > 0;
    }

    /** Returns copy of array */
    copy() {
        return this.newArray(this.data.map(clone));
    }
}

export class KeyPairArray<TValue = any> extends KeyedArray<keyPair<TValue>> {
    constructor(data?: keyPair<TValue>[]) {
        super(data || [], (k) => k.id);
    }

    values() {
        return this.map((o) => o.value);
    }

    setValue(id: string | number, value: TValue) {
        const pair = { id, value };
        super.set(pair);
    }

    getValue(id: string | number) {
        const pair = this.get(id);
        return pair && pair.value;
    }

    /** Returns copy of array */
    copy(): KeyPairArray<TValue> {
        return super.copy() as KeyPairArray<TValue>;
    }

    protected newArray(data: keyPair<TValue>[]) {
        return new KeyPairArray<TValue>(data);
    }
}

export interface keyPair<TValue = any> {
    id: string | number;
    value: TValue;
}

export interface keyPairAll<TValue = any> {
    id: string | number | boolean | undefined;
    value: TValue;
}

export interface keyPairAny<TValue = any> {
    id: any;
    value: TValue;
}

/**
 * Maps object (which should be a dictionary-like) to a key pair array
 * @param obj
 * @returns
 */
export function objToKeyPairs(obj: Object) {
    const kvps = Object.keys(obj).map((k) => {
        return { id: k, value: obj[k as keyof Object] };
    });
    return new KeyPairArray(kvps);
}

export class KeyPairArrayGeneric<TId extends string | number, TValue> extends KeyedArray<{ id: TId; value: TValue }> {
    constructor(data: { id: TId; value: TValue }[]) {
        super(data, (k) => k.id);
    }

    getValue(id: string | number) {
        const pair = this.get(id);
        return pair && pair.value;
    }

    protected newArray(data: { id: TId; value: TValue }[]) {
        return new KeyPairArrayGeneric(data);
    }

    /** Returns copy of array */
    copy() {
        return super.copy() as KeyPairArrayGeneric<TId, TValue>;
    }
}

const ALL_KEY = "_ALL_";
export class KeyedSetArray<T = number> extends KeyPairArray<Set<T>> {
    constructor(data: keyPair<Set<T>>[] = []) {
        super(data);
    }

    addElement(id: string | number, itemId: T) {
        //Clear before re-adding, in case was moved to different category
        for (const s of this.data) s.value.delete(itemId);

        const set = this.get(id)?.value ?? new Set();
        set.add(itemId);
        super.setValue(id, set);

        const allSet = this.get(ALL_KEY)?.value ?? new Set();
        allSet.add(itemId);
        super.setValue(ALL_KEY, allSet);
    }

    getValue(id: string | number) {
        const val = super.getValue(id);
        return val ?? new Set<T>();
    }

    getFullSet() {
        return this.getValue(ALL_KEY);
    }
}
