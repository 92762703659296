import { TextBox } from "../../basic";
import { LocationDropdown } from "../../dropdowns/locationDropdown";
import { type AddressFilterProps } from "../types";

export default function AddressFilter(props: AddressFilterProps) {
    const { visible, filters, selectVal, changeHandler } = props;

    if (!visible) return null;

    return (
        <>
            <div className="grid grid-cols-4 gap-2">
                <div className="col-span-3">
                    <LocationDropdown value={filters.location} selectVal={selectVal} isClearable />
                </div>
                <TextBox
                    id="locationWithinFeet"
                    label="Radius (ft)"
                    value={filters.locationWithinFeet}
                    changeHandler={changeHandler}
                    placeholder={"1000"}
                    type="number"
                />
            </div>
        </>
    );
}
