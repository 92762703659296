import { SortType } from "../../../../models";

export interface IColumn<T> {
    name: string;
    propertyName?: keyof T;
    clientTemplate?: string;
    sortBy: SortType;
    sortable?: boolean;
}

export class Column<TObj> implements IColumn<TObj> {
    public id: string | number = "";
    public name: string;
    public propertyName?: keyof TObj & string;
    public sortBy: SortType;
    public displayFunction?: (i: any) => any;
    public sortable?: boolean;
    public width?: string;
    public minWidth?: number;
    /* For templates/display functions, in case need access to other props */
    public useFullObject?: boolean;
    //to remove
    public clientTemplate?: string;
    public visible: boolean;

    public subColumns: Column<TObj>[] = [];

    constructor(name: string, propertyName?: keyof TObj & string, sortBy: SortType = SortType.none, sortable = true) {
        this.name = name;
        this.propertyName = propertyName;
        this.sortBy = sortBy;
        this.sortable = sortable;
        this.visible = true;
    }

    public withSortBy(sortBy: SortType) {
        this.sortBy = sortBy;
        return this;
    }

    public clearFormat() {
        this.displayFunction = undefined;
        return this;
    }

    public withFormat(displayFunction?: (i: any) => any) {
        if (this.displayFunction) {
            const oldFn = this.displayFunction;
            if (displayFunction) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                this.displayFunction = (o: any) => displayFunction(oldFn(o));
            }
        } else this.displayFunction = displayFunction;
        return this;
    }

    public withSortable(sortable: boolean) {
        this.sortable = sortable;
        return this;
    }

    public withMinWidth(pixels: number) {
        this.minWidth = pixels;
        return this;
    }

    public withVisibility(visible: boolean) {
        this.visible = visible;
        return this;
    }

    public withId(id: string | number) {
        this.id = id;
        return this;
    }

    public withTemplate(template: string) {
        this.clientTemplate = template;
        return this;
    }

    public withSubColumn(subColumn: Column<TObj>) {
        this.subColumns.push(subColumn);
    }

    public withFullObject(useFullObject = true) {
        this.useFullObject = useFullObject;
        return this;
    }
}
