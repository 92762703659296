import { CalendarIcon } from "@heroicons/react/24/outline";

import { formatDateOf } from "../../../helpers";
import { Button } from "../button";
import { Calendar } from "../calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../popover/popover";

export const DATE_FORMAT = "MM/dd/yy";

interface DatePickerProps {
    id?: string;
    label?: string;
    value: Date | undefined;
    setValue: (date?: Date) => void;
}

export function DatePicker(props: DatePickerProps) {
    const { id, label, value, setValue } = props;

    return (
        <div className="grid grid-cols-1">
            <span className="label">{label}</span>
            <Popover>
                <PopoverTrigger asChild>
                    <Button id={id} variant={"outline"} className="flex justify-between">
                        {value ? formatDateOf(value) : <span>Pick a date</span>}
                        <CalendarIcon className="h-4 w-4" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                    <Calendar mode="single" selected={value} onSelect={setValue} initialFocus />
                </PopoverContent>
            </Popover>
        </div>
    );
}
