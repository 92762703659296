import { useQuery } from "@tanstack/react-query";
import printJS from "print-js";
import { type FC, useEffect, useState } from "react";

import { FormApi } from "../../api/formApi";
import { MS_IN, QueryKeys } from "../../helpers";
import { type FormPrintBatchDto } from "../../models";
import { Button, Dialog } from "../basic";
import { DataGridOptions } from "../basic/dataGrid/options";

interface OptionsProps {
    data: FormPrintBatchDto;
}

export const Options: FC<OptionsProps> = (props) => {
    const [show, setShown] = useState(false);
    const [print, setPrint] = useState(false);

    const fileUrlQuery = useQuery({
        queryKey: [QueryKeys.PRINT_FORM_BATCH_FILE_URL, props.data.id],
        queryFn: async () => {
            return FormApi.getFormPrintBatchUrl(props.data.id);
        },
        enabled: show || print,
        gcTime: MS_IN.MINUTE,
    });

    useEffect(() => {
        if (print && fileUrlQuery.data) {
            printJS({
                printable: fileUrlQuery.data,
                type: "pdf",
                showModal: true,
            });
            setPrint(false);
        }
    }, [print, fileUrlQuery.data]);

    if (!props.data?.fileUrl) return null;

    return (
        <>
            <DataGridOptions
                actions={[
                    { name: "View", onClick: () => setShown(true) },
                    {
                        name: "Print",
                        onClick: () => {
                            setPrint(true);
                        },
                    },
                ]}
            />
            <Dialog
                open={show}
                setOpen={() => undefined}
                title="Print Form Batch"
                body={<iframe title="Form Batch" src={fileUrlQuery.data} width="765" height="600" />}
                actions={
                    <Button variant="default" className="col-start-2" onClick={() => setShown(false)}>
                        Close
                    </Button>
                }
            />
        </>
    );
};
