import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../contexts/userContext";
import { type UserPermissionFlags } from "../models/permissions";
import { RouteValues } from "./routes";

export const useRoutePermissions = (flag: UserPermissionFlags) => {
    const { hasPermission, user } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) return;
        if (!hasPermission(flag)) {
            navigate(`/${RouteValues.HOME}`);
        }
    }, [flag, hasPermission, navigate, user]);
};
