export function useColumnMapper(gridName: string) {
    switch (gridName) {
        case "Verifications":
            return mapVerificationColumn;
    }
    return mapGenericName;
}

function mapVerificationColumn(colName: string) {
    switch (colName) {
        default:
            return mapGenericName(colName);
    }
}

function mapGenericName(colName: string) {
    switch (colName) {
        default:
            return colName;
    }
}
