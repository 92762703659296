import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { GridFiltersApi } from "../../api/gridFiltersApi";
import { QueryKeys } from "../../helpers";
import { type DropdownProps, FilterDropdown } from "./filterDropDown";

export const VerTypesDropdown: FC<DropdownProps> = (props) => {
    const query = useQuery({ queryKey: [QueryKeys.VER_TYPES_FILTER], queryFn: () => GridFiltersApi.getVerTypes() });

    return <FilterDropdown id="verType" label="Type" {...props} query={query} />;
};
