import { type FC, type FormEvent, useState } from "react";

import { Button, Select, type SelectOption } from "../basic";

interface State {
    theme: string;
}

export const ChangeThemeForm: FC = () => {
    const [state, setState] = useState<State>({ theme: localStorage.getItem("theme") ?? "system" });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (state.theme === "system") {
            localStorage.removeItem("theme");
        } else {
            localStorage.setItem("theme", state.theme);
        }

        window.location.reload();
    };

    return (
        <form className="card h-full flex-1" onSubmit={handleSubmit}>
            <div className="space-y-12">
                <div className="pb-12">
                    <h2 className="title">Update Theme</h2>
                    <p className="subtitle">Choose between using the default system theme, or set to light or dark.</p>
                    <div className="mt-10 grid grid-cols-1">
                        <Select
                            label="Theme"
                            value={state.theme}
                            options={[
                                { value: "system", label: "System" },
                                { value: "light", label: "Light" },
                                { value: "dark", label: "Dark" },
                            ]}
                            changeHandler={(value: SelectOption) => {
                                setState({
                                    theme: value.value.toString(),
                                });
                            }}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <Button type="submit" variant="default">
                    Submit
                </Button>
            </div>
        </form>
    );
};
