import { XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback, useMemo, useState } from "react";

import { type TextSearchOption, type keyPair } from "../../../models";
import { Button, TextBoxWithSelect } from "../../basic";
import { type SearchItemFilterProps } from "../types";

//TODO: ability to clear all filters
//TODO leave generalized search

//maybe trigger on some kind of time delay (if no changes in half a second or something) - only if not already triggered by blur or enter
//need it to set up trigger on entering

export default function SearchItemFilter(props: SearchItemFilterProps) {
    const { visible, filters, changeHandler } = props;
    const searchOptions = useMemo(() => filters.searchOptions ?? [], [filters.searchOptions]);
    const [selectedItem, setSelectedItem] = useState<string | number>(searchOptions[0]?.id ?? "");
    const [searchItems, setSearchItems] = useState({} as Record<string, string | undefined>);

    const selectedRecord = useMemo(
        () => ({ id: selectedItem, value: searchItems?.[selectedItem] }) as keyPair<string>,
        [searchItems, selectedItem],
    );

    const onChange = useCallback(
        function (value: keyPair<string | undefined>) {
            const id = value.id;
            const record = { ...searchItems } as Record<string, string | undefined>;
            const val = value.value || undefined;
            if (!value.value) delete record[id];
            else record[id] = val;
            setSearchItems(record);
            return record;
        },
        [setSearchItems, searchItems],
    );

    const commitChange = useCallback(
        (newItems?: Record<string, string | undefined>) => {
            newItems = newItems ?? searchItems;
            const items = Object.keys(newItems).length ? newItems : undefined;
            changeHandler(items, "searchItems");
        },
        [changeHandler, searchItems],
    );

    const commitChangeEvent = useCallback(() => commitChange(searchItems), [commitChange, searchItems]);

    const clearItem = useCallback(
        (id: string | number) => {
            const newItems = onChange({ id, value: undefined });
            commitChange(newItems);
        },
        [commitChange, onChange],
    );

    const items = useMemo(() => searchOptions.map((o) => o.id), [searchOptions]);
    const mappedOptions = useMemo(() => searchOptions.map((o) => ({ value: o.id, label: o.label })), [searchOptions]);

    if (!visible) return null;

    return (
        <div className="row-span-2">
            <TextBoxWithSelect
                id="search"
                label="Detailed Search"
                changeHandler={onChange}
                onSelectChange={setSelectedItem}
                onEnter={commitChangeEvent}
                onBlur={commitChangeEvent}
                options={mappedOptions}
                value={selectedRecord}
            />
            <div className="flex gap-2 pt-2">
                {items?.map((i) => (
                    <SearchItem
                        key={i}
                        id={i}
                        searchItems={searchItems}
                        options={searchOptions}
                        clearItem={clearItem}
                        selectItem={setSelectedItem}
                    />
                ))}
            </div>
        </div>
    );
}

interface ItemProps {
    searchItems: Record<string, string | undefined>;
    options: TextSearchOption[];
    id: string | number;
    selectItem: (id: string | number) => void;
    clearItem: (id: string | number) => void;
}

function SearchItem(props: ItemProps) {
    const { searchItems, options, id, selectItem, clearItem } = props;

    const selectItemCallback = useCallback(() => selectItem(id), [selectItem, id]);

    const clearItemCallback = useCallback(() => {
        clearItem(id);
    }, [clearItem, id]);

    const label = useMemo(() => options.find((o) => o.id === id)?.label, [options, id]);
    const value = useMemo(() => searchItems?.[id], [searchItems, id]);

    if (!value) return null;

    return (
        <div className="flex gap-2 rounded-2xl bg-indigo-600 px-3 py-2 text-xs text-white shadow-2xl dark:bg-white dark:text-indigo-600">
            <button className="m-0 p-0" onClick={selectItemCallback}>
                <span className="font-bold">{label}</span> | {value}
            </button>
            <Button
                className="m-0 h-6 w-6 border-white p-1 text-white dark:border-indigo-400 dark:text-indigo-400"
                variant="outline"
                size="icon"
                onClick={clearItemCallback}
            >
                <XMarkIcon className="w-4" />
            </Button>
        </div>
    );
}
