import { isValid, parse } from "date-fns";

import { DATE_FORMAT } from "../components/basic";

export function tryParseDate(dateString?: string): Date | undefined {
    if (!dateString) return undefined;

    const date = parse(dateString, DATE_FORMAT, new Date());

    return isValid(date) ? date : undefined;
}

export function getTimezoneAbbreviation(date = new Date()): string {
    return date.toLocaleDateString("en-us", { day: "2-digit", timeZoneName: "short" }).substring(4);
}
