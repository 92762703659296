import { Severity, logger } from "../helpers";
import {
    type AuditBatchSummaryDto,
    type AuditDispositionDto,
    type AuditScanResponseDto,
    type CheckInFilter,
    type GridResult,
} from "../models";
import { type AuditQuestionAnswerDto } from "../models/auditQuestionAnswerDto";
import { type AuditQuestionResponseDto } from "../models/auditQuestionResponseDto";
import { type FormAuditBatch } from "../models/formAuditBatch";
import { type NextAuditQuestionDto } from "../models/nextAuditQuestionDto";
import { doGet, doPost } from "./httpHandler";

const controller = "/api/Audit";

export class AuditApi {
    public static getAll = (
        signal: AbortSignal,
        itemsPerPage = 10,
        currentPage = 1,
        filters?: Partial<CheckInFilter>,
    ) => {
        try {
            return doPost<GridResult<FormAuditBatch>, Partial<CheckInFilter>>(
                controller,
                "GetAll",
                { itemsPerPage, currentPage },
                filters,
                { signal },
            );
        } catch (e) {
            logger(Severity.Error, e);
        }
    };

    public static getUserPreviousAudit = () => {
        return doGet<AuditBatchSummaryDto>(controller, "GetUserPreviousAudit", undefined);
    };

    public static createBatch = (params: { campaignId: number }) => {
        return doPost<AuditBatchSummaryDto, undefined>(controller, "CreateBatch", params);
    };

    public static scan = (params: { batchId: number; formLinkGuid: string }) => {
        return doGet<AuditScanResponseDto>(controller, "Scan", params);
    };

    public static getBatchSummary = (params: { batchId: number }) => {
        return doGet<AuditBatchSummaryDto>(controller, "GetBatchSummary", params);
    };

    public static disposition = (params: { batchId: number; formLinkGuid: string; dispositionId: number }) => {
        return doPost<AuditBatchSummaryDto, undefined>(controller, "", params);
    };

    public static getCampaignDispositons = (params: { batchId: number }) => {
        return doGet<AuditDispositionDto[]>(controller, "", params);
    };

    public static getAllDispositions = () => {
        return doGet<AuditDispositionDto[]>(controller, "", undefined);
    };

    public static completeBatch = (params: { batchId: number }) => {
        return doPost<AuditBatchSummaryDto, undefined>(controller, "CompleteBatch", params);
    };

    public static nextAuditQuestion = (formLinkGuid: string, answers: AuditQuestionAnswerDto[]) => {
        const params = {
            formLinkGuid,
        };

        const body = {
            answers,
        };

        return doPost<AuditQuestionResponseDto, NextAuditQuestionDto>(controller, "NextAuditQuestion", params, body);
    };
}
