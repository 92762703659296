import { type FC, useCallback } from "react";

import { MapApi } from "../../api/mapApi";
import { Select, type SelectOption } from "../basic";
import { type DropdownProps } from "./filterDropDown";

export const LocationDropdown: FC<DropdownProps> = (props) => {
    const { selectVal, value } = props;

    const loadOptions = useCallback(async (inputValue: string) => {
        const values = await MapApi.autocomplete(inputValue);

        return values?.map((item) => ({ value: item, label: item })) ?? ([] as SelectOption[]);
    }, []);

    const changeHandler = (val: SelectOption) => {
        selectVal(val, props.id ?? "location");
    };

    return (
        <Select label="Location" value={value} loadOptions={loadOptions} changeHandler={changeHandler} isClearable />
    );
};
