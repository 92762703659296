import { useQuery } from "@tanstack/react-query";
import { type FC, useMemo } from "react";

import { RepresentativesApi } from "../../../api/representativesApi";
import { QueryKeys, toPercent } from "../../../helpers";
import { useMapVerbiage } from "../../../hooks/verbiageHooks";
import { KeyedArray, type VerificationDto } from "../../../models";
import { VerTypes } from "../../../models/verTypes";
import { Column, DataGridClient } from "../../basic";

interface GridProps {
    data: VerificationDto[];
    update: () => Promise<void>;
    loading: boolean;
    loadingSignalR: boolean;
}

interface RealTimeAgentData {
    repId: string;
    totalSales: number;
    goodSales: number;
    pendingSales: number;
    noSales: number;
    goodWebSalesGpsRatio: number | undefined;
}

export const RealtimeAgentLeaderboard: FC<GridProps> = (props) => {
    const { data, loading } = props;

    const mapVerbiage = useMapVerbiage();
    const repsQuery = useQuery({ queryKey: [QueryKeys.REPS], queryFn: RepresentativesApi.getAllBasicInfo });
    const reps = useMemo(() => new KeyedArray(repsQuery.data ?? [], (r) => r.repId), [repsQuery.data]);

    const mapped = useMemo(() => {
        const map = new Map<string, VerificationDto[]>();
        const counted = new Map<string, RealTimeAgentData>();

        data.forEach((ver) => {
            if (map.has(ver.repId)) {
                map.get(ver.repId)?.push(ver);
            } else {
                map.set(ver.repId, [ver]);
            }
        });

        for (const [key, value] of map.entries()) {
            const allGoodSales = value.filter((ver) => ver.success);
            const goodSales = allGoodSales.length;
            const pendingSales = value.filter(
                (ver) => !ver.dispositionName || ver.dispositionName === "Pending",
            ).length;
            const noSales = value.filter(
                (ver) => !ver.success && !!ver.dispositionName && ver.dispositionName !== "Pending",
            ).length;

            let goodWebSalesGpsRatio = undefined;
            const allGoodWebSales = allGoodSales.filter((ver) => ver.verType === VerTypes.Web);
            if (allGoodWebSales.length) {
                const goodWebSalesWithGps = allGoodSales.filter((ver) => !!ver.position).length;
                goodWebSalesGpsRatio = goodWebSalesWithGps / allGoodSales.length;
            }

            counted.set(key, {
                repId: key,
                totalSales: goodSales + pendingSales + noSales,
                goodSales: goodSales,
                pendingSales,
                noSales,
                goodWebSalesGpsRatio,
            } as RealTimeAgentData);
        }

        return Array.from(counted.values()).sort((a, b) => b.totalSales - a.totalSales);
    }, [data]);

    const columns = useMemo(() => {
        return [
            new Column(mapVerbiage("Rep")).withFormat((r: RealTimeAgentData) =>
                reps.has(r.repId)
                    ? `${reps.get(r.repId)?.lastName ?? ""}, ${reps.get(r.repId)?.lastName ?? ""} (${r.repId})`
                    : r.repId,
            ),
            new Column("Total TPVs", "totalSales"),
            new Column("Successful", "goodSales"),
            new Column("Pending", "pendingSales"),
            new Column("Failed", "noSales"),
            new Column("Successful GPS %").withFormat((r: RealTimeAgentData) => toPercent(r.goodWebSalesGpsRatio, 0)),
        ] as Column<RealTimeAgentData>[];
    }, [mapVerbiage, reps]);

    return (
        <div className="pt-2 text-center">
            <DataGridClient
                loading={loading}
                data={mapped}
                columns={columns}
                embedded={false}
                pageSizeOptions={[10, 50, 100]}
            />
        </div>
    );
};
