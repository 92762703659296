import { useCallback, useEffect, useState } from "react";

import { enterKey, styles } from "../../../helpers";
import { type keyPair } from "../../../models";
import { Select, type SelectOption } from "../select";
import { TextBox } from "../textBox";

interface Props {
    label?: string;
    inline?: boolean;
    onBlur?: () => void;
    onEnter?: () => void;
    changeHandler: (value: keyPair<string>, name?: any) => void;
    onSelectChange?: (value: any) => void;
    value?: keyPair<string>;
    id?: string;
    className?: string;
    options: SelectOption[];
}

export function TextBoxWithSelect(props: Props) {
    const { label, inline, changeHandler, className, value, options, onBlur, onEnter, onSelectChange, ...rest } = props;

    const [selectVal, setSelectVal] = useState<string | number>();
    const [textVal, setTextVal] = useState<string>("");

    const selectChange = useCallback(
        (val: SelectOption) => {
            setSelectVal(val?.value);
            onSelectChange?.(val?.value);
        },
        [onSelectChange],
    );

    const enterCallback = useCallback(() => onEnter && enterKey(onEnter), [onEnter]);

    useEffect(() => {
        if (!value) return;

        setSelectVal(value.id);
        setTextVal(value.value ?? "");
    }, [value]);

    useEffect(() => {
        changeHandler({ id: selectVal ?? "", value: textVal }, props.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textVal]);

    //TODO: run on enter
    return (
        <div>
            <div className={styles(inline && "flex items-center")}>
                <label htmlFor={props.id} className="label">
                    {label}
                </label>
                <div className={styles(inline ? "ml-2" : "mt-2", className, "flex gap-1 p-0", "ml-[-.5rem]")}>
                    <Select inline changeHandler={selectChange} value={selectVal} options={options} />
                    <TextBox
                        {...rest}
                        value={textVal}
                        inline
                        outerClassName="grow"
                        changeHandler={setTextVal}
                        onBlur={onBlur}
                        onKeyUp={enterCallback}
                    />
                </div>
            </div>
        </div>
    );
}
