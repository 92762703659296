import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsAccessibility from "highcharts/modules/accessibility";
import { type FC, useEffect, useRef, useState } from "react";

import "./bar.scss";
import "./charts.scss";
import { NoData } from "./noData";
import { type DataItem } from "./types";

interface BarChartProps extends HighchartsReact.Props {
    id: string;
    title: string;
    data: { name: string; data: DataItem[] }[];
}

highchartsAccessibility(Highcharts);

export const BarChart: FC<BarChartProps> = (props) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const [options, setOptions] = useState<Highcharts.Options>();

    useEffect(() => {
        if (props.data) {
            setOptions({
                chart: {
                    styledMode: true,
                },
                credits: {
                    enabled: false,
                },
                title: {
                    text: props.title,
                    align: "left",
                },
                accessibility: {
                    point: {
                        valueSuffix: "%",
                    },
                },
                plotOptions: {
                    bar: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: false,
                        },
                        showInLegend: true,
                    },
                },
                xAxis: {
                    visible: false,
                },
                tooltip: {
                    formatter: function () {
                        return `${this.point.name}: ${this.point.y ?? "0"}`;
                    },
                },
                series: props.data.map((item) => ({
                    type: "column",
                    name: item.name,
                    data: item.data,
                    accessibility: {
                        enabled: true,
                    },
                })),
            });
        }
    }, [props.data, props.title]);

    if (!options) return null;

    if (props.data?.length === 0) return <NoData title={props.title} />;

    return (
        <div className="card bar-chart">
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
        </div>
    );
};
