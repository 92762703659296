/** Not null or undefined */
export function hasValue(i: unknown) {
    return i !== undefined && i !== null;
}

/** Concatenates css styles */
export function styles(...styles: (string | boolean | undefined)[]) {
    return styles.filter((s) => s).join(" ");
}

/** Creates deep copy of object */
export function clone<T>(obj: T) {
    return obj && (JSON.parse(JSON.stringify(obj)) as T);
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function guid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
export function isGuid(s: string) {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return guidRegex.test(s);
}

export function toFixed(num: number, decimals: number) {
    decimals = Math.pow(10, decimals);
    return Math.floor(num * decimals) / decimals;
}
