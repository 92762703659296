import { useQuery } from "@tanstack/react-query";
import { type FC, type ReactNode, createContext, useCallback, useEffect, useState } from "react";

import { ScanApi } from "../api/scanApi";
import { QueryKeys } from "../helpers";
import { type ScanBatchSummaryDto } from "../models/scanBatchSummaryDto";

interface ContextType {
    batchId: number | undefined;
    setBatchId: (id: number | undefined) => void;
    batchSummary: ScanBatchSummaryDto | undefined;
    updateBatchSummary: (data: Partial<ScanBatchSummaryDto>) => void;
    loading: boolean;
}

export const CheckInContext = createContext<ContextType>({
    batchId: undefined,
    setBatchId: () => undefined,
    batchSummary: undefined,
    updateBatchSummary: () => undefined,
    loading: false,
});

interface Props {
    children: ReactNode;
}

export const CheckInProvider: FC<Props> = ({ children }) => {
    const previousScanQuery = useQuery({
        queryKey: [QueryKeys.PREVIOUS_SCAN],
        queryFn: ScanApi.getUserPreviousScan,
    });
    const [batchId, setBatchId] = useState<number | undefined>(previousScanQuery.data?.id);

    const getBatchSummary = useCallback(async () => {
        if (!batchId) return {} as ScanBatchSummaryDto;
        return await ScanApi.getBatchSummary({ batchId });
    }, [batchId]);
    const batchSummaryQuery = useQuery({
        queryKey: [QueryKeys.CHECK_IN_BATCH_SUMMARY, batchId],
        queryFn: getBatchSummary,
    });
    const loading = batchSummaryQuery.isLoading;

    useEffect(() => {
        if (!batchId) setBatchId(previousScanQuery.data?.id);
    }, [batchId, previousScanQuery.data]);

    const [batchSummary, setBatchSummary] = useState(batchSummaryQuery.data);

    useEffect(() => {
        setBatchSummary(batchSummaryQuery.data);
    }, [batchSummaryQuery.data]);

    const updateBatchSummary = useCallback(
        (formSummary: Partial<ScanBatchSummaryDto>) => {
            const bs = batchSummary ?? ({} as ScanBatchSummaryDto);
            const fs = formSummary ?? {};
            setBatchSummary({ ...bs, ...fs });
        },
        [batchSummary, setBatchSummary],
    );

    return (
        <CheckInContext.Provider value={{ batchId, setBatchId, batchSummary, updateBatchSummary, loading }}>
            {children}
        </CheckInContext.Provider>
    );
};
