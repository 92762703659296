import printJS from "print-js";
import { type FC, type FormEvent, useState } from "react";

import { Button, type SelectOption, TextBox } from "../basic";
import { CampaignDropdown } from "../dropdowns/campaignDropdown";
import { FormTemplatesDropdown } from "../dropdowns/formTemplatesDropdown";

interface State {
    campaignId: number | undefined;
    formTemplateId: number | undefined;
    size: string;
}

interface CreatePrintFormBatchProps {
    repIds: number[];
    setOpen: (open: boolean) => void;
    create: boolean;
    campaignId?: number;
}

export const CreatePrintFormBatch: FC<CreatePrintFormBatchProps> = ({ repIds, setOpen, campaignId }) => {
    const [state, setState] = useState<State>({
        campaignId: campaignId ?? undefined,
        formTemplateId: undefined,
        size: "1",
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const repUrlParams = repIds.map((repId) => `representativeId=${repId}`).join("&");

        printJS({
            printable: `/api/Form/CreateFormPrintBatches?${repUrlParams}&formTemplateId=${
                state.formTemplateId ?? ""
            }&size=${parseInt(state.size)}`,
            type: "pdf",
            showModal: true,
        });
    };

    return (
        <form className="form-dialog" onSubmit={handleSubmit}>
            <CampaignDropdown
                value={state.campaignId}
                selectVal={(value: SelectOption) => setState({ ...state, campaignId: value.value as number })}
                required
                isDisabled={!!campaignId}
            />
            {state.campaignId ? (
                <FormTemplatesDropdown
                    campaignId={state.campaignId}
                    value={state.formTemplateId}
                    selectVal={(value: SelectOption) => setState({ ...state, formTemplateId: value.value as number })}
                    required
                    chooseFirst
                />
            ) : null}
            {state.campaignId ? (
                <TextBox
                    id="form-batch-size"
                    label="Size"
                    type="number"
                    value={state.size}
                    changeHandler={(value: string) => setState({ ...state, size: value })}
                />
            ) : null}
            <div className="mt-4 grid grid-cols-2 gap-2">
                <Button
                    type="button"
                    onClick={() => setOpen(false)}
                    variant="secondary"
                    className="flex justify-center"
                >
                    Cancel
                </Button>
                <Button type="submit" variant="default" className="flex justify-center">
                    Submit
                </Button>
            </div>
        </form>
    );
};
