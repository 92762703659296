import { useQuery } from "@tanstack/react-query";
import { type FC, useMemo } from "react";

import { SystemIncidentsApi } from "../../../api/systemIncidentsApi";
import { QueryKeys } from "../../../helpers";
import { type SystemIncidentDto } from "../../../models";
import style from "./style.module.scss";

export function AlertsBanner() {
    const alertsQuery = useQuery({ queryKey: [QueryKeys.ALERTS], queryFn: SystemIncidentsApi.getAlerts });

    if (alertsQuery.isLoading) return null;

    if ((alertsQuery.data?.length ?? 0) < 1) return null;

    return (
        <div className="h-6 w-full bg-white text-amber-700 hover:bg-amber-200 dark:bg-black dark:text-amber-300 dark:hover:bg-amber-800">
            <Alerts alerts={alertsQuery.data} />
        </div>
    );
}

const Alerts: FC<{ alerts?: SystemIncidentDto[] }> = (props) => {
    return (
        <div className="z-50 m-auto block min-w-full overflow-hidden">
            {props.alerts?.map((alert, index) => <SingleAlert key={index} alert={alert} />)}
        </div>
    );
};

const SingleAlert: FC<{ alert: SystemIncidentDto }> = (props) => {
    const { alert } = props;
    const msg = useMemo(
        () => [alert.started, alert.status, alert.type, alert.impact, alert.description, alert.carrier].join(" | "),
        [alert],
    );
    return <span className={style.alert}>{msg};</span>;
};
