import { type EventDto, type GridResult, type UserDto, type UserFilter } from "../models";
import { doGet, doPost } from "./httpHandler";

const controller = "/api/Users";

interface ToggleData {
    activate: boolean;
    ids: number[];
}

export class UsersApi {
    public static getUsers = (
        signal: AbortSignal,
        itemsPerPage = 10,
        currentPage = 1,
        filters?: Partial<UserFilter>,
    ) => {
        filters = filters || {};
        return doPost<GridResult<UserDto>, Partial<UserFilter>>(
            controller,
            "GetAll",
            { itemsPerPage, currentPage },
            filters,
            { signal },
        );
    };

    public static getEvents = (userId: number, signal: AbortSignal) => {
        return doGet<EventDto[]>(controller, "events", { userId }, { signal });
    };

    public static saveUser = (user: UserDto) => {
        return doPost<UserDto, UserDto>(controller, "save", undefined, user);
    };

    public static toggle = (data: ToggleData) => {
        return doPost<UserDto, ToggleData>(controller, "toggle", undefined, data);
    };
}
