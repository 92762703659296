import { LanguagesDropdown } from "../../dropdowns/languagesDropdown";
import { type LanguageFilterProps } from "../types";

export default function LanguageFilter(props: LanguageFilterProps) {
    const { visible, filters, selectVal } = props;

    if (!visible) return null;

    return (
        <>
            <LanguagesDropdown value={filters.language} selectVal={selectVal} autoHide isClearable />
        </>
    );
}
