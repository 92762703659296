import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { HomeApi } from "../../../api/homeApi";
import { MS_IN, QueryKeys } from "../../../helpers";
import { type VerificationDto } from "../../../models";

interface Props {
    verification: VerificationDto;
    visible?: boolean;
}

export function RecordingView(props: Props) {
    const { verification } = props;
    const [audioUrl, setAudioUrl] = useState<string>("");
    const baseUrlQuery = useQuery({ queryKey: [QueryKeys.BASE_URL], queryFn: HomeApi.getBaseUrl });
    const tokenQuery = useQuery({
        queryKey: [QueryKeys.TEMP_TOKEN, verification.verCode],
        queryFn: () => HomeApi.getTempToken({ scope: verification.verCode }),
        gcTime: MS_IN.MINUTE,
    });

    useEffect(() => {
        if (!baseUrlQuery.data) return;
        if (!tokenQuery.data) return;

        setAudioUrl(`${baseUrlQuery.data}/Player?call=${verification.verCode}&apitoken=${tokenQuery.data}`);
    }, [baseUrlQuery.data, verification.verCode, tokenQuery.data]);

    return (
        <>
            <iframe title="AVL Audio Review" src={audioUrl} width={1000} height={250} />
        </>
    );
}
