import { type FC } from "react";
import { Link as RouterLink, type LinkProps as RouterLinkProps } from "react-router-dom";

import { Button } from "../button";

type LinkProps = RouterLinkProps;

export const Link: FC<LinkProps> = (props) => {
    const { className, ...rest } = props;
    return (
        <Button asChild variant="link">
            <RouterLink {...rest} className={className} />
        </Button>
    );
};
