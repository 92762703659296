import { type FC, Fragment } from "react";

import { type AuditBatchSummaryDto } from "../../models";
import { TextOrLoading } from "../basic/loading/textOrLoading";

interface CountsProps {
    title?: string;
    loading: boolean;
    summary: AuditBatchSummaryDto | undefined;
}

export const Counts: FC<CountsProps> = (props) => {
    return (
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
            <div>
                <h3 className="headline">TPV Disposition</h3>
                <dl className="card mb-4 grid grid-cols-1 gap-2 sm:grid-cols-2">
                    <dt className="title">Total</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={props.summary?.total ?? 0} loading={props.loading} />
                    </dd>
                    <dt className="title">Good TPV</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={props.summary?.goodSales ?? 0} loading={props.loading} />
                    </dd>
                    <dt className="title">Failed TPV</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={props.summary?.noSales ?? 0} loading={props.loading} />
                    </dd>
                    <dt className="title">Missing TPV</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={props.summary?.missing ?? 0} loading={props.loading} />
                    </dd>
                </dl>
            </div>
            <div>
                <h3 className="headline">Audit Disposition</h3>
                <dl className="card mb-4 grid grid-cols-1 gap-2 sm:grid-cols-2">
                    {props.summary?.dispositionCounts.map((item) => (
                        <Fragment key={item.name}>
                            <dt className="title">{item.name}</dt>
                            <dd className="subtitle">
                                <TextOrLoading text={item.count} loading={props.loading} />
                            </dd>
                        </Fragment>
                    ))}
                </dl>
            </div>
        </div>
    );
};
