import { useState } from "react";

import { TextBox } from "../../basic";
import { type SearchFilterProps } from "../types";

export default function SearchFilter(props: SearchFilterProps) {
    const { visible, filters, changeHandler } = props;
    const [value, setValue] = useState<string>(filters.search ?? "");

    const handleSubmit = () => {
        changeHandler(value, "search");
    };

    if (!visible) return null;

    return (
        <TextBox
            id="search"
            label="Search"
            changeHandler={(newValue: string) => setValue(newValue)}
            value={value}
            onBlur={handleSubmit}
            onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
        />
    );
}
