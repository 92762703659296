import { useQuery } from "@tanstack/react-query";

import { ReportingApi } from "../../../api/reportingApi";
import { QueryKeys } from "../../../helpers";
import { type ReportingFilter } from "../../../models";
import { ChartSkeleton, MapChart } from "../../basic";

export interface Props {
    filterData: ReportingFilter;
}

export function LocationWidget(props: Props) {
    const { filterData } = props;

    const locationsQuery = useQuery({
        queryKey: [QueryKeys.VERIFICATION_POSITIONS, filterData],
        queryFn: async () => {
            return await ReportingApi.getVerificationPositions(filterData);
        },
    });

    if (locationsQuery.isLoading) return <ChartSkeleton />;

    return <MapChart id="historical-locations" title="TPV Locations" data={locationsQuery.data ?? []} type="flag" />;
}
