import { useMutation } from "@tanstack/react-query";

import { ReportingApi } from "../../../api/reportingApi";
import { formatDateTimeOf } from "../../../helpers";
import type { GeneratedReportExportDto } from "../../../models/generatedReportDto";
import { Button } from "../../basic";

interface ExportProps {
    export: GeneratedReportExportDto | undefined;
}

export function ExportLink(props: ExportProps) {
    const mutation = useMutation({
        mutationFn: () => ReportingApi.reportExport(props.export?.fileIdentifier ?? ""),
        onSuccess: (data) => {
            window.open(data, "_blank");
        },
    });

    if (!props.export) return null;

    return (
        <Button
            variant="link"
            className="h-6 p-0"
            onClick={() => {
                mutation.mutate();
            }}
        >
            {`${formatDateTimeOf(props.export.createdOn, false) ?? ""} (${props.export.records})`}
        </Button>
    );
}
