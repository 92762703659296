import { useContext, useState } from "react";

import { ReportingApi } from "../api/reportingApi";
import { Column } from "../components/basic";
import { FilterGroups } from "../components/gridFilters/types";
import { GridPage } from "../components/gridPage";
import { ExportLink } from "../components/reporting/generated/exportLink";
import { GeneratedDetails } from "../components/reporting/generated/generatedDetails";
import { UserContext } from "../contexts/userContext";
import {
    QueryKeys,
    Severity,
    formatBool,
    logger,
    splitWordsOnUpperCase,
    usePropReducer,
    useTransitionState,
} from "../helpers";
import { type GeneratedFilter, type IFilter } from "../models";
import { type GeneratedReportDto } from "../models/generatedReportDto";

function dateRangeFormat(item: GeneratedReportDto) {
    if (!item.rollingPrevious) return splitWordsOnUpperCase(item.dateRangeType);

    return `Rolling ${item.rollingPrevious} ${splitWordsOnUpperCase(item.dateRangeType)}`;
}

function formatLastRun(item: GeneratedReportDto) {
    if (!item.lastRun) return "";

    return <ExportLink export={item.lastRun} />;
}

const initialState: GeneratedFilter = {
    endDate: undefined,
    includesDataEndDate: undefined,
    includesDataStartDate: undefined,
    startDate: undefined,
    clients: [],
    channels: [],
    states: [],
    active: true,
};

export default function Generated() {
    const { user } = useContext(UserContext);
    const [filterData, dispatchFilterData] = usePropReducer(initialState);
    const [itemsPerPage, setItemsPerPage] = useTransitionState(10);
    const [currentPage, setCurrentPage] = useTransitionState(1);
    const [sortFilter, setSortFilter] = useState<IFilter>({});

    const columns = [
        new Column("Last Run").withFormat(formatLastRun),
        new Column("Name", "name").withFormat((s: string) => splitWordsOnUpperCase(s)),
        new Column("Active", "active").withFormat(formatBool),
        new Column("Client", "clientName"),
        new Column("Channel", "channelName"),
        new Column("State", "stateName"),
        new Column("Date Range Type").withFormat(dateRangeFormat),
    ] as Column<GeneratedReportDto>[];

    async function getData() {
        try {
            return await ReportingApi.generateReports(itemsPerPage, currentPage, {
                ...filterData,
                ...sortFilter,
            });
        } catch (ex) {
            logger(Severity.Error, ex);
        }
    }

    if (!user) return null;

    return (
        <GridPage<GeneratedFilter, GeneratedReportDto>
            filters={filterData}
            updateFilter={dispatchFilterData}
            visibleFilters={[FilterGroups.Segment, FilterGroups.Active, FilterGroups.Date, FilterGroups.DataPeriod]}
            queryKey={QueryKeys.GENERATED_REPORTS}
            getData={getData}
            columns={columns}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={(itemsPerPage: number) => setItemsPerPage(itemsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            sortFilter={sortFilter}
            setSortFilter={setSortFilter}
            expandable
            getRowCanExpand={() => true}
            renderSubComponent={(row) => <GeneratedDetails row={row.row} />}
        />
    );
}
