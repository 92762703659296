import {
    type SortingState,
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { type ReactNode, useState } from "react";

import { useBreakpoint } from "../../../helpers";
import { type IFilter } from "../../../models";
import { type CustomTable } from "./dataGrid";
import { Loading } from "./loading";
import { DataGridMobile } from "./mobile";
import { type Column } from "./models/column";
import { DataGridPagination } from "./pagination";
import { DataGridTableBody } from "./tableBody";
import { DataGridTableHead } from "./tableHead";
import { mapColumn } from "./utils";

interface DataGridClientProps<T> {
    title?: string;
    data: T[];
    columns: Column<T>[];
    loading?: boolean;
    pageSizeOptions?: number[];
    setSortFilter?: (sortFilter?: IFilter) => void;
    options?: (row: T) => ReactNode;
    embedded?: boolean;
}

export function DataGridClient<T>({
    title,
    data,
    columns,
    loading,
    pageSizeOptions,
    options,
    embedded,
}: DataGridClientProps<T>) {
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sorting, setSorting] = useState<SortingState>([]);
    const columnHelper = createColumnHelper<T>();
    const { isSm } = useBreakpoint("sm");

    const table = useReactTable({
        data: embedded ? data : data.slice((page - 1) * itemsPerPage, page * itemsPerPage),
        columns: columns.map(mapColumn(columnHelper)),
        pageCount: Math.ceil(data.length / (itemsPerPage ?? 10)),
        state: {
            sorting,
        },
        manualPagination: true,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
    }) as CustomTable<T>;

    return (
        <div className="sm:card !p-0 text-left">
            {title ? (
                <div className="p-4">
                    <h2 className="title">{title}</h2>
                </div>
            ) : null}
            <div className="overflow-x-auto">
                {isSm ? (
                    <div className="min-w-full divide-y divide-gray-300">
                        <DataGridMobile<T> table={table} options={options} />
                    </div>
                ) : (
                    <table className="min-w-full divide-y divide-gray-300">
                        <DataGridTableHead<T> table={table} options={options} />
                        {loading ? <Loading table={table} /> : <DataGridTableBody<T> table={table} options={options} />}
                    </table>
                )}
            </div>
            {!embedded && itemsPerPage && setItemsPerPage ? (
                <DataGridPagination<T>
                    page={page}
                    setPage={setPage}
                    table={table}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    pageSizeOptions={pageSizeOptions ?? [10, 50, 100]}
                    totalItems={data.length}
                />
            ) : null}
        </div>
    );
}
