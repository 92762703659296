import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type FC, type FormEvent, useContext, useState } from "react";

import { UsersApi } from "../../api/usersApi";
import { UserContext } from "../../contexts/userContext";
import { QueryKeys, splitWordsOnUpperCase } from "../../helpers";
import { type UserDto } from "../../models";
import { SelectableUserPermissionFlags } from "../../models/permissions";
import { Button, Checkbox, TextBox, useErrorToast, useSuccessToast } from "../basic";
import { ChannelsDropdown } from "../dropdowns/channelsDropdown";
import { ClientsDropdown } from "../dropdowns/clientsDropdown";
import { StatesDropdown } from "../dropdowns/statesDropdown";

interface State {
    email: string;
    firstName: string;
    lastName: string;
    permissionFlags: number | undefined;
}

export const UpdateUserForm: FC = () => {
    const { user } = useContext(UserContext);

    const [state, setState] = useState<State>({
        email: user?.email ?? "",
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        permissionFlags: user?.permissionFlags,
    });
    const queryClient = useQueryClient();
    const successToast = useSuccessToast();
    const errorToast = useErrorToast();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        userMutation.mutate({
            ...user,
            ...state,
        } as UserDto);
    };

    const userMutation = useMutation({
        mutationFn: UsersApi.saveUser,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.USER] });
            successToast("User Updated", "User updated successfully");
        },
        onError: () => {
            errorToast("Update User", "User updated failed");
        },
    });

    // We don't want this to do anything, but we need to pass it to the dropdowns
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const handleSelect = () => {};

    if (!user) return null;

    return (
        <form className="card flex-1" onSubmit={handleSubmit}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="title">Personal Information</h2>
                    <div className="mt-10 grid grid-cols-2 gap-2">
                        <TextBox
                            id="firstName"
                            label="First Name"
                            value={state.firstName}
                            onChange={(e) => setState({ ...state, firstName: e.target.value })}
                            required
                        />
                        <TextBox
                            id="lastName"
                            label="Last Name"
                            value={state.lastName}
                            onChange={(e) => setState({ ...state, lastName: e.target.value })}
                            required
                        />
                    </div>
                    <div className="mt-2 grid grid-cols-1 gap-2">
                        <TextBox
                            id="email"
                            label="Email"
                            value={state.email}
                            onChange={(e) => setState({ ...state, email: e.target.value })}
                            required
                            disabled
                        />
                        <StatesDropdown id="state" value={user.stateId} isDisabled selectVal={handleSelect} />
                        <ClientsDropdown id="state" value={user.clientId} isDisabled selectVal={handleSelect} />
                        <ChannelsDropdown id="state" value={user.channelId} isDisabled selectVal={handleSelect} />
                    </div>
                    <div className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2">
                        <Checkbox id="isOwner" label="Is Owner" checked={user.isOwner} disabled />
                        {Object.keys(SelectableUserPermissionFlags).map((permission) => {
                            if (isNaN(Number(permission))) {
                                return null;
                            }

                            const enumVal = parseInt(permission);
                            const fieldVal = parseInt(state.permissionFlags?.toString() ?? "");

                            return (
                                <Checkbox
                                    id={`permissionFlags-${enumVal}`}
                                    key={enumVal}
                                    disabled
                                    label={splitWordsOnUpperCase(SelectableUserPermissionFlags[enumVal] ?? "")}
                                    checked={(fieldVal & enumVal) === enumVal}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <Button type="submit" variant="default">
                    Submit
                </Button>
            </div>
        </form>
    );
};
