import { type FC } from "react";

import { TextSkeleton } from "./textSkeleton";

interface TextOrLoadingProps {
    loading?: boolean;
    text: string | number | null | undefined;
}

export const TextOrLoading: FC<TextOrLoadingProps> = (props) => {
    if (props.loading) return <TextSkeleton />;

    return <>{props.text}</>;
};
