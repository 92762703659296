import { useReducer } from "react";

/** Wraps React's useReducer with a basic reducer that just takes actions of individual property updates */
export function usePropReducer<T>(initialState: T) {
    const [state, dispatch] = useReducer(simpleReducer, initialState);
    const update = (...props: PropUpdate[]) => props.forEach(dispatch);
    return [state, update] as [T, (...props: PropUpdate[]) => void];
    function simpleReducer(state: T, action: PropUpdate): T {
        const actionObj = { [action.name]: action.value };

        return Object.assign({}, state, actionObj);
    }
}
type PropUpdate = { name: string; value: object };
