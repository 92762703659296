import { format } from "date-fns";

import { DATE_FORMAT } from "../components/basic";
import { Severity, logger } from "../helpers";
import { type CampaignDto, type FormFilter, type GridResult } from "../models";
import { type FormPrintBatchDto } from "../models";
import { type FormTemplateDto } from "../models/formTemplateDto";
import { doGet, doPost } from "./httpHandler";

const controller = "/api/Form";

export class FormApi {
    public static getForms = (
        signal: AbortSignal,
        itemsPerPage = 10,
        currentPage = 1,
        filters?: Partial<FormFilter>,
    ) => {
        try {
            return doPost<GridResult<FormPrintBatchDto>, Partial<FormFilter>>(
                controller,
                "GetAll",
                { itemsPerPage, currentPage },
                filters,
                { signal },
            );
        } catch (e) {
            logger(Severity.Error, e);
        }
    };

    public static createFormPrintBatch = (params: {
        representativeId: number;
        formTemplateId: number;
        size: number;
    }) => {
        return doGet<File>(controller, "CreateFormPrintBatches", params);
    };

    public static saveFormTemplate = (form: FormTemplateDto) => {
        form = {
            ...form,
            activeStart: form.activeStart && new Date(form.activeStart),
            activeEnd: form.activeEnd && new Date(form.activeEnd),
        };

        const formData = new FormData();
        form.id && formData.append("id", form.id.toString());
        form.createdOn && formData.append("createdOn", form.createdOn.toUTCString());
        form.updatedOn && formData.append("updatedOn", form.updatedOn.toUTCString());
        formData.append("campaignId", form.campaignId);
        formData.append("active", form.active.toString());
        formData.append("repSpecific", form.repSpecific.toString());
        form.activeStart && formData.append("activeStart", format(form.activeStart, DATE_FORMAT));
        form.activeEnd && formData.append("activeEnd", format(form.activeEnd, DATE_FORMAT));
        form.notes && formData.append("notes", form.notes);
        formData.append("frontPages", form.frontPages.toString());
        formData.append("backPages", form.backPages.toString());
        formData.append("lineBarcodesInvisible", form.lineBarcodesInvisible.toString());
        if (form.formPdf) formData.append("formPdf", form.formPdf);

        return doPost<FormPrintBatchDto, FormData>(controller, "save", undefined, formData);
    };

    public static getFormTemplateUrl = (id: number) => {
        return doGet<string>(controller, "GetFormTemplateUrl", { id });
    };

    public static getFormTemplate = (id: number) => {
        return doGet<FormTemplateDto>(controller, "GetFormTemplate", { id });
    };

    public static getFormPrintBatchUrl = (id: number) => {
        return doGet<string>(controller, "GetFormPrintBatchUrl", {
            id,
        });
    };

    public static getCampaignByBatchId = (batchId: number) => {
        try {
            return doGet<CampaignDto>(controller, "GetCampaignByBatchId", { batchId });
        } catch (e) {
            logger(Severity.Error, e);
        }
    };
}
