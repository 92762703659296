import { useQuery } from "@tanstack/react-query";

import { ReportingApi } from "../../../api/reportingApi";
import { QueryKeys } from "../../../helpers";
import { type ReportingFilter } from "../../../models";
import { ChartSkeleton } from "../../basic";
import { HeatmapChart } from "../../basic";

export interface Props {
    filterData: ReportingFilter;
}

export function HeatMapWidget(props: Props) {
    const { filterData } = props;

    const heatmapQuery = useQuery({
        queryKey: [QueryKeys.VERIFICATION_HEATMAP, filterData],
        queryFn: async () => {
            return await ReportingApi.getVerificationHeatmap(filterData);
        },
    });

    if (heatmapQuery.isLoading) return <ChartSkeleton />;

    return <HeatmapChart id="historical-heatmap" title="Completed TPVs" data={heatmapQuery.data ?? []} />;
}
