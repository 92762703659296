import { type FC, useState } from "react";

import { type UserDto } from "../../models";
import { Dialog } from "../basic";
import { DataGridOptions } from "../basic/dataGrid/options";
import { CreateUpdateUser } from "./createUpdateUser";

interface OptionsProps {
    user: UserDto;
    toggle: (ids: number[], active: boolean) => void;
}

export const Options: FC<OptionsProps> = (props) => {
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

    return (
        <>
            <DataGridOptions
                actions={[
                    { name: "Edit", onClick: () => setOpenEditDialog(true) },
                    {
                        name: props.user.active ? "Deactivate" : "Activate",
                        onClick: () => props.toggle([props.user.id], !props.user.active),
                    },
                ]}
            />
            <Dialog
                open={openEditDialog}
                setOpen={setOpenEditDialog}
                title="Edit User"
                body={<CreateUpdateUser user={props.user} setOpen={setOpenEditDialog} create={false} />}
            />
        </>
    );
};
