import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { FormApi } from "../../api/formApi";
import { MS_IN, QueryKeys } from "../../helpers";
import { type FormTemplateDto } from "../../models/formTemplateDto";
import { Button, Dialog } from "../basic";
import { DataGridOptions } from "../basic/dataGrid/options";
import { CreateUpdateFormTemplate } from "./createUpdateFormTemplate";

export const Options = (formTemplate: FormTemplateDto) => {
    const [show, setShown] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

    const fileUrlQuery = useQuery({
        queryKey: [QueryKeys.GET_FORM_TEMPLATE, formTemplate.id],
        queryFn: async () => {
            return FormApi.getFormTemplateUrl(formTemplate.id);
        },
        enabled: show,
        gcTime: MS_IN.MINUTE,
    });

    return (
        <>
            <DataGridOptions
                actions={[
                    { name: "View", onClick: () => setShown(true) },
                    { name: "Edit", onClick: () => setOpenEditDialog(true) },
                ]}
            />
            <Dialog
                open={show}
                setOpen={() => undefined}
                title="Print Form Template"
                body={<iframe title="Form Template" src={fileUrlQuery.data} width="765" height="600" />}
                actions={
                    <Button variant="default" className="col-start-2" onClick={() => setShown(false)}>
                        Close
                    </Button>
                }
            />
            <Dialog
                open={openEditDialog}
                setOpen={setOpenEditDialog}
                title="Edit Form Template"
                body={
                    <CreateUpdateFormTemplate formTemplate={formTemplate} setOpen={setOpenEditDialog} create={false} />
                }
            />
        </>
    );
};
