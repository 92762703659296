import { type FC, useEffect, useRef } from "react";

import { TextBox } from "../basic";
import { type IConfig } from "./models";
import { getKey } from "./utils";

interface BarcodeProps {
    value: string;
    setValue: (value: string) => void;
}

export const BarcodeScanner: FC<BarcodeProps> = ({ value, setValue }) => {
    const config: IConfig = {
        intervalBetweenKeyPress: 10,
        scanningEndTimeout: 200,
        ignoreOnInputs: false,
        historyLength: 0,
    };

    const isBusy = useRef(false);
    const keyDownTime = useRef<number | null>(null);
    const inputText = useRef("");

    useEffect(() => {
        function handleKeydown(e: KeyboardEvent) {
            const d = new Date();

            const character = getKey(e.shiftKey, e.code);

            if (character === "") {
                return;
            }
            if (isBusy.current) {
                e.preventDefault();
            }
            if (config.ignoreOnInputs) {
                return;
            }
            if (keyDownTime.current === null) {
                keyDownTime.current = d.getTime();
                inputText.current = character;
            } else {
                const newTime = d.getTime();
                if (newTime - keyDownTime.current < config.intervalBetweenKeyPress) {
                    inputText.current += character;
                    isBusy.current = true;
                    setTimeout(() => {
                        isBusy.current = false;
                        if (inputText.current.length > 6) setValue(inputText.current);
                        inputText.current = "";
                    }, config.scanningEndTimeout);
                } else {
                    inputText.current = getKey(e.shiftKey, e.code);
                }
                keyDownTime.current = newTime;
            }
        }

        window.addEventListener("keydown", handleKeydown);
        return () => {
            window.removeEventListener("keydown", handleKeydown);
        };
    }, [config.ignoreOnInputs, config.intervalBetweenKeyPress, config.scanningEndTimeout, setValue]);

    if (process.env.NODE_ENV === "development") {
        return (
            <div className="my-8">
                <TextBox label="Development Barcode Entry" value={value} onChange={(e) => setValue(e.target.value)} />
            </div>
        );
    }

    return null;
};
