import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highmaps";
import highchartsAccessibility from "highcharts/modules/accessibility";
import highchartsMap from "highcharts/modules/map";
import { type FC, useEffect, useMemo, useRef, useState } from "react";

import { MAX_INT, first, last } from "../../../helpers";
import "./charts.scss";
import { NoData } from "./noData";

export interface HeatmapData {
    date: Date;
    time: number;
    z: number;
}

interface HeatmapChartProps extends HighchartsReact.Props {
    id: string;
    title: string;
    data: HeatmapData[];
}

function toTimestamp(d: Date) {
    return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
}

highchartsMap(Highcharts);
highchartsAccessibility(Highcharts);

export const HeatmapChart: FC<HeatmapChartProps> = (props) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const [options, setOptions] = useState<Highcharts.Options>();

    const [min, max, ticks] = useMemo(() => {
        if (!props.data || props.data.length === 0) return [0, 23, [0, 5, 11, 17, 23]];

        let newMin = MAX_INT;
        let newMax = 0;
        const newTicks = [];

        props.data.forEach((d) => {
            if (d.time < newMin) newMin = d.time;
            if (d.time > newMax) newMax = d.time;
        });

        const tickInterval = Math.floor((newMax - newMin) / 5);

        if (tickInterval === 0) return [newMin, newMax, [newMin, newMax]];

        for (let i = newMin; i <= newMax; i += tickInterval) {
            newTicks.push(i);
        }

        return [newMin, newMax, newTicks];
    }, [props.data]);

    useEffect(() => {
        if (!props.data || props.data.length === 0) return;

        setOptions({
            chart: {
                inverted: true,
                styledMode: true,
            },
            credits: {
                enabled: false,
            },
            title: {
                text: props.title,
                align: "left",
            },
            tooltip: {
                formatter: function () {
                    return `TPVs: ${this.point.value ?? "0"}`;
                },
            },
            xAxis: {
                min: toTimestamp(first(props.data)?.date ?? new Date()),
                max: toTimestamp(last(props.data)?.date ?? new Date()),
                labels: {
                    format: "{value:%B / %d}", // long month
                },
            },
            yAxis: {
                accessibility: {
                    description: "Hours in the day",
                },
                title: {
                    text: null,
                },
                labels: {
                    format: "{value}:00",
                },
                lineWidth: 1,
                minPadding: 0,
                maxPadding: 0,
                startOnTick: false,
                endOnTick: false,
                tickPositions: ticks,
                tickWidth: 1,
                min,
                max,
            },
            colorAxis: {
                stops: [
                    [0, "#3b82f6"],
                    [0.5, "#d946ef"],
                    [0.9, "#14b8a6"],
                ],
                min: 0,
            },
            series: [
                {
                    type: "heatmap",
                    name: "TPVs",
                    data: props.data.map((d) => [Date.parse(d.date.toDateString()), d.time, d.z]),
                    borderWidth: 0,
                    colsize: 24 * 36e5, // one day
                    accessibility: {
                        enabled: true,
                    },
                },
            ],
        });
    }, [max, min, props.data, props.title, ticks]);

    if (!options) return null;

    if (props.data?.length === 0) return <NoData title={props.title} />;

    return (
        <div className="card">
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
                constructorType={"mapChart"}
            />
        </div>
    );
};
