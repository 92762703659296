import { type FC } from "react";

import { ChangePasswordForm } from "../../components/settings/changePasswordForm";
import { ChangeThemeForm } from "../../components/settings/changeThemeForm";
import { UpdateUserForm } from "../../components/settings/updateUserForm";

export const Settings: FC = () => {
    return (
        <div className="flex flex-col justify-center gap-2 sm:flex-row">
            <UpdateUserForm />
            <div className="flex h-[32rem] flex-1 flex-col gap-2">
                <ChangePasswordForm />
                <ChangeThemeForm />
            </div>
        </div>
    );
};
