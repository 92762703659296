import DOMPurify from "dompurify";
import { type FC, useMemo } from "react";

interface SafeHtmlProps {
    html: string | undefined;
    className?: string;
}

export const SafeHtml: FC<SafeHtmlProps> = ({ html, className = "" }) => {
    const clean = useMemo(() => {
        return DOMPurify.sanitize(html ?? "");
    }, [html]);

    return <div className={className} dangerouslySetInnerHTML={{ __html: clean }} />;
};
