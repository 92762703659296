import { type FC } from "react";
import { Link } from "react-router-dom";

import { styles } from "../../../helpers";

interface TabProps {
    name: string;
    current: boolean;
    to: string;
}

export const TabLink: FC<TabProps> = ({ name, current, to }) => {
    return (
        <Link
            key={name}
            to={to}
            className={styles(
                current
                    ? "border-indigo-400 text-indigo-400 hover:border-indigo-600"
                    : "border-transparent hover:border-gray-300",
                "w-1/4 border-b-2 px-1 py-4 text-center text-sm font-medium",
            )}
            aria-current={current ? "page" : undefined}
        >
            {name}
        </Link>
    );
};
