import { useQuery } from "@tanstack/react-query";
import { type FC, type PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router-dom";

import { UserApi } from "../../api/loginApi";
import { UserContext } from "../../contexts/userContext";
import { QueryKeys, RouteValues, getPayloadCookieAsType } from "../../helpers";
import { type UserDto } from "../../models";
import { Spinner } from "../basic";

type AuthWrapperProps = PropsWithChildren<{ allowAnonymous?: boolean; isChangingPassword?: boolean }>;

export const AuthWrapper: FC<AuthWrapperProps> = ({ children, allowAnonymous, isChangingPassword }) => {
    const user = getPayloadCookieAsType<UserDto>();

    const { user: ctxUser, setUser } = useContext(UserContext);

    const userQuery = useQuery({
        queryKey: [QueryKeys.USER, user?.id],
        queryFn: async () => {
            const updatedUser = await UserApi.get();

            setUser(updatedUser);

            return updatedUser;
        },
        enabled: !!user && !!user.id,
    });

    if (user == null && !allowAnonymous) {
        return <Navigate to={`/${RouteValues.LOGIN}`} />;
    }

    if (!isChangingPassword && ctxUser?.mustChangePassword) return <Navigate to={`/${RouteValues.CHANGE_PASSWORD}`} />;

    if (userQuery.isLoading) {
        return (
            <div className="flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    return <>{children}</>;
};
