import { PlusIcon } from "@heroicons/react/24/outline";
import { type Row } from "@tanstack/react-table";
import { type FC, useMemo, useState } from "react";

import { formatActive, formatBool, formatDateOf } from "../../helpers";
import { useReplaceVerbiage } from "../../hooks/verbiageHooks";
import { type CampaignDto } from "../../models";
import { type FormTemplateDto } from "../../models/formTemplateDto";
import { Button, Column, DataGridClient, Dialog } from "../basic";
import { CreateUpdateFormTemplate } from "./createUpdateFormTemplate";
import { Options } from "./options";

interface RenderSubComponentProps {
    row: Row<CampaignDto>;
}

export const SubComponent: FC<RenderSubComponentProps> = (props) => {
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const replaceVerbiage = useReplaceVerbiage();

    const columns = useMemo(() => {
        return [
            new Column("Active", "active").withFormat(formatActive),
            new Column(replaceVerbiage("Rep", "Rep Specific"), "repSpecific").withFormat(formatBool),
            new Column("Notes", "notes"),
            new Column("Active Start", "activeStart").withFormat(formatDateOf),
            new Column("Active End", "activeEnd").withFormat(formatDateOf),
            new Column("Front Pages", "frontPages"),
            new Column("Back Pages", "backPages"),
        ] as Column<FormTemplateDto>[];
    }, [replaceVerbiage]);

    return (
        <>
            <div className="p-4">
                <div className="mb-2 flex items-center justify-between">
                    <h3 className="text-left text-lg font-bold">Form Templates</h3>
                    <Button onClick={() => setOpenCreateDialog(true)}>
                        <PlusIcon className="w-4" />
                        New Form Template
                    </Button>
                </div>
                <div className="rounded border border-gray-50">
                    <DataGridClient
                        data={props.row.original.formTemplates ?? []}
                        columns={columns}
                        embedded
                        options={Options}
                    />
                </div>
            </div>
            <Dialog
                open={openCreateDialog}
                setOpen={setOpenCreateDialog}
                title="Add Form Template"
                body={
                    <CreateUpdateFormTemplate
                        formTemplate={
                            {
                                active: true,
                                repSpecific: true,
                                frontPages: 0,
                                backPages: 0,
                                campaignId: props.row.original.id.toString(),
                            } as FormTemplateDto
                        }
                        setOpen={setOpenCreateDialog}
                        create
                    />
                }
            />
        </>
    );
};
