import { type FC } from "react";

import CheckInGrid from "../components/checkIn/checkInGrid";
import { useRoutePermissions } from "../helpers";
import { UserPermissionFlags } from "../models/permissions";

export const CheckInPage: FC = () => {
    useRoutePermissions(UserPermissionFlags.FormsScan);

    return <CheckInGrid />;
};
