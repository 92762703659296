import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { GridFiltersApi } from "../../api/gridFiltersApi";
import { QueryKeys } from "../../helpers";
import { type DropdownProps, FilterDropdown } from "./filterDropDown";

export const ClientsDropdown: FC<DropdownProps> = (props) => {
    const query = useQuery({ queryKey: [QueryKeys.CLIENTS_FILTER], queryFn: () => GridFiltersApi.getClients() });

    return <FilterDropdown id="clients" label="Client" {...props} query={query} />;
};
