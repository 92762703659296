import { type FC, type HTMLProps, useEffect, useRef } from "react";

import { styles } from "../../../helpers";

interface CheckboxProps extends HTMLProps<HTMLInputElement> {
    label?: string;
    inline?: boolean;
    indeterminate?: boolean;
}

export const IndeterminateCheckbox: FC<CheckboxProps> = (props) => {
    const { label, inline, indeterminate, className, ...rest } = props;
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (typeof indeterminate === "boolean" && ref.current) {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate, rest.checked]);

    return (
        <div className={!inline ? "flex flex-col justify-start" : "flex items-center"}>
            <label htmlFor={rest.id} className={styles(inline ? "mr-2" : "", "label")}>
                {label}
            </label>
            <input id={rest.id} type="checkbox" ref={ref} className={styles("checkbox", className ?? "")} {...rest} />
        </div>
    );
};
