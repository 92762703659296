export class RouteValues {
    public static HOME = "";
    public static LOGIN = "login";
    public static FORGOT_PASSWORD = "forgot-password";
    public static CHANGE_PASSWORD = "change-password";
    public static REPS = "reps";
    public static USERS = "users";
    public static FORMS = "forms";
    public static CAMPAIGNS = "campaigns";
    public static CHECK_IN = "check-in";
    public static AUDIT = "audit";
    public static REALTIME = "realtime";
    public static HISTORICAL = "historical";
    public static GENERATED = "generated";
    public static SETTINGS = "settings";
    public static REPORTS = "reports";
}
