import { ToggleButton } from "../../basic";
import { type ActiveFilterProps } from "../types";

export default function ActiveFilter(props: ActiveFilterProps) {
    const { visible, filters, changeHandler } = props;

    const values = [
        { id: false, value: "Inactive" },
        { id: true, value: "Active" },
    ];

    if (!visible) return null;

    return (
        <ToggleButton
            id="active"
            label="Active"
            values={values}
            value={filters.active}
            changeHandler={changeHandler}
            clearable
        />
    );
}
