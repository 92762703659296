import { Navigate, createBrowserRouter } from "react-router-dom";

import { Auth } from "./components/auth/Auth";
import { CheckInProviders } from "./components/checkIn/checkInProviders";
import { RouteValues } from "./helpers";
import { AuditPage } from "./pages/audit";
import CampaignsPage from "./pages/campaigns";
import { CheckInPage } from "./pages/checkIn";
import FormsPage from "./pages/forms";
import Generated from "./pages/generated";
import Historical from "./pages/historical";
import Home from "./pages/home";
import RealTime from "./pages/realtime";
import { Reports } from "./pages/reports";
import Representatives from "./pages/representatives";
import { ScanPage } from "./pages/scan";
import { StartAuditPage } from "./pages/startAudit";
import ChangePassword from "./pages/user/changePassword";
import ForgotPassword from "./pages/user/forgotPassword";
import Login from "./pages/user/login";
import { Settings } from "./pages/user/settings";
import Users from "./pages/users";

export const router = createBrowserRouter([
    {
        path: RouteValues.LOGIN,
        element: <Login />,
    },
    {
        path: RouteValues.FORGOT_PASSWORD,
        element: <ForgotPassword />,
    },
    {
        path: RouteValues.CHANGE_PASSWORD,
        element: <Auth page={<ChangePassword />} allowAnonymous isChangingPassword />,
    },
    {
        path: `${RouteValues.CHANGE_PASSWORD}/:forgotKey`,
        element: <Auth page={<ChangePassword />} allowAnonymous isChangingPassword />,
    },
    {
        path: RouteValues.HOME,
        element: <Auth page={<Home />} />,
    },
    {
        path: RouteValues.REPS,
        element: <Auth page={<Representatives />} />,
    },
    {
        path: RouteValues.USERS,
        element: <Auth page={<Users />} />,
    },
    {
        path: RouteValues.FORMS,
        element: <Auth page={<FormsPage />} />,
    },
    {
        path: RouteValues.CAMPAIGNS,
        element: <Auth page={<CampaignsPage />} />,
    },
    {
        path: RouteValues.CHECK_IN,
        element: <Auth page={<CheckInProviders />} />,
        children: [
            {
                path: "",
                element: <CheckInPage />,
            },
            {
                path: ":id",
                element: <ScanPage />,
            },
        ],
    },
    {
        path: RouteValues.AUDIT,
        element: <Auth page={<StartAuditPage />} />,
    },
    {
        path: `${RouteValues.AUDIT}/:id`,
        element: <Auth page={<AuditPage />} />,
    },
    {
        path: RouteValues.REPORTS,
        element: <Auth page={<Reports />} />,
        children: [
            {
                path: RouteValues.REALTIME,
                element: <RealTime />,
            },
            {
                path: RouteValues.HISTORICAL,
                element: <Historical />,
            },
            {
                path: RouteValues.GENERATED,
                element: <Generated />,
            },
            {
                path: "",
                element: <Navigate to={RouteValues.REALTIME} />,
            },
        ],
    },
    {
        path: RouteValues.SETTINGS,
        element: <Auth page={<Settings />} />,
    },
    {
        path: "*",
        element: <Navigate to={RouteValues.HOME} />,
    },
]);
