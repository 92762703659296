import { CampaignDropdown } from "../../dropdowns/campaignDropdown";
import { RepsDropdown } from "../../dropdowns/repsDropdown";
import { type RepFilterProps } from "../types";

export default function RepFilter(props: RepFilterProps) {
    const { visible, filters, selectVal } = props;

    if (!visible) return null;

    return (
        <>
            <CampaignDropdown value={filters.campaigns} selectVal={selectVal} isMulti autoHide isClearable />
            <RepsDropdown
                value={filters.representativeId}
                campaignIds={filters.campaigns}
                selectVal={selectVal}
                autoHide
                isClearable
            />
        </>
    );
}
