import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { VerificationsApi } from "../../api/verificationsApi";
import { QueryKeys } from "../../helpers";
import { type AllGridFilters, type IDispositionFilter } from "../../models";
import { type DropdownProps, FilterDropdown } from "./filterDropDown";

interface Props extends DropdownProps {
    filterData?: Partial<IDispositionFilter>;
}

export const DispositionsDropdown: FC<Props> = (props) => {
    // I just want to remove the ids from the filterData
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { dispositions, ...filters } = props.filterData as AllGridFilters;

    const query = useQuery({
        queryKey: [QueryKeys.TPV_DISPOSITIONS, filters],
        queryFn: async () => {
            const responses = await VerificationsApi.getDispositions(filters);

            if (!responses) return [];

            return responses.map((item) => ({ label: item.name, value: item.id }));
        },
    });

    return <FilterDropdown id="dispositions" label="Disposition" {...props} query={query} />;
};
