import { type UseQueryResult } from "@tanstack/react-query";
import { type FC } from "react";

import { InputSkeleton } from "../basic";
import { Select, type SelectOption, type SelectProps } from "../basic";

export interface DropdownProps extends Partial<SelectProps> {
    selectVal: (val: SelectOption, id: string) => void;
    autoHide?: boolean;
    chooseFirst?: boolean;
}

export interface FilterDropdownProps extends Partial<SelectProps> {
    query: UseQueryResult<SelectOption[]>;
    selectVal: (val: SelectOption, id: string) => void;
    autoHide?: boolean;
    chooseFirst?: boolean;
}

export const FilterDropdown: FC<FilterDropdownProps> = (props: FilterDropdownProps) => {
    const { query, selectVal } = props;
    const length = query.data?.length ?? 0;

    const changeHandler = (val: SelectOption) => {
        selectVal(val, props.id ?? "filterDropdown");
    };

    if (query.isLoading) return <InputSkeleton />;

    if (props.autoHide && length < 2)
        if (!props.value || (props.isMulti && (props.value as any[])?.length === 0)) return null;

    return (
        <Select
            {...props}
            options={query.data}
            changeHandler={changeHandler}
            defaultValue={props.chooseFirst ? query.data?.at(0) : undefined}
        />
    );
};
