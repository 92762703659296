export class QueryKeys {
    public static VERIFICATIONS = "Verifications";
    public static VERIFICATION_EVENTS = "VerificationEvents";
    public static VERIFICATION_FILES = "VerificationFiles";
    public static REP = "SingleRep";
    public static REPS = "Representatives";
    public static USERS = "Users";
    public static USER_EVENTS = "UserEvents";
    public static FORMS = "Forms";
    public static FORM_TEMPLATES = "FormTemplates";
    public static CAMPAIGN = "Campaign";
    public static CAMPAIGNS = "Campaigns";
    public static CAMPAIGNS_FILTER = "CampaignsFilter";
    public static CHANNELS_FILTER = "ChannelsFilter";
    public static CLIENTS_FILTER = "ClientsFilter";
    public static LANGUAGES_FILTER = "LanguagesFilter";
    public static STATES_FILTER = "StatesFilter";
    public static VER_MODES_FILTER = "VerificationModesFilter";
    public static VER_TYPES_FILTER = "VerificationTypesFilter";
    public static DISTRICTS_FILTER = "DistrictsFilter";
    public static TITLE = "Title";
    public static BASE_URL = "BaseUrl";
    public static TEMP_TOKEN = "TempToken";
    public static ALERTS = "Alerts";
    public static PRINT_FORM_BATCH_FILE_URL = "PrintFormBatchFileUrl";
    public static USERS_TOGGLE_MUTATION = "UsersToggleMutation";
    public static REPS_TOGGLE_MUTATION = "RepresentativeToggleMutation";
    public static USER = "User";
    public static AGENT_LEADERBOARD = "AgentLeaderboard";
    public static SALES_OUTCOMES = "SalesOutcomes";
    public static VERIFICATION_POSITIONS = "VerificationPositions";
    public static REP_POSITIONS = "RepPositions";
    public static VERIFICATION_HEATMAP = "VerificationHeatmap";
    public static NO_SALE_DISPOSITIONS = "NoSaleDispositions";
    public static TOPOLOGY = "Topology";
    public static PREVIOUS_SCAN = "PreviousScan";
    public static PREVIOUS_AUDIT = "PreviousAudit";
    public static DISPOSITIONS = "Dispositions";
    public static TPV_DISPOSITIONS = "TPVDispositions";
    public static AUDIT_BATCH_SUMMARY = "AuditBatchSummary";
    public static CHECK_IN = "CheckIn";
    public static AUDIT = "Audit";
    public static CHECK_IN_BATCH_SUMMARY = "CheckInBatchSummary";
    public static CAMPAIGN_REPORTS = "CampaignReports";
    public static CAMPAIGN_GOALS = "CampaignGoals";
    public static GET_FORM_TEMPLATE = "GetFormTemplate";
    public static PERMISSIONS = "Permissions";
    public static FORM_TEMPLATE = "FormTemplate";
    public static VERSION = "Version";
    public static AUDIT_QUESTIONS = "AuditQuestions";
    public static GENERATED_REPORTS = "GeneratedReports";
    public static SETTINGS_MAP = "MapSettings";
    public static RESPONSE_COUNTS = "SelfGeneratedResponseCounts";
    public static FORGOT_PASSWORD = "ForgotPassword";
}
