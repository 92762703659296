const keyToShiftedKey = new Map<string, string>(
    Object.entries({
        "/": "?",
        ".": ">",
        ",": "<",
        "'": '"',
        ";": ":",
        "[": "{",
        "]": "}",
        "\\": "|",
        "`": "~",
        "=": "+",
        "-": "_",
        "1": "!",
        "2": "@",
        "3": "#",
        "4": "$",
        "5": "%",
        "6": "^",
        "7": "&",
        "8": "*",
        "9": "(",
        "0": ")",
    }),
);

const codeToKey = new Map<string, string>(
    Object.entries({
        KeyA: "a",
        KeyB: "b",
        KeyC: "c",
        KeyD: "d",
        KeyE: "e",
        KeyF: "f",
        KeyG: "g",
        KeyH: "h",
        KeyI: "i",
        KeyJ: "j",
        KeyK: "k",
        KeyL: "l",
        KeyM: "m",
        KeyN: "n",
        KeyO: "o",
        KeyP: "p",
        KeyQ: "q",
        KeyR: "r",
        KeyS: "s",
        KeyT: "t",
        KeyU: "u",
        KeyV: "v",
        KeyW: "w",
        KeyX: "x",
        KeyY: "y",
        KeyZ: "z",
        Digit1: "1",
        Digit2: "2",
        Digit3: "3",
        Digit4: "4",
        Digit5: "5",
        Digit6: "6",
        Digit7: "7",
        Digit8: "8",
        Digit9: "9",
        Digit0: "0",
        // Enter: "enter",
        // Escape: "esc",
        // Backspace: "backspace",
        // Tab: "tab",
        Space: " ",
        Minus: "-",
        Equal: "=",
        BracketLeft: "[",
        BracketRight: "]",
        Backslash: "\\",
        Semicolon: ";",
        Quote: "'",
        Backquote: "`",
        Comma: ",",
        Period: ".",
        Slash: "/",
        // CapsLock: "caps",
        // F1: "f1",
        // F2: "f2",
        // F3: "f3",
        // F4: "f4",
        // F5: "f5",
        // F6: "f6",
        // F7: "f7",
        // F8: "f8",
        // F9: "f9",
        // F10: "f10",
        // F11: "f11",
        // F12: "f12",
        // PrintScreen: "print",
        // ScrollLock: "scroll",
        // Pause: "pause",
        // Insert: "insert",
        // Home: "home",
        // PageUp: "pageup",
        // Delete: "delete",
        // End: "end",
        // PageDown: "pagedown",
        // ArrowRight: "right",
    }),
);

export function getKey(isShifted: boolean, code: string): string {
    const key = codeToKey.get(code);
    if (!key) return "";

    if (isShifted && keyToShiftedKey.has(key)) return keyToShiftedKey.get(key) || "";
    else if (isShifted) return key.toUpperCase();
    return key;
}
