import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { RepresentativesApi } from "../../../api/representativesApi";
import { QueryKeys } from "../../../helpers";
import { type RepresentativeDto, type VerificationDto } from "../../../models";
import { Spinner } from "../../basic";
import { RepDetailView } from "../../representatives/details/repGridDetail";

interface Props {
    verification: VerificationDto;
    visible?: boolean;
}

export function RepData(props: Props) {
    const { verification } = props;
    const { repId } = verification;
    const repQuery = useQuery({
        queryKey: [QueryKeys.REP, repId],
        queryFn: () => RepresentativesApi.getRepresentative(repId),
    });
    const rep = useMemo(() => repQuery.data ?? ({} as RepresentativeDto), [repQuery.data]);

    if (!repQuery.isFetched) return <Spinner />;
    return (
        <>
            <RepDetailView rep={rep} readOnly />
        </>
    );
}
