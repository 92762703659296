import { addHours, format, startOfDay } from "date-fns";

import * as constants from "./constants";
import { hasValue } from "./general";

/**Formats name into a string */
export function formatName(firstName: string, lastName: string, normal?: boolean) {
    if (!firstName || !lastName) return firstName || lastName;
    if (!normal) return lastName + ", " + firstName;
    return firstName + " " + lastName;
}

/** Format city and state info into string */
export function formatCityState(city: string, state: string, zip: string | number) {
    let str = "";
    if (city && state) str = city + ", " + state;
    else if (city) str = city;
    else if (state) str = state;

    if (zip) {
        if (str) str += " ";
        str += zip;
    }
    return str;
}

/** Format phone number into single string
 * NOTE: currently doesn't handle extensions
 */
export function formatPhone(number: string | number) {
    if (!number) return number;
    let onlyDigits = number.toString().replace(/\D/g, "");
    if (onlyDigits.length < 10) return onlyDigits;
    let formatted = "";
    if (onlyDigits.length > 10) {
        const extraDigits = onlyDigits.length - 10;
        formatted += "+";
        formatted += onlyDigits.slice(0, extraDigits);
        formatted += " ";
        onlyDigits = onlyDigits.slice(extraDigits);
    }
    formatted += "(" + onlyDigits.slice(0, 3) + ") ";
    formatted += onlyDigits.slice(3, 6);
    formatted += "-" + onlyDigits.slice(6);
    return formatted;
}

/** Format phone number into single string
 * NOTE: currently doesn't handle extensions
 */
export function formatPhoneOnlyNumeric(number: string) {
    if (!number) return number;
    return number.replace(/\D/g, "");
}

export const formatFullTimeFromSeconds = (totalSeconds: number) => formatTimeFromSeconds(totalSeconds, true);

/** Formats total seconds into hours and minutes and seconds */
export function formatTimeFromSeconds(totalSeconds: number, full?: boolean) {
    if (!hasValue(totalSeconds)) return null;
    const totalMinutes = Math.floor(totalSeconds / constants.SECONDS_IN_MINUTE);
    const hours = Math.floor(totalMinutes / constants.MINUTES_IN_HOUR);
    const minutes = totalMinutes % constants.MINUTES_IN_HOUR;
    const seconds = totalSeconds % constants.SECONDS_IN_MINUTE;

    const displayingHours = full || hours;

    const dispSeconds = `${seconds < 10 ? "0" : ""}${seconds}`;
    const dispMinutes = `${displayingHours && minutes < 10 ? "0" : ""}${minutes}`;
    let time = dispMinutes + ":" + dispSeconds;
    if (displayingHours) time = `${hours}:"${time}`;
    return time;
}

/** Formats date into date-only string */
export function formatDateOf(date?: Date | string, fullYear = false) {
    if (!date) return null;
    if (typeof date === "string") date = new Date(date);

    return format(date, fullYear ? "PP" : "M/d/yy");
}

/** Formats date into time-only string */
export function formatTimeOf(date?: Date | string, displaySeconds = true) {
    if (!date) return null;
    if (typeof date === "string") date = new Date(date);

    return format(date, displaySeconds ? "h:mm:ss a" : "h:mm a");
}

export function intToTime(hour: number) {
    const date = addHours(startOfDay(new Date()), hour);
    return format(date, "haaa").toLowerCase(); // 'haaa' formats the hour, followed by am/pm
}

/** Formats date into date/time string */
export function formatDateTimeOf(date?: Date | string, displaySeconds = true, fullYear = false) {
    if (!date) return null;
    if (typeof date === "string") date = new Date(date);

    let formatString = "";

    if (fullYear) {
        formatString += "PP";
    } else {
        formatString += "M/d/yy";
    }

    if (displaySeconds) {
        formatString += " h:mm:ss a";
    } else {
        formatString += " h:mm a";
    }

    return format(date, formatString);
}

export const formatShortDateOf = (date?: Date | string) => formatDateOf(date, false);

/** Date Time, without seconds */
export const formatShortDateTimeOf = (date?: Date | string) => formatDateTimeOf(date, false, false);

export function formatHoldInUtc(date?: Date | string) {
    if (!date) return "";
    if (typeof date === "string") date = new Date(date);
    date.setMinutes(date.getTimezoneOffset());
    return date;
}

export function formatOriginalDateOf(date?: Date | string) {
    return formatDateOf(formatHoldInUtc(date));
}

export function formatDateWithSeparator(date: Date | string, separator: string) {
    if (!date) return "";
    if (typeof date === "string") date = new Date(date);
    let strDate = date.toISOString();
    strDate = strDate.substring(0, strDate.indexOf("."));
    return strDate.replaceAll(/[-T:]/gm, separator);
}

export function formatDateRange(
    start: Date | string,
    end: Date | string,
    showTime = false,
    showSeconds = true,
    showYear = false,
) {
    if (showTime) {
        return `${formatDateTimeOf(start, showSeconds, showYear) ?? ""} - ${formatDateTimeOf(end, showSeconds, showYear) ?? ""}`;
    }

    return `${formatDateOf(start, showYear) ?? ""} - ${formatDateOf(end, showYear) ?? ""}`;
}

export function formatActive(active: boolean) {
    return active ? "Active" : "Inactive";
}

export function formatBool(b: boolean) {
    return b ? "Yes" : "No";
}

export function splitWordsOnUpperCase(str: string) {
    return str?.replace(/(?<=[A-Z])(?=[A-Z][a-z])|(?<=[^A-Z])(?=[A-Z])|(?<=[A-Za-z])(?=[^A-Za-z])/gm, " ");
}

export function toStartCase(str: string) {
    return str?.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
}
export function toPercent(fraction: number | undefined, decimalPlaces: number, noValue: string = "") {
    if (fraction === null || fraction === undefined) return noValue;
    return (fraction * 100.0).toFixed(decimalPlaces) + "%";
}

export function safePercent(numerator: number, denominator: number, decimalPlaces: number, noValue: string = "") {
    if (numerator === 0) return noValue;
    return toPercent(numerator / denominator, decimalPlaces, noValue);
}
