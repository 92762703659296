import { useQuery } from "@tanstack/react-query";
import { useContext, useMemo } from "react";

import { VerificationsApi } from "../../../api/verificationsApi";
import { UserContext } from "../../../contexts/userContext";
import { QueryKeys, formatName } from "../../../helpers";
import { useMapVerbiage } from "../../../hooks/verbiageHooks";
import { type PhoneVerificationDto, type WebVerificationDto } from "../../../models";
import { VerTypes } from "../../../models/verTypes";
import { type ITimelineItem, Timeline } from "../../basic";

interface Props {
    verification: WebVerificationDto | PhoneVerificationDto;
}

export function VerificationInfo(props: Props) {
    const { verification } = props;
    const mapVerbiage = useMapVerbiage();
    const { user } = useContext(UserContext);

    return (
        <div className="flex flex-col gap-3 p-3">
            <section className="flex flex-1 flex-row flex-wrap gap-4">
                <div className="grow-1">
                    <BasicInfoItem label="ID" value={verification.id} />
                    <BasicInfoItem label="Sale ID" value={verification.saleId} />
                    <BasicInfoItem
                        label={mapVerbiage("Customer", verification)}
                        value={formatName(verification.firstName, verification.lastName, true)}
                    />
                </div>
                {"carrierName" in verification && verification.carrierName ? (
                    <div className="grow-1">
                        <BasicInfoItem label="Line Type" value={verification.carrierType} />
                        <BasicInfoItem label="Carrier" value={verification.carrierName} />
                        <BasicInfoItem label="Caller ID" value={verification.callerIdName} />
                    </div>
                ) : null}
                {user?.clientId == null && verification.auditStatus && verification.auditStatus !== "NotStarted" && (
                    <div id="auditSection" className="flex-1">
                        <span className="label">Remarks</span>
                        <div>{verification.auditReasonRemarks?.split("|").map((s) => <li key={s}>{s}</li>)}</div>
                    </div>
                )}
            </section>
            <section id={`timeline-${verification.id}`}>
                <VerificationTimeline {...props} />
            </section>
        </div>
    );
}

function VerificationTimeline(props: Props) {
    const { verification } = props;
    const mapVerbiage = useMapVerbiage();

    const additionalEventsQuery = useQuery({
        queryKey: [QueryKeys.VERIFICATION_EVENTS, verification.verificationGuid],
        queryFn: () => VerificationsApi.getAdditionalEvents(verification.verificationGuid),
    });

    const isWeb = verification.verType === VerTypes.Web;
    //TODO: possibly group timeline items (e.g. Sale/TPV/Audit)
    const timelineItems = useMemo(() => {
        const items = [
            {
                date: verification.dateOfSale,
                description: mapVerbiage("DateOfSale", verification),
            } as ITimelineItem,
        ];
        if (verification.initiateDateTime)
            items.push({
                date: verification.initiateDateTime,
                description: isWeb ? "Text Sent" : "Call Initiated",
            });
        if (verification.startDateTime)
            items.push({
                date: verification.startDateTime,
                description: isWeb ? `${verification.verModeName} Started` : "Call Answered",
            });
        if (verification.endDateTime) {
            let description = `${verification.verModeName} Completed`;
            if (!verification.success) {
                if (verification.dispositionReason)
                    description = `${verification.verModeName} Failed - ${verification.dispositionName}`;
                else description = verification.dispositionName;
            }
            items.push({
                date: verification.endDateTime,
                description,
                color: verification.success ? "emerald" : "rose",
                iconDef: verification.success ? "success" : "fail",
            });
        }
        if (additionalEventsQuery.data?.length) {
            for (const otherEvent of additionalEventsQuery.data) {
                const item = {
                    date: otherEvent.at,
                    description: otherEvent.eventType,
                    details: otherEvent.remarks,
                } as ITimelineItem;
                if (otherEvent.remarks?.startsWith("Disposition:"))
                    item.iconDef = otherEvent.remarks.endsWith("Good") ? "success" : "fail";
                items.push(item);
            }
        }
        return items;
    }, [verification, mapVerbiage, isWeb, additionalEventsQuery.data]);

    return (
        <div className="md:pt-8">
            <Timeline items={timelineItems} />
        </div>
    );
}

//TODO: rep info

function BasicInfoItem(props: ItemProps) {
    return (
        <div className="grid grid-rows-2" key={props.label}>
            <label className="label">{props.label}</label>
            <span>{props.value}</span>
        </div>
    );
}

interface ItemProps {
    label: string;
    value: string | number | undefined | null;
}
