import { type ReactNode, createContext, useMemo, useState } from "react";

import { TabPage } from "./tabPage";
import { TabsHeader } from "./tabsHeader";

export interface TabsProps {
    containerClass?: string;
    headerClass?: string;
    tabHeaderClass?: string;
    bodyClass?: string;
    tabBodyClass?: string;
    defaultTab?: string | number;
    readOnly?: boolean;
    children: ReactNode[];
}

export function Tabs(props: TabsProps) {
    const { children, containerClass, defaultTab } = props;
    const { headerClass, bodyClass, tabHeaderClass, tabBodyClass } = props;
    const [activeTab, setActiveTab] = useState(defaultTab ?? "");
    const providerProps = useMemo(
        () => ({
            activeTab,
            setActiveTab,
            tabBodyClass,
            tabHeaderClass,
        }),
        [activeTab, setActiveTab, tabBodyClass, tabHeaderClass],
    );
    return (
        <div className={containerClass}>
            {children.length && (
                <TabsProvider {...providerProps} isLink>
                    <TabsHeader className={headerClass}>{children}</TabsHeader>
                </TabsProvider>
            )}
            <TabsProvider {...providerProps}>
                <TabPage className={bodyClass}>{children}</TabPage>
            </TabsProvider>
        </div>
    );
}

interface TabsContextType {
    activeTab?: string | number;
    setActiveTab: (tab: string | number) => void;
    isLink?: boolean;
    tabBodyClass?: string;
    tabHeaderClass?: string;
}

export const TabsContext = createContext<TabsContextType>({
    setActiveTab: () => {
        return;
    },
});

interface TabsProviderProps extends TabsContextType {
    children: ReactNode;
}

export function TabsProvider(props: TabsProviderProps) {
    const { children, ...rest } = props;
    return <TabsContext.Provider value={{ ...rest }}>{children}</TabsContext.Provider>;
}
