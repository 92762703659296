import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import { type ButtonHTMLAttributes, type MouseEvent, forwardRef } from "react";

import { styles } from "../../../helpers";
import { Spinner } from "../spinner";

const buttonVariants = cva(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    {
        variants: {
            variant: {
                default: "bg-indigo-600 text-white hover:bg-indigo-700 active:bg-indigo-800 shadow",
                destructive: "bg-rose-600 text-white hover:bg-rose-700 active:bg-rose-800",
                success: "bg-green-600 text-white hover:bg-green-700 active:bg-green-800",
                warning: "bg-amber-600 text-white hover:bg-amber-700 active:bg-amber-800",
                outline:
                    "border border-indigo-600 dark:border-indigo-300 dark:text-indigo-300 text-indigo-600 bg-transparent hover:bg-indigo-100 active:bg-indigo-200 dark:hover:text-indigo-600 dark:active:text-indigo-600",
                secondary:
                    "dark:bg-white dark:hover:bg-indigo-100 dark:active:bg-indigo-200 bg-indigo-50 text-indigo-600 hover:bg-indigo-100 active:bg-indigo-200 shadow disabled:text-indigo-600",
                ghost: "hover:bg-gray-300 dark:hover:bg-gray-600 active:bg-gray-400 dark:active:bg-gray-500",
                link: "dark:text-white underline-offset-4 underline hover:text-indigo-600 active:text-indigo-700 dark:hover:text-indigo-200 dark:active:text-indigo-300",
            },
            size: {
                default: "h-10 px-4 py-2",
                sm: "h-9 rounded-md px-3",
                lg: "h-11 rounded-md px-8",
                icon: "h-10 w-10",
                barcode: "h-40 p-4",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    },
);

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    isLoading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, ...props }, ref) => {
        const onClick = (e: MouseEvent<HTMLButtonElement>) => {
            if (props.isLoading) return;
            if (props.onClick) return props.onClick(e);
        };

        const Comp = asChild ? Slot : "button";

        return (
            <Comp
                {...props}
                className={styles(buttonVariants({ variant, size, className }))}
                ref={ref}
                onClick={onClick}
            >
                <>
                    {props.isLoading && (
                        <Spinner
                            className="flex items-center justify-center"
                            svgClassName="!w-4 !h-4 !fill-white !text-gray-400"
                        />
                    )}
                    {props.children}
                </>
            </Comp>
        );
    },
);

Button.displayName = "Button";

export { Button, buttonVariants };
