import { useQuery } from "@tanstack/react-query";
import { startOfMonth } from "date-fns";
import { useMemo } from "react";

import { GridFiltersApi } from "../api/gridFiltersApi";
import GridFilters from "../components/gridFilters";
import { FilterGroups } from "../components/gridFilters/types";
import { AgentLeaderboardWidget } from "../components/reporting/historical/agentLeaderboard";
import { AllDispositionsWidget } from "../components/reporting/historical/allDispositions";
import { Districts } from "../components/reporting/historical/districts";
import { GeneratedResponseCountTable } from "../components/reporting/historical/generatedResponseCountTable";
import { HeatMapWidget } from "../components/reporting/historical/heatMap";
import { HeatmapMapWidget } from "../components/reporting/historical/heatmapMap";
import { LocationWidget } from "../components/reporting/historical/locations";
import { NoSaleDispositionsWidget } from "../components/reporting/historical/noSaleDispositions";
import { PercentTPV } from "../components/reporting/historical/percentTPV";
import { RepLocationWidget } from "../components/reporting/historical/repLocations";
import { RepProduction } from "../components/reporting/historical/repProduction";
import { SalesOutcomeWidget } from "../components/reporting/historical/salesOutcome";
import { QueryKeys, usePropReducer } from "../helpers";
import { type ReportingFilter } from "../models";

const startDate = startOfMonth(new Date());
const endDate = new Date();

const initialState: ReportingFilter = {
    campaigns: [],
    channels: [],
    clients: [],
    endDate,
    language: undefined,
    representativeId: undefined,
    startDate,
    startTime: undefined,
    endTime: undefined,
    states: [],
    verMode: undefined,
    verType: undefined,
    location: undefined,
    locationWithinFeet: undefined,
    includesDataEndDate: undefined,
    includesDataStartDate: undefined,
};

export default function Historical() {
    const [filterData, dispatchFilterData] = usePropReducer(initialState);

    const searchOptions = useMemo(
        () => [
            { id: "Rep", label: "Rep" }, //possibly searches on rep name as well (get rep Id from Reps table then search)
        ],
        [],
    );

    const fullFilterData = useMemo(() => ({ ...filterData, searchOptions }), [filterData, searchOptions]);

    const campaignQuery = useQuery({ queryKey: [QueryKeys.CAMPAIGNS], queryFn: () => GridFiltersApi.getCampaigns() });

    return (
        <div>
            <GridFilters<ReportingFilter>
                updateFilter={dispatchFilterData}
                filters={fullFilterData}
                visibleFilters={[
                    FilterGroups.Segment,
                    FilterGroups.Verification,
                    FilterGroups.Date,
                    FilterGroups.Time,
                    FilterGroups.Rep,
                    FilterGroups.Location,
                    FilterGroups.Language,
                    FilterGroups.SearchItems,
                ]}
            />
            <div className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2">
                <SalesOutcomeWidget filterData={fullFilterData} />
                <NoSaleDispositionsWidget filterData={fullFilterData} />
                <LocationWidget filterData={fullFilterData} />
                <RepLocationWidget filterData={fullFilterData} />
                <HeatmapMapWidget filterData={fullFilterData} />
                <HeatMapWidget filterData={fullFilterData} />
                {fullFilterData.campaigns.length === 1 || campaignQuery.data?.length === 1 ? (
                    <>
                        <AllDispositionsWidget filterData={fullFilterData} />
                        <RepProduction filterData={fullFilterData} />
                        <PercentTPV filterData={fullFilterData} />
                        <Districts filterData={fullFilterData} />
                    </>
                ) : null}
                <GeneratedResponseCountTable filterData={fullFilterData} />
                <div className="sm:col-span-2">
                    <AgentLeaderboardWidget filterData={fullFilterData} />
                </div>
            </div>
        </div>
    );
}
