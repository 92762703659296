import { type Row } from "@tanstack/react-table";
import { type FC } from "react";

import { useMapVerbiage, useReplaceVerbiage } from "../../../hooks/verbiageHooks";
import { type RepresentativeDto } from "../../../models";
import { Tab } from "../../basic";
import { DataItemView } from "../../basic/dataItemView";
import { RepBasicInfoView } from "./repBasicInfoView";
import { RepWidgetEventGrid } from "./repWidgetEventGrid";
import { RepWidgetGraphs } from "./repWidgetGraphs";

export enum RepTabs {
    Info,
    Widgets,
    Events,
}

export const RepDetailView: FC<Props> = (props: Props) => {
    const { rep } = props;
    const mapVerbiage = useMapVerbiage();
    const replaceVerbiage = useReplaceVerbiage();

    return (
        <DataItemView defaultTab={RepTabs.Info} readOnly={props.readOnly}>
            <Tab title={mapVerbiage("Rep")} id={RepTabs.Info}>
                <RepBasicInfoView rep={rep} disabled={props.readOnly} />
            </Tab>
            <Tab title="Widgets" id={RepTabs.Widgets} delayLoad>
                <RepWidgetGraphs rep={rep} />
            </Tab>
            <Tab title={replaceVerbiage("Rep", "Rep Events")} id={RepTabs.Events} delayLoad>
                <RepWidgetEventGrid rep={rep} />
            </Tab>
        </DataItemView>
    );
};
interface Props {
    rep: RepresentativeDto;
    readOnly?: boolean;
}
export function RepGridDetail(props: GridProps) {
    return <RepDetailView rep={props.row.original} />;
}
interface GridProps {
    row: Row<RepresentativeDto>;
}
