import { type Row } from "@tanstack/react-table";

import { formatDateRange } from "../../../helpers";
import { type GeneratedReportDto, type GeneratedReportExportDto } from "../../../models/generatedReportDto";
import { Column, DataGridClient } from "../../basic";
import { ExportLink } from "./exportLink";

function formatCreated(item: GeneratedReportExportDto) {
    if (!item) return "";

    return <ExportLink export={item} />;
}

const columns = [
    new Column("Created").withFormat(formatCreated),
    new Column("Data Period").withFormat((d: GeneratedReportExportDto) =>
        formatDateRange(d.dataPeriod.start, d.dataPeriod.end, true, true, false),
    ),
    new Column("Records", "records"),
] as Column<GeneratedReportExportDto>[];

export interface Props {
    row: Row<GeneratedReportDto>;
}

export function GeneratedDetails(props: Props) {
    return <Grid exportData={props.row.original.exports} />;
}

export interface SubGridProps {
    exportData: GeneratedReportExportDto[];
}

export function Grid(props: SubGridProps) {
    return (
        <div className="p-4">
            <div className="rounded dark:border dark:border-white">
                <DataGridClient
                    data={props.exportData ?? []}
                    columns={columns}
                    loading={false}
                    pageSizeOptions={[10]}
                />
            </div>
        </div>
    );
}
