import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { HomeApi } from "../../../api/homeApi";
import { MS_IN, QueryKeys } from "../../../helpers";
import { type VerificationDto } from "../../../models";
import { Button, Dialog } from "../../basic";

interface Props {
    verification: VerificationDto;
    visible?: boolean;
}

export function ReceiptView(props: Props) {
    const { verification } = props;
    const [dialogOpen, setDialogOpen] = useState(true);
    const [receiptUrl, setReceiptUrl] = useState<string>("");
    const baseUrlQuery = useQuery({ queryKey: [QueryKeys.BASE_URL], queryFn: HomeApi.getBaseUrl });
    const tokenQuery = useQuery({
        queryKey: [QueryKeys.TEMP_TOKEN, verification.verCode],
        queryFn: () => HomeApi.getTempToken({ scope: verification.verCode }),
        gcTime: MS_IN.MINUTE,
    });

    useEffect(() => {
        if (!baseUrlQuery.data) return;
        if (!tokenQuery.data) return;

        setReceiptUrl(`${baseUrlQuery.data}/TPV/Receipt?id=${verification.verCode}&apitoken=${tokenQuery.data}`);
    }, [baseUrlQuery.data, verification.verCode, tokenQuery.data]);

    return (
        <>
            <Button onClick={() => setDialogOpen(true)}>Open</Button>
            <Dialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                body={
                    <iframe
                        title="TPV Receipt"
                        src={receiptUrl}
                        width={1000}
                        height={900}
                        className="max-h-full max-w-full"
                    />
                }
                closeIcon
                title={`TPV Receipt - ${verification.id}`}
            />
        </>
    );
}
