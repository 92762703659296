/* eslint-disable */
import * as signalR from "@microsoft/signalr";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { UserContext } from "../../../contexts/userContext";
import { Severity, logger, useTransitionState } from "../../../helpers";
import { KeyedArray, KeyedSetArray, type ReportingFilter, type VerificationDto } from "../../../models";

function useRealtimeData(filterData: ReportingFilter) {
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useTransitionState(true);
    const [loadingSignalR, setLoadingSignalR] = useTransitionState(true);
    const fullList = useRef(new KeyedArray<VerificationDto>([], (v) => v.id));
    const [filteredIds, setFilteredIds] = useState(new Set<number>());
    const [dataArr, setDataArr] = useTransitionState<VerificationDto[]>([]);
    const batches = useRef<VerificationBatch[]>([]);
    const filteredSets = useRef(new VerificationsByFilter());
    const [dirty, setDirty] = useState(0);
    const [connectionOpen, setConnectionOpen] = useTransitionState(false);
    const refresh = useCallback(read, [connectionOpen]);
    const connection = useRef<signalR.HubConnection>();

    useEffect(() => {
        startSignalR().catch((e) => logger(Severity.Error, e));
    }, [user]);

    useEffect(() => {
        updateFilter();
    }, [user, filterData, dirty]);

    useEffect(() => {
        if (filteredIds.size === 0) setDataArr([]);
        else setDataArr(fullList.current.filter((v) => filteredIds.has(v.id)).data);
    }, [user, filteredIds]);

    useEffect(() => {
        if (connectionOpen) read().catch((e) => logger(Severity.Error, e));
    }, [user, connectionOpen]);

    async function startSignalR() {
        connection.current = new signalR.HubConnectionBuilder().withUrl("/verificationHub").build();
        connection.current.on("update", onUpdate);
        await connection.current.start();
        await onConnected();
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        connection.current.onreconnected(onConnected);
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        connection.current.onclose(onDisconnected);
        setLoadingSignalR(false);
    }

    async function onConnected() {
        if (!user) return;
        await connection.current?.invoke("login", user.id, new Date().getTimezoneOffset());
        setConnectionOpen(true);
    }
    function onDisconnected() {
        setConnectionOpen(false);
    }

    async function read() {
        setLoading(true);
        if (!connectionOpen) {
            await connection.current?.start();
            await onConnected();
        }
        const result = (await connection.current?.invoke("read")) as VerificationDto[];
        dataLoaded(result);
        setLoading(false);
    }

    function dataLoaded(data: VerificationDto[]) {
        batches.current.push({
            timestamp: new Date(),
            updatedIds: data.map((v) => v.id),
        });
        for (const v of data) fullList.current.set(v);
        filteredSets.current.loadData(data);
        setDirty(Math.random());
    }

    function flushBatches(date: Date) {
        const oldBatches = batches.current.filter((b) => b.timestamp <= date);
        if (!oldBatches.length) return;
        batches.current.splice(0, oldBatches.length - 1);
    }

    function updateFilter() {
        const filteredIds = new Set<number>();
        //Gather id's that match each filter (additive), then filter down to id's that match all filters (id's that exist in all groups)
        const multiFilters: (keyof ReportingFilter | keyof VerificationsByFilter)[] = [
            "campaigns",
            "channels",
            "clients",
            "states",
        ];
        const singleFilters: (keyof ReportingFilter | keyof VerificationsByFilter)[] = [
            "verType",
            "verMode",
            "language",
        ];
        const matchSets: Set<number>[] = [
            ...multiFilters.map((item) => getMatchingIdsMulti(item)),
            ...singleFilters.map((item) => getMatchingIds(item)),
        ];
        for (const id of matchSets?.at(0) ?? []) {
            if (matchSets.every((s) => s.has(id))) filteredIds.add(id);
        }
        setFilteredIds(filteredIds);

        function getMatchingIdsMulti(type: keyof ReportingFilter | keyof VerificationsByFilter) {
            // @ts-ignore
            const filterInput = (filterData[type] as string[]) ?? [];
            // @ts-ignore
            const filterSet = filteredSets.current[type] ?? new KeyedSetArray([]);

            const matchingIds = new Set<number>();
            if (!filterInput?.length) return filterSet.getFullSet();
            const idLists = filterInput.map((v) => filterSet.getValue(v));

            for (const idList of idLists) {
                for (const id of idList) matchingIds.add(id);
            }

            return matchingIds;
        }

        function getMatchingIds(type: keyof ReportingFilter | keyof VerificationsByFilter) {
            // @ts-ignore
            const filterInput = filterData[type] as string;
            // @ts-ignore
            const filterSet = filteredSets.current[type] ?? new KeyedSetArray([]);

            if (!filterInput) return filterSet.getFullSet();
            return filterSet.getValue(filterInput);
        }
    }

    function onUpdate(e: VerificationDto) {
        dataLoaded([e]);
    }

    return {
        data: dataArr,
        filteredIds,
        refresh,
        loadingSignalR,
        loading,
        batches,
        flushBatches,
        fullList,
    };
}

export default useRealtimeData;

class VerificationsByFilter {
    public campaigns = new KeyedSetArray([]);
    public channels = new KeyedSetArray([]);
    public clients = new KeyedSetArray([]);
    public states = new KeyedSetArray([]);
    public verType = new KeyedSetArray([]);
    public verMode = new KeyedSetArray([]);
    public language = new KeyedSetArray([]);

    constructor(data: VerificationDto[] = []) {
        this.loadData(data);
    }

    loadData(verifications: VerificationDto[]) {
        for (const verification of verifications) {
            this.campaigns.addElement(verification.campaignId, verification.id);
            verification.channelId && this.channels.addElement(verification.channelId, verification.id);
            verification.clientId && this.clients.addElement(verification.clientId, verification.id);
            verification.stateId && this.states.addElement(verification.stateId, verification.id);
            this.verType.addElement(verification.verTypeId, verification.id);
            this.verMode.addElement(verification.verModeId, verification.id);
            this.language.addElement(verification.languageId ?? -1, verification.id);
        }
    }
}

export interface VerificationBatch {
    updatedIds: number[];
    timestamp: Date;
}
