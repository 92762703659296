import { type FC, type ReactNode } from "react";

import { Layout } from "../layout";
import { AuthWrapper } from "./AuthWrapper";

interface AuthProps {
    page: ReactNode;
    allowAnonymous?: boolean;
    isChangingPassword?: boolean;
}

export const Auth: FC<AuthProps> = ({ page, allowAnonymous, isChangingPassword }) => {
    return (
        <Layout>
            <AuthWrapper allowAnonymous={allowAnonymous} isChangingPassword={isChangingPassword}>
                {page}
            </AuthWrapper>
        </Layout>
    );
};
