import { type FC, useMemo } from "react";

import { formatDateTimeOf } from "../../../helpers";
import { type EventDto, type RepresentativeDto } from "../../../models";
import { Column, DataGridClient } from "../../basic";

interface RepWidgetsGridProps {
    rep: RepresentativeDto;
}

export const RepWidgetEventGrid: FC<RepWidgetsGridProps> = ({ rep }) => {
    const columns = useMemo(() => {
        return [
            new Column("At", "at").withFormat(formatDateTimeOf),
            new Column("Event", "eventType"),
            new Column("By", "by"),
            new Column("Remarks", "remarks"),
        ] as Column<EventDto>[];
    }, []);

    return <DataGridClient data={rep.events ?? []} columns={columns} />;
};
