import { Severity, logger } from "../helpers";
import {
    type AgentData,
    type CampaignReportsDto,
    type GeneratedFilter,
    type GridResult,
    type ReportingData,
    type ReportingFilter,
    type ReportingHeatmap,
    type ReportingLocation,
} from "../models";
import { type CampaignGoalProgressDto } from "../models/campaignGoalProgressDto";
import { type GeneratedReportDto } from "../models/generatedReportDto";
import { type GeneratedResponseCountDto } from "../models/generatedResponseCountDto";
import { doGet, doPost } from "./httpHandler";

const controller = "/api/Reporting";

export class ReportingApi {
    public static formatFilter = (filters?: Partial<ReportingFilter>) => {
        try {
            return filters;
        } catch (e) {
            logger(Severity.Error, e);
            return undefined;
        }
    };

    public static getSaleOutcomes = (filters?: Partial<ReportingFilter>) => {
        return doPost<ReportingData[], Partial<ReportingFilter>>(
            controller,
            "GetSaleOutcomes",
            undefined,
            ReportingApi.formatFilter(filters),
        );
    };

    public static getVerificationPositions = (filters?: Partial<ReportingFilter>) => {
        return doPost<ReportingLocation[], Partial<ReportingFilter>>(
            controller,
            "GetVerificationPositions",
            undefined,
            ReportingApi.formatFilter(filters),
        );
    };

    public static getRepPositions = (filters?: Partial<ReportingFilter>) => {
        return doPost<ReportingLocation[], Partial<ReportingFilter>>(
            controller,
            "GetRepPositions",
            undefined,
            ReportingApi.formatFilter(filters),
        );
    };

    public static getVerificationHeatmap = (filters?: Partial<ReportingFilter>) => {
        return doPost<ReportingHeatmap[], Partial<ReportingFilter>>(
            controller,
            "GetVerificationHeatmap",
            undefined,
            ReportingApi.formatFilter(filters),
        );
    };

    public static getNoSaleDispositions = (filters?: Partial<ReportingFilter>) => {
        return doPost<ReportingData[], Partial<ReportingFilter>>(
            controller,
            "GetNoSaleDispositions",
            undefined,
            ReportingApi.formatFilter(filters),
        );
    };

    public static getAgentLeaderboard = (filters?: Partial<ReportingFilter>) => {
        return doPost<AgentData[], Partial<ReportingFilter>>(
            controller,
            "GetAgentLeaderboard",
            undefined,
            ReportingApi.formatFilter(filters),
        );
    };

    public static getCampaignReportData = (filters?: Partial<ReportingFilter>) => {
        return doPost<CampaignReportsDto, Partial<ReportingFilter>>(
            controller,
            "GetCampaignReportData",
            undefined,
            ReportingApi.formatFilter(filters),
        );
    };

    public static getCampaignGoalProgress = (filters?: Partial<ReportingFilter>) => {
        return doPost<CampaignGoalProgressDto[], Partial<ReportingFilter>>(
            controller,
            "GetCampaignGoalProgress",
            undefined,
            ReportingApi.formatFilter(filters),
        );
    };

    public static generateReports = (itemsPerPage = 10, currentPage = 1, filters?: Partial<GeneratedFilter>) => {
        filters = filters || {};
        return doPost<GridResult<GeneratedReportDto>, Partial<ReportingFilter>>(
            controller,
            "GeneratedReports",
            { itemsPerPage, currentPage },
            filters,
        );
    };

    public static reportExport = (id: string) => {
        return doGet<string>(controller, "ReportExport", { id });
    };

    public static getSelfGeneratedResponseCounts = (filters?: Partial<ReportingFilter>) => {
        return doPost<GeneratedResponseCountDto[], Partial<ReportingFilter>>(
            controller,
            "GetSelfGeneratedResponseCounts",
            undefined,
            ReportingApi.formatFilter(filters),
        );
    };
}
