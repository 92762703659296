import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { type FC, useEffect } from "react";

import { HomeApi } from "../../api/homeApi";
import { PingApi } from "../../api/pingApi";
import { QueryKeys, formatDateWithSeparator, styles } from "../../helpers";

export const Footer: FC = () => {
    const versionQuery = useQuery({ queryKey: [QueryKeys.VERSION], queryFn: () => PingApi.version() });
    const titleQuery = useQuery({ queryKey: [QueryKeys.TITLE], queryFn: () => HomeApi.getTitle() });

    useEffect(() => {
        document.title = titleQuery.data ?? "AVL Portal";
    }, [titleQuery.data]);

    return (
        <div
            className={styles(
                "fixed bottom-0 z-50 flex w-full items-center bg-gray-200 px-2 dark:bg-gray-900",
                versionQuery.data ? "justify-between" : "flex-end",
            )}
        >
            {versionQuery.data ? (
                <span className="caption text-[0.8em] dark:text-gray-300">
                    {versionQuery.data?.version} - {formatDateWithSeparator(versionQuery.data?.build, ".")} -{" "}
                    {versionQuery.data?.environment}
                </span>
            ) : null}

            <p className="caption text-[0.8em] dark:text-gray-300">
                © {format(new Date(), "yyyy")} Advanced Verification
            </p>
        </div>
    );
};
