import { AxiosHeaders, type AxiosRequestConfig } from "axios";

import { type UserDto } from "../models";
import { doGet, doPost } from "./httpHandler";

const loginController = "/api/Login";

interface LoginData {
    email: string;
    password: string;
}

export class UserApi {
    public static login = (data: LoginData) => {
        const headers = new AxiosHeaders();
        headers.setContentType("application/json");
        const config = { headers } as AxiosRequestConfig;
        return doPost<UserDto, LoginData>(loginController, "", undefined, data, config);
    };

    public static logout = () => {
        return doPost(loginController, "Logout");
    };

    public static get = () => {
        return doGet<UserDto>(loginController, "");
    };

    public static forgotPassword = (params: { email: string }) => {
        return doPost(loginController, "ForgotPassword", params);
    };

    public static getForgotPassword = (key: string) => {
        return doGet<string>(loginController, `Forgot/${key}`);
    };

    public static changePassword = (params: {
        email: string;
        oldPassword?: string;
        newPassword?: string;
        forgotPassword: boolean;
    }) => {
        return doPost<UserDto, LoginData>(loginController, "ChangePassword", params);
    };
}
