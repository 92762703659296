import { CalendarIcon } from "@heroicons/react/24/outline";
import { type DateRange } from "react-day-picker";

import { formatDateOf, styles } from "../../../helpers";
import { Button } from "../button";
import { Calendar } from "../calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../popover/popover";

interface DateRangePickerProps {
    label?: string;
    id?: string;
    date?: DateRange | undefined;
    setDate?: (date: DateRange | undefined) => void;
    min?: Date;
    max?: Date;
    className?: string;
}

export function DateRangePicker(props: DateRangePickerProps) {
    const { className, id, date, setDate, label = "Date Range" } = props;

    return (
        <div id={id} className={styles("grid gap-2", className)}>
            <span className="label">{label}</span>
            <Popover>
                <PopoverTrigger asChild>
                    <Button id="date" variant={"outline"} className="flex justify-between">
                        {date?.from ? (
                            date.to ? (
                                <>
                                    {formatDateOf(date.from)} - {formatDateOf(date.to)}
                                </>
                            ) : (
                                formatDateOf(date.from)
                            )
                        ) : (
                            <span>Pick a date range</span>
                        )}
                        <CalendarIcon className="mr-2 h-4 w-4" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={date?.from}
                        selected={date}
                        onSelect={setDate}
                        numberOfMonths={2}
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}
