import { useMutation, useQueryClient } from "@tanstack/react-query";

import { RepresentativesApi } from "../../api/representativesApi";
import { QueryKeys } from "../../helpers";
import { useErrorToast, useSuccessToast } from "../basic";

export const useToggleRepresentative = () => {
    const queryClient = useQueryClient();
    const successToast = useSuccessToast();
    const errorToast = useErrorToast();

    const toggleMutation = useMutation({
        mutationKey: [QueryKeys.REPS_TOGGLE_MUTATION],
        mutationFn: RepresentativesApi.toggle,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.REPS] });
            successToast("Success", "Representatives updated successfully");
        },
        onError: () => {
            errorToast("Error", "Representatives update failed");
        },
    });

    return (ids: number[], toggleTo: boolean) => {
        toggleMutation.mutate({ ids, activate: toggleTo });
    };
};
