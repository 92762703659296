import { type FC } from "react";

import { formatDateTimeOf } from "../../helpers";
import { useMapVerbiage } from "../../hooks/verbiageHooks";
import { type ScanResponseDto } from "../../models/scanResponseDto";
import { ImageSkeleton } from "../basic";
import { TextOrLoading } from "../basic/loading/textOrLoading";

interface LastScanProps {
    loading: boolean;
    scan: ScanResponseDto | undefined;
}

const LastScanImage: FC<LastScanProps> = (props) => {
    if (props.loading) return <ImageSkeleton />;

    if (!props.scan?.imageUrl) return <span className="label">No TPV</span>;

    return <img src={props.scan.imageUrl} alt="TPV" className="h-[48rem] w-full rounded" />;
};

export const LastScan: FC<LastScanProps> = (props) => {
    const mapVerbiage = useMapVerbiage();

    if (!props.scan) return null;

    return (
        <>
            <h2 className="headline">Last Scan</h2>
            <div className="card grid grid-cols-1 gap-2 sm:grid-cols-2">
                <LastScanImage {...props} />
                <div>
                    <dl className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                        <dt className="title">TPV ID</dt>
                        <dd className="subtitle">
                            <TextOrLoading text={props.scan?.verificationId} loading={props.loading} />
                        </dd>
                        <dt className="title">{mapVerbiage("Rep")}</dt>
                        <dd className="subtitle">
                            <TextOrLoading text={props.scan?.rep} loading={props.loading} />
                        </dd>
                        <dt className="title">{mapVerbiage("DateOfSale")}</dt>
                        <dd className="subtitle">
                            <TextOrLoading
                                text={formatDateTimeOf(props.scan?.dateOfSale ?? "", false)}
                                loading={props.loading}
                            />
                        </dd>
                        <dt className="title">TPV Completed</dt>
                        <dd className="subtitle">
                            <TextOrLoading
                                text={formatDateTimeOf(props.scan?.completed ?? "", false)}
                                loading={props.loading}
                            />
                        </dd>
                        <dt className="title">TPV Disposition</dt>
                        <dd className="subtitle">
                            <TextOrLoading text={props.scan?.disposition} loading={props.loading} />
                        </dd>
                        <dt className="title">Turned In</dt>
                        <dd className="subtitle">
                            <TextOrLoading
                                text={formatDateTimeOf(props.scan?.turnedIn.at ?? "", false)}
                                loading={props.loading}
                            />
                            <div className="mx-1" />
                            <TextOrLoading text={props.scan?.turnedIn.by} loading={props.loading} />
                        </dd>
                        <dt className="title">Picked Up</dt>
                        <dd className="subtitle">
                            <TextOrLoading
                                text={formatDateTimeOf(props.scan?.pickedUp?.at ?? "", false)}
                                loading={props.loading}
                            />
                            <div className="mx-1" />
                            <TextOrLoading text={props.scan?.pickedUp?.by} loading={props.loading} />
                        </dd>
                    </dl>
                </div>
            </div>
        </>
    );
};
