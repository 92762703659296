import "@googlemaps/js-api-loader";
import { useQuery } from "@tanstack/react-query";
import GoogleMapReact from "google-map-react";
import { type FC, useContext, useEffect, useMemo, useState } from "react";

import { SettingsApi } from "../../../../api/settingsApi";
import { UserContext } from "../../../../contexts/userContext";
import {
    QueryKeys,
    Severity,
    formatShortDateTimeOf,
    intToTime,
    logger,
    splitWordsOnUpperCase,
} from "../../../../helpers";
import { type MapPinType, type ReportingLocation } from "../../../../models";
import { Slider } from "../../slider";
import { Spinner } from "../../spinner";
import { NoData } from "../noData";
import "./index.css";
import { findCenterCoordinate, getSegment, typeToImage } from "./utils";

interface TimeSliderSettings {
    enabled: boolean;
    min: number;
    max: number;
}

interface MapChartProps {
    id: string;
    title: string;
    data: ReportingLocation[];
    type: MapPinType;
    realtime?: boolean;
    timeSlider?: TimeSliderSettings;
}

const marks = {
    0: intToTime(0),
    6: intToTime(6),
    12: intToTime(12),
    18: intToTime(18),
    24: intToTime(24),
};

export const MapChart: FC<MapChartProps> = ({ id, title, data, type, realtime, timeSlider }) => {
    const { user } = useContext(UserContext);
    const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
    const [displayMarkers, setDisplayMarkers] = useState<boolean>(false);
    const [markers, setMarkers] = useState<google.maps.marker.AdvancedMarkerElement[]>([]);
    const [sliderValue, setSliderValue] = useState<number[]>([0, 24]);

    useEffect(() => {
        if (timeSlider?.enabled) {
            setSliderValue([timeSlider.min, timeSlider.max]);
        }
    }, [timeSlider]);

    const filteredData = useMemo(() => {
        return data
            .filter((item) => item.datetime)
            .filter((item) => item.lat && item.lon)
            .filter((item) => item.datetime.getHours() >= (sliderValue?.at(0) ?? 0))
            .filter((item) => item.datetime.getHours() <= (sliderValue?.at(1) ?? 24));
    }, [data, sliderValue]);

    const mapSettings = useQuery({
        queryKey: [QueryKeys.SETTINGS_MAP],
        queryFn: SettingsApi.getMapSettings,
    });

    const center = useMemo(() => {
        return findCenterCoordinate(data.map((item) => [item.lat, item.lon]));
    }, [data]);

    const bounds: google.maps.LatLngBounds | undefined = useMemo(() => {
        if (!displayMarkers) return undefined;
        if (!data) return undefined;
        if (!window.google?.maps) return undefined;

        const filtered = data.filter((item) => item.lat && item.lon);

        const b = new window.google.maps.LatLngBounds();
        filtered.forEach((item) => {
            b.extend(new window.google.maps.LatLng(item.lat, item.lon));
        });
        return b;
    }, [data, displayMarkers]);

    const onGoogleApiLoaded = ({ map }: { map: google.maps.Map }) => {
        setMapRef(map);
        setDisplayMarkers(true);
    };

    const heatmapData = useMemo(() => {
        return {
            positions: filteredData.map((item) => ({ lat: item.lat, lng: item.lon, weight: 100 })),
            options: {},
        };
    }, [filteredData]);

    useEffect(() => {
        if (!mapRef) return;
        if (!bounds) return;

        mapRef.fitBounds(bounds);
    }, [bounds, mapRef]);

    useEffect(() => {
        if (!displayMarkers) return;
        if (!filteredData) return;
        if (type === "heatmap") return;

        if (filteredData.length === markers.length) return;

        async function initMap() {
            const { InfoWindow } = (await google.maps.importLibrary("maps")) as google.maps.MapsLibrary;
            const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;

            const newMarkers: google.maps.marker.AdvancedMarkerElement[] = [];

            function clearMarkers() {
                if (realtime) return;
                markers.forEach((marker) => (marker.map = null));
                setMarkers([]);
            }

            clearMarkers();

            const infoWindow = new InfoWindow();

            filteredData.forEach((item) => {
                const flagImage = document.createElement("img");
                flagImage.src = typeToImage(type, item.disposition);
                flagImage.width = 32;
                flagImage.height = 32;

                const marker = new AdvancedMarkerElement({
                    position: { lat: item.lat, lng: item.lon },
                    map: mapRef,
                    content: flagImage,
                    gmpClickable: true,
                });

                const content = marker.content as HTMLElement;
                content.style.opacity = "0";
                content.classList.add("drop");
                content.addEventListener("animationend", () => {
                    content.classList.remove("drop");
                    content.style.opacity = "1";
                });

                newMarkers.push(marker);

                marker.addListener("click", () => {
                    mapRef?.setMapTypeId("satellite");
                    mapRef?.setCenter({ lat: item.lat, lng: item.lon });
                    mapRef?.setZoom(19);

                    const repInfo = item.repName ? `(${item.repName})` : "";
                    const segment = getSegment(user, item.client, item.channel, item.state);

                    infoWindow.close();
                    infoWindow.setContent(
                        `<div style="color: black">
                            <dl style="display: grid; grid-template-columns: 1fr 1fr; gap: 4px; padding-bottom: 4px">
                                <dt style="font-weight: bold">Rep:</dt>
                                <dd>${item.repId} ${repInfo}</dd>
                                <dt style="font-weight: bold">TPV:</dt>
                                <dd>${item.tpvId} ${segment ? `(${segment})` : ""}</dd>
                                <dt style="font-weight: bold">Date:</dt>
                                <dd>${formatShortDateTimeOf(item.datetime) ?? ""}</dd>
                                <dt style="font-weight: bold">Status:</dt>
                                <dd>${splitWordsOnUpperCase(item.status ?? "")}</dd>
                                <dt style="font-weight: bold">Disposition:</dt>
                                <dd>${item.disposition ?? ""}</dd>
                            </dl>
                            <button id="openStreetView" style="color: blue">Open Street View</button>
                        </div>`,
                    );
                    infoWindow.open(mapRef, marker);

                    infoWindow.addListener("closeclick", () => {
                        mapRef?.setMapTypeId("roadmap");
                        if (bounds) {
                            mapRef?.fitBounds(bounds);
                        } else {
                            mapRef?.setCenter({ lat: item.lat, lng: item.lon });
                            mapRef?.setZoom(3);
                        }
                    });

                    infoWindow.addListener("domready", () => {
                        const openStreetViewButton = document.getElementById("openStreetView");
                        if (!openStreetViewButton) return;
                        openStreetViewButton.addEventListener("click", () => openStreetView(item.lat, item.lon));
                    });

                    function openStreetView(lat: number, lng: number) {
                        if (!mapRef) return;

                        const panorama: google.maps.StreetViewPanorama = mapRef?.getStreetView();

                        panorama.setPosition({ lat, lng });
                        panorama.setVisible(true);

                        const size = new google.maps.Size(96, 96);
                        const image = {
                            url: flagImage.src,
                            size,
                            scaledSize: size,
                        } as google.maps.Icon;

                        // TODO: For some reason adv markers don't work in street view yet
                        const marker = new google.maps.Marker({
                            position: { lat, lng },
                            title: "",
                            icon: image,
                        });

                        marker.setMap(panorama);

                        mapRef.setStreetView(panorama);

                        panorama.addListener("closeclick", () => {
                            marker.setMap(null);
                        });
                    }
                });
            });

            setMarkers(newMarkers);
        }

        initMap().catch((e) => logger(Severity.Error, e));
    }, [filteredData, type, user, bounds, realtime, markers, displayMarkers, mapRef]);

    if (mapSettings.isLoading) return <Spinner />;

    if (data.length === 0) {
        return <NoData title={title} />;
    }

    return (
        <div id={id} className="card p-0">
            <h2 className="title p-4">{title}</h2>
            {timeSlider?.enabled ? (
                <Slider
                    className="w-full px-4 pb-6"
                    marks={marks}
                    min={timeSlider.min}
                    max={timeSlider.max}
                    step={1}
                    defaultValue={[timeSlider.min, timeSlider.max]}
                    value={sliderValue}
                    onChange={setSliderValue}
                    draggableTrack
                />
            ) : null}
            <div className="h-[372px]">
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: mapSettings.data?.apiKey ?? "",
                        libraries: ["visualization", "marker"],
                    }}
                    options={{
                        minZoom: 3,
                        mapTypeId: "roadmap",
                        mapTypeControl: true,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: true,
                        fullscreenControl: true,
                        zoomControl: true,
                        clickableIcons: false,
                        mapId: "4504f8b37365c3d0",
                    }}
                    defaultCenter={center}
                    defaultZoom={3}
                    heatmapLibrary={type === "heatmap"}
                    heatmap={type === "heatmap" ? heatmapData : undefined}
                    onGoogleApiLoaded={onGoogleApiLoaded}
                    yesIWantToUseGoogleMapApiInternals
                    shouldUnregisterMapOnUnmount
                />
            </div>
        </div>
    );
};
