import { type FC, useCallback } from "react";

import { styles } from "../../../helpers";
import { type keyPairAll } from "../../../models";
import { Button } from "../button";

interface ToggleButtonProps {
    id: string;
    label: string;
    values: keyPairAll<string>[];
    value: string | number | boolean | undefined;
    changeHandler?: (value: any, id: any) => void;
    onChange?: (event: any) => void;
    clearable?: boolean;
}

export const ToggleButton: FC<ToggleButtonProps> = ({ id, label, values, value, changeHandler, clearable }) => {
    const handleSelect = (val: string | number | boolean | undefined) => {
        if (clearable && val === value) val = undefined;
        if (changeHandler) {
            changeHandler(val, id);
        }
    };

    return (
        <div className="">
            <span className="font-bold">{label}</span>
            <span className="mt-2 grid grid-cols-2">
                {values.map((item, index) => (
                    <ToggleButtonItem
                        key={item.id?.toString()}
                        onSelect={handleSelect}
                        item={item}
                        isSelected={item.id === value}
                        isFirst={index === 0}
                        isLast={index === values.length - 1}
                    />
                ))}
            </span>
        </div>
    );
};

interface ToggleButtonItemProps {
    onSelect: (val: string | number | boolean | undefined) => void;
    isFirst: boolean;
    isLast: boolean;
    item: keyPairAll<string>;
    isSelected: boolean;
}
function ToggleButtonItem(props: ToggleButtonItemProps) {
    const { onSelect, item, isFirst, isLast, isSelected } = props;
    const handleSelect = useCallback(() => onSelect(item.id), [onSelect, item.id]);
    return (
        <Button
            type="button"
            key={item.id?.toString()}
            variant={isSelected ? "default" : "outline"}
            onClick={handleSelect}
            className={styles(isFirst && "!rounded-l-md", isLast && "!rounded-r-md", "button w-full !rounded-none")}
        >
            {item.value}
        </Button>
    );
}
