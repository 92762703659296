import { type EventDto } from "./eventDto";
import { type FormBatchSummaryDto } from "./formBatchSummaryDto";

export interface ScanResponseDto {
    verificationId?: number;
    dateOfSale?: Date;
    completed?: Date;
    disposition: string;
    rep: string;
    turnedIn: EventDto;
    pickedUp: EventDto;
    imageUrl: string;
    error: string;
    batchSummary: FormBatchSummaryDto;
    valid: boolean;
}

export enum ScanBatchDirection {
    In = 1,
    Out = 2,
}
