import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AuditApi } from "../../api/auditApi";
import { UserContext } from "../../contexts/userContext";
import { Severity, formatDateTimeOf, logger } from "../../helpers";
import { QueryKeys, RouteValues, usePropReducer, useTransitionState } from "../../helpers";
import { useMapVerbiage } from "../../hooks/verbiageHooks";
import { type AuditDispositionDto, type AuditFilter, type IFilter, KeyedArray } from "../../models";
import { type FormAuditBatch } from "../../models/formAuditBatch";
import { Button, Column, Dialog } from "../basic";
import { FilterGroups } from "../gridFilters/types";
import { GridPage } from "../gridPage";
import { CreateAudit } from "./createAudit";

const formatDispositionFn = (id: number) => (row: FormAuditBatch) => formatDisposition(row, id);
function formatDisposition(row: FormAuditBatch, id: number) {
    if (row.dispositionCounts.length === 0) return 0;

    return row.dispositionCounts.find((disposition) => disposition.id === id)?.count ?? 0;
}

export default function AuditGrid() {
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [filterData, dispatchFilterData] = usePropReducer({} as AuditFilter);
    const [itemsPerPage, setItemsPerPage] = useTransitionState(10);
    const [currentPage, setCurrentPage] = useTransitionState(1);
    const [sortFilter, setSortFilter] = useState<IFilter>({});
    const { user } = useContext(UserContext);
    const mapVerbiage = useMapVerbiage();
    const navigate = useNavigate();

    const previousScanQuery = useQuery({
        queryKey: [QueryKeys.PREVIOUS_AUDIT],
        queryFn: AuditApi.getUserPreviousAudit,
    });
    const dispositions = useQuery({ queryKey: [QueryKeys.DISPOSITIONS], queryFn: AuditApi.getAllDispositions });

    async function getData(signal: AbortSignal) {
        try {
            return await AuditApi.getAll(signal, itemsPerPage, currentPage, {
                ...filterData,
                ...sortFilter,
            });
        } catch (ex) {
            logger(Severity.Error, ex);
        }
    }

    const columns = useMemo(() => {
        const defaultColumns = [
            new Column(mapVerbiage("Campaign"), "campaignName"),
            new Column("User", "createdBy"),
            new Column("Started", "createdOn").withFormat(formatDateTimeOf),
            new Column("Completed", "completedOn").withFormat(formatDateTimeOf),
            new Column("Count", "size"),
        ] as Column<FormAuditBatch>[];
        const parentColumns = new KeyedArray<Column<any>>(
            [new Column("Good"), new Column("Mismatch"), new Column("Other")],
            (c) => c.name,
        );
        if (dispositions.data) {
            for (const disposition of dispositions.data) {
                addColumnToParent(disposition);
            }
            return [...defaultColumns, ...parentColumns.filter((c) => c.subColumns.length > 0).data];
        }
        return defaultColumns;

        function addColumnToParent(disposition: AuditDispositionDto) {
            const parent = parentColumns.get(getName());
            const column = getColumn();
            parent?.withSubColumn(column);
            return column;

            function getColumn() {
                let name = disposition.name;
                if (name.includes("Mismatch")) name = name.replace("Mismatch", "").trim();

                return new Column(name)
                    .withSortable(false)
                    .withFormat(formatDispositionFn(disposition.id))
                    .withId(disposition.id);
            }
            function getName() {
                if (disposition.name.includes("Mismatch")) return "Mismatch";
                if (disposition.name.includes("Good")) return "Good";
                return "Other";
            }
        }
    }, [dispositions.data, mapVerbiage]);
    if (!user) return null;

    return (
        <>
            <GridPage<AuditFilter, FormAuditBatch>
                title="Audits"
                actionButton={
                    <div className="flex gap-2">
                        {previousScanQuery.data ? (
                            <Button
                                variant="outline"
                                onClick={() => {
                                    navigate(`/${RouteValues.AUDIT}/${previousScanQuery.data.id}`);
                                }}
                            >
                                <PencilSquareIcon className="mr-1 w-5" /> Previous Audit
                            </Button>
                        ) : null}
                        <Button variant="default" onClick={() => setOpenCreateDialog(true)}>
                            <PlusIcon className="mr-1 w-5" /> New Audit
                        </Button>
                    </div>
                }
                filters={filterData}
                updateFilter={dispatchFilterData}
                visibleFilters={[FilterGroups.Segment, FilterGroups.Campaign, FilterGroups.Date]}
                queryKey={QueryKeys.AUDIT}
                getData={getData}
                columns={columns}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={(itemsPerPage: number) => setItemsPerPage(itemsPerPage)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
            />
            <Dialog
                open={openCreateDialog}
                setOpen={setOpenCreateDialog}
                title="Create Audit"
                body={<CreateAudit setOpenCreateDialog={setOpenCreateDialog} />}
            />
        </>
    );
}
