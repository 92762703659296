import { type FC } from "react";

interface NoDataProps {
    title: string;
    noCard?: boolean;
}

export const NoData: FC<NoDataProps> = ({ title, noCard }) => {
    return (
        <div className={noCard ? "h-full min-h-[432px]" : "card h-full min-h-[432px]"}>
            <h2 className="title">{title}</h2>
            <div className="flex h-full w-full items-center justify-center text-center">
                <span className="label">No data available for chosen timeframe/filters</span>
            </div>
        </div>
    );
};
