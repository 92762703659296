import { type FC, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { TabsHeader } from "../components/basic";
import { TabLink } from "../components/basic/tabs/tabLink";
import { UserContext } from "../contexts/userContext";
import { RouteValues, useRoutePermissions } from "../helpers";
import { UserPermissionFlags } from "../models/permissions";

export const Reports: FC = () => {
    const location = useLocation();

    const neededPermission = location.pathname.includes(RouteValues.GENERATED)
        ? UserPermissionFlags.ReportsManage
        : UserPermissionFlags.ReportsView;
    useRoutePermissions(neededPermission);

    const { hasPermission } = useContext(UserContext);

    return (
        <div>
            <TabsHeader className="justify-center">
                <TabLink
                    key="realtime-tab"
                    to={RouteValues.REALTIME}
                    current={location.pathname.includes(RouteValues.REALTIME)}
                    name="Realtime"
                />
                <TabLink
                    key="historical-tab"
                    to={RouteValues.HISTORICAL}
                    current={location.pathname.includes(RouteValues.HISTORICAL)}
                    name="Historical"
                />
                {hasPermission(UserPermissionFlags.ReportsManage) && (
                    <TabLink
                        key="generated-tab"
                        to={RouteValues.GENERATED}
                        current={location.pathname.includes(RouteValues.GENERATED)}
                        name="Generated"
                    />
                )}
            </TabsHeader>
            <Outlet />
        </div>
    );
};
