import { type FC } from "react";

export const GridSkeleton: FC = () => {
    return (
        <>
            <div className="h-4 w-24 animate-pulse rounded-full bg-gray-300 dark:bg-gray-600" />
            <span className="sr-only">loading</span>
        </>
    );
};
