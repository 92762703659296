import { doGet } from "./httpHandler";

interface MapSettings {
    apiKey: string;
}

const controller = "/api/Settings";

export const SettingsApi = {
    getMapSettings: () => doGet<MapSettings>(controller, "Map"),
};
