import { CampaignDropdown } from "../../dropdowns/campaignDropdown";
import { type CampaignFilterProps } from "../types";

export default function CampaignFilter(props: CampaignFilterProps) {
    const { visible, filters, selectVal } = props;

    if (!visible) return null;

    return (
        <>
            <CampaignDropdown value={filters.campaigns} selectVal={selectVal} isMulti autoHide isClearable />
        </>
    );
}
