import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";

import { formatDateTimeOf } from "../../../helpers";
import { KeyPairArray } from "../../../models";
import type { ITimelineItem, Props } from "./index";

export function Timeline(props: Props) {
    const { items } = props;

    const dayGroups = useMemo(() => {
        const days = new KeyPairArray<ITimelineItem[]>([]);
        let currentDay = new Date().toLocaleDateString();
        for (const item of items) {
            if (!item.date || item.date <= new Date("1/1/1980")) continue;

            currentDay = item.date.toLocaleDateString();
            if (!days.has(currentDay)) days.setValue(currentDay, []);
            days.getValue(currentDay)?.push(item);
        }
        return days.sort((d1, d2) => (new Date(d1.id) > new Date(d2.id) ? 1 : -1));
    }, [items]);
    const lastItemDate = useMemo(() => [...items].pop()?.date, [items]);

    return (
        <>
            <ol className="hidden items-center md:flex">
                {dayGroups.map((dg) =>
                    dg.value.map((i) => (
                        <li className="relative mb-6 sm:mb-0" key={i.description} title={i.details}>
                            <div className="flex items-center">
                                <div className="absolute flex h-10 w-10 items-center justify-center rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700">
                                    {renderIcon(i)}
                                </div>
                                {i.date !== lastItemDate && (
                                    <div className="hidden h-0.5 w-full bg-gray-200 dark:bg-gray-700 sm:flex"></div>
                                )}
                            </div>
                            <div className="mt-6 sm:pe-8">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{i.description}</h3>
                                <time className="caption mb-2">{formatDateTimeOf(i.date)}</time>
                            </div>
                        </li>
                    )),
                )}
            </ol>
            <ol className="relative border-s border-gray-200 dark:border-gray-700 md:hidden">
                {dayGroups.map((dg) =>
                    dg.value.map((i) => (
                        <li className="mb-10 ms-4" key={i.description}>
                            <div className="absolute -start-1.5 mt-1.5 h-3 w-3 rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700">
                                {renderIcon(i)}
                            </div>
                            <time className="caption mb-1">{formatDateTimeOf(i.date)}</time>
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{i.description}</h3>
                            <span>{i.details}</span>
                        </li>
                    )),
                )}
            </ol>
        </>
    );

    function renderIcon(item: ITimelineItem) {
        switch (item.iconDef) {
            case "success":
                return <CheckCircleIcon color="emerald" className="h-full w-full text-emerald-600" />;
            case "fail":
                return <XCircleIcon color="rose" className="h-full w-full text-rose-600" />;
        }
        return item.icon;
    }
}
