import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, UserCircleIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import { type FC, Fragment, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { UserContext } from "../../contexts/userContext";
import { RouteValues, clearPayloadCookie, styles } from "../../helpers";
import { Logo } from "./logo";

interface HeaderProps {
    setSidebarOpen: (value: boolean) => void;
}

export const Header: FC<HeaderProps> = ({ setSidebarOpen }) => {
    const { user, setUser } = useContext(UserContext);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const handleLogout = async () => {
        setUser(undefined);
        clearPayloadCookie();
        navigate("/", { replace: true });
        await queryClient.invalidateQueries();
    };

    const userNavigation = [
        { name: "Profile", href: `/${RouteValues.SETTINGS}` },
        { name: "Sign out", href: "", onClick: () => handleLogout() },
    ];

    return (
        <div className="z-40 flex h-16 shrink-0 items-center gap-x-4 bg-gray-200 px-4 dark:bg-gray-900 dark:text-white sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 lg:hidden" onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-800 lg:hidden" aria-hidden="true" />

            <div className="flex w-full items-center justify-center lg:ml-28">
                <div className="container flex items-center justify-between">
                    <Logo />
                    <div className="flex items-center gap-x-4 lg:gap-x-6">
                        {/* Profile dropdown */}
                        <Menu as="div" className="relative">
                            <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                <span className="sr-only">Open user menu</span>
                                <UserCircleIcon className="w-8" />
                                <span className="hidden lg:flex lg:items-center">
                                    <span className="ml-4 text-sm font-semibold leading-6" aria-hidden="true">
                                        {user?.firstName} {user?.lastName}
                                    </span>
                                    <ChevronDownIcon className="ml-2 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-800 focus:outline-none dark:bg-gray-800">
                                    {userNavigation.map((item) => (
                                        <Menu.Item key={item.name}>
                                            {({ active }) => (
                                                <Link
                                                    to={item?.href}
                                                    onClick={item?.onClick}
                                                    className={styles(
                                                        active && "bg-gray-50 dark:bg-gray-600",
                                                        "block px-3 py-1",
                                                    )}
                                                >
                                                    {item.name}
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    );
};
