import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { AuditApi } from "../../api/auditApi";
import { QueryKeys, sleep } from "../../helpers";
import { type AuditScanResponseDto } from "../../models";
import { useErrorToast } from "../basic";

export const useChangeLineMutation = (
    batchId: number,
    setResults: (results: AuditScanResponseDto | undefined) => void,
) => {
    const queryClient = useQueryClient();
    const errorToast = useErrorToast();

    return useMutation({
        mutationFn: async (code?: string) => {
            if (!batchId) throw new Error("No batch id provided");
            if (!code) {
                await sleep(10);
                return null;
            }

            return AuditApi.scan({ batchId, formLinkGuid: code });
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AUDIT] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AUDIT_BATCH_SUMMARY] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AUDIT_QUESTIONS] });
            setResults(data ?? undefined);
        },
        onError: (error: AxiosError) => {
            errorToast("Error", error.message ?? "Something went wrong getting that signature line. Please try again.");
        },
    });
};

export const useDispositionMutation = (
    barcode: string,
    batchId: number,
    setResults: (results: AuditScanResponseDto | undefined) => void,
    setInput: (input: string) => void,
    setBarcode: (barcode: string) => void,
) => {
    const queryClient = useQueryClient();
    const errorToast = useErrorToast();

    return useMutation({
        mutationFn: (data: { dispositionId: number; input: string }) => {
            if (!batchId) throw new Error("No batch id");

            return AuditApi.disposition({ batchId, dispositionId: data.dispositionId, formLinkGuid: barcode });
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AUDIT] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.PREVIOUS_AUDIT] });

            setResults(undefined);
            setInput("");
            setBarcode("");
        },
        onError: () => {
            setInput("");
            errorToast("Verify Failed", "Please try that last scan again.");
        },
    });
};

export const useMultiDispositionMutation = (
    results: AuditScanResponseDto | undefined,
    batchId: number,
    setResults: (results: AuditScanResponseDto | undefined) => void,
    setInput: (input: string) => void,
    setBarcode: (barcode: string) => void,
) => {
    const queryClient = useQueryClient();
    const errorToast = useErrorToast();
    const changeLineMutation = useChangeLineMutation(batchId, setResults);

    return useMutation({
        mutationFn: (data: { dispositionId: number; input: string }) => {
            if (!batchId) throw new Error("No batch id");
            if (!results?.scanLine?.lineGuid) throw new Error("No line guid");

            return AuditApi.disposition({
                batchId,
                dispositionId: data.dispositionId,
                formLinkGuid: results?.scanLine?.lineGuid,
            });
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.AUDIT] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.PREVIOUS_AUDIT] });

            if (!results?.scanLine?.nextLineGuid) {
                setResults(undefined);
                setInput("");
                setBarcode("");
            } else {
                await changeLineMutation.mutateAsync(results?.scanLine?.nextLineGuid);
            }
        },
        onError: () => {
            errorToast("Verify Failed", "Please try that last scan again.");
        },
    });
};

export const useHandleDisposition = (
    results: AuditScanResponseDto | undefined,
    barcode: string,
    batchId: number,
    setResults: (results: AuditScanResponseDto | undefined) => void,
    setInput: (input: string) => void,
    setBarcode: (barcode: string) => void,
    multi?: boolean,
) => {
    const dispositionMutation = useDispositionMutation(barcode, batchId, setResults, setInput, setBarcode);
    const multiDispositionMutation = useMultiDispositionMutation(results, batchId, setResults, setInput, setBarcode);

    return (dispositionId: number | undefined, barcode: string) => {
        if (!dispositionId) throw new Error("No disposition id");

        if (multi) {
            multiDispositionMutation.mutate({ dispositionId, input: barcode });
        } else {
            dispositionMutation.mutate({ dispositionId, input: barcode });
        }
    };
};
