import { type FC } from "react";

import { NavLink } from "../basic/link/navLink";
import { type Route } from "./types";

interface SidebarProps {
    routes: Route[];
}

export const Sidebar: FC<SidebarProps> = ({ routes }) => {
    return (
        <div className="hidden overflow-x-hidden bg-gray-200 dark:bg-gray-900 lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-28 lg:overflow-y-auto lg:pb-4">
            <div className="flex h-16 shrink-0 items-center justify-center">
                <img className="h-8 w-auto dark:hidden" src="/images/logo-icon.png" alt="AVL" />
                <img className="hidden h-8 w-auto dark:block" src="/images/logo-white-icon.png" alt="AVL" />
            </div>
            <nav>
                <ul className="flex flex-col items-center space-y-1">
                    {routes.map((route) => (
                        <SidebarItem route={route} key={route.name} />
                    ))}
                </ul>
            </nav>
        </div>
    );
};

const SidebarItem: FC<{ route: Route }> = ({ route }) => {
    return (
        <li className="w-full px-1">
            <NavLink to={route.href}>
                {route.icon}
                <span className="text-center">{route.name}</span>
            </NavLink>
        </li>
    );
};
