import { useQuery } from "@tanstack/react-query";
import { type FC, useEffect, useMemo, useState } from "react";

import { AuditApi } from "../../api/auditApi";
import { QueryKeys, guid } from "../../helpers";
import { type AuditScanResponseDto } from "../../models";
import { type AuditQuestionAnswerDto } from "../../models/auditQuestionAnswerDto";
import { BarcodeGenerator } from "../barcode/barcodeGenerator";
import { BarcodeScanner } from "../barcode/barcodeScanner";
import { Button } from "../basic";
import { SafeHtml } from "../safeHtml/safeHtml";
import { useChangeLineMutation, useHandleDisposition } from "./auditHooks";
import { missing } from "./missingTpv";

interface AuditQuestionnaireProps {
    results: AuditScanResponseDto;
    batchId: number;
    barcode: string;
    setResults: (results: AuditScanResponseDto | undefined) => void;
    setBarcode: (barcode: string) => void;
    multi?: boolean;
}

export const AuditQuestionnaire: FC<AuditQuestionnaireProps> = ({
    results,
    barcode,
    batchId,
    setResults,
    setBarcode,
    multi,
}) => {
    const [input, setInput] = useState<string>("");
    const [answers, setAnswers] = useState<AuditQuestionAnswerDto[]>([]);
    const handleDisposition = useHandleDisposition(results, barcode, batchId, setResults, setInput, setBarcode, multi);
    const changeLineMutation = useChangeLineMutation(batchId, setResults);

    const questionQuery = useQuery({
        queryKey: [QueryKeys.AUDIT_QUESTIONS, barcode, results?.scanLine?.lineGuid, answers],
        queryFn: () => {
            if (!barcode) return null;

            if (multi) {
                return AuditApi.nextAuditQuestion(results.scanLine?.lineGuid ?? "", answers);
            }

            return AuditApi.nextAuditQuestion(barcode, answers);
        },
    });

    const possibleAnswers = useMemo(() => {
        const map = new Map<string, string>();
        const keys: string[] = [];

        questionQuery.data?.nextQuestion?.answerOptions?.forEach((option) => {
            const id = "x" + guid().substring(0, 8);

            map.set(id, option);
            keys.push(id);
        });

        return {
            keys,
            map,
        };
    }, [questionQuery.data?.nextQuestion?.answerOptions]);

    const handleAnswer = (value?: string) => {
        if (!value) return;

        setAnswers([
            ...answers,
            {
                questionId: questionQuery.data?.nextQuestion.questionId ?? 0,
                value: value,
                text: questionQuery.data?.nextQuestion.text ?? "",
                timestamp: new Date(),
            },
        ]);
    };

    const handleAnswerDisposition = (input: number, barcode: string) => {
        handleDisposition(input, barcode);

        if (multi) {
            changeLineMutation.mutate(results?.scanLine?.nextLineGuid);
        }

        setAnswers([]);
    };

    const handleSkipLine = () => {
        changeLineMutation.mutate(results?.scanLine?.nextLineGuid);
    };

    useEffect(() => {
        // buttons are the first 8 chars of a guid, so make sure it's shorter than the barcodes that are guids
        if (input.includes("N")) {
            handleSkipLine();
        } else if (input.length > 0 && input.length < 16 && !isNaN(parseInt(input))) {
            handleAnswerDisposition(parseInt(input), barcode);
        } else if (input.length > 0 && input.length < 16) {
            handleAnswer(possibleAnswers.map.get(input));
        }

        if (input.length > 0) setInput("");

        // disable because we want to run this on every input change but not on the other dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    if (!multi && missing(results)) return null;

    return (
        <div className="my-4">
            <div className="py-4">
                <BarcodeScanner value={input} setValue={setInput} />
                <h4 className="subtitle text-center">
                    <SafeHtml html={questionQuery.data?.nextQuestion?.text} />
                </h4>
                <div className="mt-4 flex justify-center gap-8">
                    {possibleAnswers.keys.map((key) => (
                        <Button
                            size="barcode"
                            key={possibleAnswers.map.get(key)}
                            onClick={() => handleAnswer(possibleAnswers.map.get(key))}
                            className="!bg-indigo-200 hover:!bg-indigo-400 active:!bg-indigo-200"
                        >
                            <div className="flex flex-col items-center justify-center">
                                <BarcodeGenerator value={key} />
                                <span className="text-2xl normal-case text-black">{possibleAnswers.map.get(key)}</span>
                            </div>
                        </Button>
                    ))}
                </div>
                <div className="mt-4 flex flex-wrap justify-center gap-8">
                    {questionQuery.data?.possibleDispositions?.map((disposition) => (
                        <Button
                            size="barcode"
                            key={disposition.id}
                            style={{ backgroundColor: disposition.hexColor }}
                            onClick={() => handleAnswerDisposition(disposition.id, input)}
                        >
                            <div className="flex flex-col items-center justify-center">
                                <BarcodeGenerator value={"000000" + disposition.id.toString()} />
                                <span className="text-2xl normal-case text-black">{disposition.name}</span>
                            </div>
                        </Button>
                    ))}
                    {missing(results) ? (
                        <Button onClick={handleSkipLine} size="barcode">
                            <div className="flex flex-col items-center justify-center">
                                <BarcodeGenerator value={"000000N"} />
                                <span className="text-2xl normal-case text-black">Skip Line</span>
                            </div>
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
