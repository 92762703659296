import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { GridFiltersApi } from "../../api/gridFiltersApi";
import { QueryKeys } from "../../helpers";
import { type DropdownProps, FilterDropdown } from "./filterDropDown";

export const VerModesDropdown: FC<DropdownProps> = (props) => {
    const query = useQuery({ queryKey: [QueryKeys.VER_MODES_FILTER], queryFn: () => GridFiltersApi.getVerModes() });

    return <FilterDropdown id="verMode" label="Mode" {...props} query={query} />;
};
