import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import { ReportingApi } from "../../../api/reportingApi";
import { QueryKeys } from "../../../helpers";
import { useReplaceVerbiage } from "../../../hooks/verbiageHooks";
import { type ReportingFilter } from "../../../models";
import { ChartSkeleton, ComboChart } from "../../basic";

export interface Props {
    filterData: ReportingFilter;
}

export function RepProduction(props: Props) {
    const { filterData } = props;
    const [axis, setAxis] = useState<string[]>([]);
    const [column, setColumn] = useState<{ name: string; data: number[] }[] | undefined>(undefined);
    const [spline, setSpline] = useState<{ name: string; data: number[] } | undefined>(undefined);
    const replaceVerbiage = useReplaceVerbiage();

    const reportQuery = useQuery({
        queryKey: [QueryKeys.CAMPAIGN_REPORTS, filterData],
        queryFn: () => {
            return ReportingApi.getCampaignReportData(filterData);
        },
    });

    useEffect(() => {
        if (!reportQuery.data?.successData) return;

        const map = new Map<string, number[]>();
        const countColumnName = replaceVerbiage("Rep", "Rep Count");
        map.set(countColumnName, Array(reportQuery.data.successData.length).fill(0) as number[]);
        map.set("Completed", Array(reportQuery.data.successData.length).fill(0) as number[]);

        reportQuery.data.successData.forEach((item, index) => {
            const count = map.get(countColumnName);
            const completed = map.get("Completed");

            if (count) count[index]! += item.repCount;
            if (completed) completed[index]! += item.hasVoterIdCount;
        });

        setAxis(reportQuery.data.successData.map((item) => format(item.date, "yyyy-MM-dd")));
        setColumn([{ name: countColumnName, data: map.get(countColumnName) ?? [] }]);
        setSpline({ name: "Completed", data: map.get("Completed") ?? [] });
    }, [reportQuery.data?.successData, replaceVerbiage]);

    if (reportQuery.isLoading) return <ChartSkeleton />;

    if (!reportQuery.data) return null;
    if (!reportQuery.data.successData || reportQuery.data.successData.length === 0) return null;

    return (
        <ComboChart
            id="rep-production-combo"
            title={replaceVerbiage("Rep", "Rep Production")}
            axis={axis}
            columns={column ?? []}
            spline={spline}
            areas={[]}
        />
    );
}
