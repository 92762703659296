import { DispositionsDropdown } from "../../dropdowns/dispositionsDropdown";
import { type DispositionFilterProps } from "../types";

export default function DispositionFilter(props: DispositionFilterProps) {
    const { visible, filters, selectVal } = props;

    if (!visible) return null;

    return (
        <>
            <DispositionsDropdown
                filterData={filters}
                value={filters.dispositions}
                selectVal={selectVal}
                autoHide
                isClearable
                isMulti
            />
        </>
    );
}
