export enum SelectableUserPermissionFlags {
    BulkExport = 1 << 0,
    UserManage = 1 << 1,
    ReportsView = 1 << 2,
    ReportsManage = 1 << 3,
    RepresentativesCreate = 1 << 4,
    RepresentativesManage = 1 << 5,
    FormsPrint = 1 << 6,
    FormsScan = 1 << 7,
    FormsAudit = 1 << 8,
    FormsFastAudit = 1 << 9,
    CampaignsManage = 1 << 10,
}

export enum UserPermissionFlags {
    None = 0,
    BulkExport = 1 << 0,
    UserManage = 1 << 1,
    ReportsView = 1 << 2,
    ReportsManage = 1 << 3,
    RepresentativesCreate = 1 << 4,
    RepresentativesManage = 1 << 5,
    FormsPrint = 1 << 6,
    FormsScan = 1 << 7,
    FormsAudit = 1 << 8,
    FormsFastAudit = 1 << 9,
    CampaignsManage = 1 << 10,
    All = ~(~0 << 31),
}
