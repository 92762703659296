import { type FC } from "react";
import { type LinkProps as RouterLinkProps, NavLink as RouterNavLink } from "react-router-dom";

import { styles } from "../../../helpers";

type LinkProps = RouterLinkProps;

export const NavLink: FC<LinkProps> = (props) => {
    const { className, ...rest } = props;
    return (
        <RouterNavLink
            {...rest}
            className={({ isActive }) =>
                styles(
                    className,
                    isActive ? "bg-gray-100 text-indigo-600 dark:bg-gray-700 dark:text-indigo-300" : "",
                    "label flex flex-col items-center gap-x-3 rounded-md p-4",
                    "hover:bg-gray-300 active:bg-gray-400 dark:hover:bg-gray-600 dark:active:bg-gray-500",
                )
            }
        />
    );
};
