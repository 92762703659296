import {
    ArrowDownOnSquareStackIcon,
    ClipboardIcon,
    InformationCircleIcon,
    MapIcon,
    MicrophoneIcon,
    PaperClipIcon,
    UserIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQuery } from "@tanstack/react-query";
import { type Row } from "@tanstack/react-table";

import { VerificationsApi } from "../../../api/verificationsApi";
import { QueryKeys } from "../../../helpers";
import { useMapVerbiage } from "../../../hooks/verbiageHooks";
import { type PhoneVerificationDto, type VerificationDto, type WebVerificationDto } from "../../../models";
import { VerTypes } from "../../../models/verTypes";
import { Spinner, Tab, TabTitle, useErrorToast, useSuccessToast } from "../../basic";
import { DataItemView } from "../../basic/dataItemView";
import { SingleVerificationFiles } from "./files";
import { SingleVerificationMap } from "./map";
import { ReceiptView } from "./receipt";
import { RecordingView } from "./recording";
import { RepData } from "./repData";
import { VerificationInfo } from "./verificationInfo";

export enum VerificationTabs {
    Info,
    Receipt,
    Recording,
    Files,
    Map,
    Rep,
}

export function VerificationDetailView(props: Props) {
    const { verification } = props;
    const mapVerbiage = useMapVerbiage();
    const successToast = useSuccessToast();
    const errorToast = useErrorToast();

    const filesQuery = useQuery({
        queryKey: [QueryKeys.VERIFICATION_FILES, verification.verificationGuid],
        queryFn: () => VerificationsApi.getFiles(verification.verificationGuid),
    });

    const downloadFilesMutation = useMutation({
        mutationFn: (id: number) => VerificationsApi.downloadFilesZip(verification.verificationGuid, id),
        onSuccess: () => {
            successToast("File Downloaded", "Your file has been downloaded. Look in your downloads folder.");
        },
        onError: () => {
            errorToast("File Download Failed", "Something went wrong with your file download. Please try again.");
        },
    });

    const hasMap = !!verification.position?.lat || !!verification.enrollmentPosition?.lat;
    return (
        <DataItemView defaultTab={VerificationTabs.Info}>
            <Tab title={getTitle(VerificationTabs.Info)} id={VerificationTabs.Info}>
                <VerificationInfo verification={verification} />
            </Tab>

            {verification.hasReceipt && (
                <Tab title={getTitle(VerificationTabs.Receipt)} id={VerificationTabs.Receipt} alwaysReload>
                    <ReceiptView verification={verification} />
                </Tab>
            )}
            {verification.hasRecording && (
                <Tab title={getTitle(VerificationTabs.Recording)} id={VerificationTabs.Recording} delayLoad>
                    <RecordingView verification={verification} />
                </Tab>
            )}
            {filesQuery.data?.length ? (
                <Tab title={getTitle(VerificationTabs.Files)} id={VerificationTabs.Files} delayLoad>
                    <SingleVerificationFiles verification={verification} />
                </Tab>
            ) : null}
            {hasMap && (
                <Tab title={getTitle(VerificationTabs.Map)} id={VerificationTabs.Map} delayLoad>
                    <SingleVerificationMap verification={verification} />
                </Tab>
            )}
            {verification.isSystemRep && (
                <Tab title={getTitle(VerificationTabs.Rep)} id={VerificationTabs.Rep} delayLoad>
                    <RepData verification={verification} />
                </Tab>
            )}
        </DataItemView>
    );

    function getTitle(tab: VerificationTabs) {
        switch (tab) {
            case VerificationTabs.Info:
                return <TabTitle text={verification.verModeName} icon={InformationCircleIcon} />;
            case VerificationTabs.Receipt:
                return <TabTitle text={"Receipt"} icon={ClipboardIcon} />;
            case VerificationTabs.Recording:
                return <TabTitle text={"Recording"} icon={MicrophoneIcon} />;
            case VerificationTabs.Files:
                return (
                    <TabTitle
                        text={"Files"}
                        icon={PaperClipIcon}
                        rightIcon={ArrowDownOnSquareStackIcon}
                        onClickRight={() => downloadFilesMutation.mutate(verification.id)}
                    />
                );
            case VerificationTabs.Map:
                return <TabTitle text="Map" icon={MapIcon} />;
            case VerificationTabs.Rep:
                return <TabTitle text={mapVerbiage("Rep")} icon={UserIcon} />;
        }
    }
}

interface Props {
    verification: WebVerificationDto | PhoneVerificationDto;
    readOnly?: boolean;
}

export function VerificationGridDetail(props: GridProps) {
    const expanded = props.row.getIsExpanded();
    const verification = props.row.original;

    const webVerificationQuery = useQuery({
        queryKey: [QueryKeys.VERIFICATIONS, verification.verificationGuid, verification.verType],
        queryFn: () => VerificationsApi.getWebVerification(verification.verificationGuid),
        enabled: verification.verType === VerTypes.Web && expanded,
    });

    const phoneVerificationQuery = useQuery({
        queryKey: [QueryKeys.VERIFICATIONS, verification.verificationGuid, verification.verType],
        queryFn: () => VerificationsApi.getPhoneVerification(verification.verificationGuid),
        enabled: verification.verType === VerTypes.Phone && expanded,
    });

    if (webVerificationQuery.isLoading || phoneVerificationQuery.isLoading) {
        return <Spinner />;
    }

    if (webVerificationQuery.data) {
        return <VerificationDetailView verification={webVerificationQuery.data} />;
    }

    if (phoneVerificationQuery.data) {
        return <VerificationDetailView verification={phoneVerificationQuery.data} />;
    }

    return null;
}
interface GridProps {
    row: Row<VerificationDto>;
}
