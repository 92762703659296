import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { type FC, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FormApi } from "../api/formApi";
import { ScanApi } from "../api/scanApi";
import { BarcodeScanner } from "../components/barcode/barcodeScanner";
import { Button, useErrorToast } from "../components/basic";
import { Counts } from "../components/checkIn/counts";
import { LastScan } from "../components/checkIn/lastScan";
import { CheckInContext } from "../contexts/checkInContext";
import { QueryKeys } from "../helpers";
import { type ScanBatchSummaryDto } from "../models/scanBatchSummaryDto";
import { type ScanResponseDto } from "../models/scanResponseDto";

export const ScanPage: FC = () => {
    const [input, setInput] = useState<string>("");
    const [results, setResults] = useState<ScanResponseDto | undefined>(undefined);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const errorToast = useErrorToast();
    const batchId = useMemo(() => parseInt(id ?? "0"), [id]);

    const campaignQuery = useQuery({
        queryKey: [QueryKeys.CAMPAIGN, batchId],
        queryFn: () => FormApi.getCampaignByBatchId(batchId),
    });

    const { batchSummary, updateBatchSummary, setBatchId } = useContext(CheckInContext);

    useEffect(() => {
        if (batchId) setBatchId(batchId);
    }, [batchId, setBatchId]);

    const checkInMutation = useMutation({
        mutationKey: [input],
        mutationFn: (input: string) => {
            if (!batchId) throw new Error("No batch id");
            const barcode = input.substring(input.length - 36);

            return ScanApi.scan({ batchId: batchId, formLinkGuid: barcode });
        },
        onSuccess: async (data) => {
            setInput("");
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.CHECK_IN] });
            updateBatchSummary(data?.batchSummary);
            setResults(data);
        },
        onError: (error: AxiosError) => {
            let errMessage = "Please try that last scan again.";
            if (error.message) errMessage = error.message;
            setInput("");
            errorToast("Scan Failed", errMessage);
        },
    });

    const completeBatchMutation = useMutation({
        mutationFn: () => {
            if (!batchId) throw new Error("No batch id");
            return ScanApi.completeBatch({ batchId: batchId });
        },
        onSuccess: async (result: ScanBatchSummaryDto) => {
            setBatchId(result?.id);
            updateBatchSummary(result);
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.CHECK_IN] });
            navigate(-1);
        },
        onError: () => {
            errorToast("Finish Pickup Failed", "Please try again in a little bit");
        },
    });

    useEffect(() => {
        // Barcode is a guid, so probably just make sure it's long enough
        if (input.length > 6 && !checkInMutation.isPending) {
            checkInMutation.mutate(input);
        }
    }, [input, checkInMutation]);

    return (
        <div className="grid grid-cols-1 gap-2">
            <BarcodeScanner value={input} setValue={setInput} />
            <div className="mb-4">
                <h2 className="headline">
                    {campaignQuery.data?.type === "Petition" ? "Petition" : "VR"} Campaign:{" "}
                    <span className="italic">{campaignQuery.data?.name}</span>
                </h2>
                <h3 className="title">Check {batchSummary?.direction}</h3>
                <p>
                    Scan the QR code on each {campaignQuery.data?.type.toLowerCase()}. Results will be displayed below.
                    Click finish check in when you are done turning in your petitions.
                </p>
            </div>
            <LastScan scan={results} loading={checkInMutation.isPending} />
            <Counts batchId={batchId} />
            <div className="flex justify-end">
                <Button variant="default" onClick={() => completeBatchMutation.mutate()}>
                    Finish Check {batchSummary?.direction}
                </Button>
            </div>
        </div>
    );
};
