import {
    type IActiveFilter,
    type IAddressFilter,
    type ICampaignFilter,
    type IDateFilter,
    type IDispositionFilter,
    type ILanguageFilter,
    type IRepFilter,
    type ISearchFilter,
    type ISearchItemFilter,
    type ISegmentFilter,
    type ITimeFilter,
    type IVerificationFilter,
} from "../../models";
import { type SelectOption } from "../basic";

export enum FilterGroups {
    Segment,
    Verification,
    Search,
    SearchItems,
    Date,
    Campaign,
    Active,
    Rep,
    Time,
    Location,
    Language,
    DataPeriod,
    Disposition,
}

export default interface Props<T> {
    updateFilter: (
        ...filters: {
            name: string;
            value: undefined | any | any[];
        }[]
    ) => void;
    filters: T;
    visibleFilters: FilterGroups[];
}

export interface SegmentFilterProps extends BaseFilterProps<ISegmentFilter> {
    client?: number;
    channel?: number;
    state?: number;
    language?: number;
}

export type SearchFilterProps = BaseFilterProps<ISearchFilter>;
export type SearchItemFilterProps = BaseFilterProps<ISearchItemFilter>;
export type VerificationFilterProps = BaseFilterProps<IVerificationFilter>;
export type DateFilterProps = BaseFilterProps<IDateFilter>;
export type TimeFilterProps = BaseFilterProps<ITimeFilter>;
export type CampaignFilterProps = BaseFilterProps<ICampaignFilter>;
export type LanguageFilterProps = BaseFilterProps<ILanguageFilter>;
export type ActiveFilterProps = BaseFilterProps<IActiveFilter>;
export type RepFilterProps = BaseFilterProps<IRepFilter>;
export type AddressFilterProps = BaseFilterProps<IAddressFilter>;
export type DispositionFilterProps = BaseFilterProps<IDispositionFilter>;

interface BaseFilterProps<T> {
    className?: string;
    filters: Partial<T>;
    visible: boolean;
    selectVal: (value: SelectOption, id: string) => void;
    changeHandler: (value: any, id: string) => void;
}
