import { GridSkeleton } from "../loading";
import { type CustomTable } from "./dataGrid";

interface DataGridTableBodyProps<T> {
    table: CustomTable<T>;
}

export function Loading<T>({ table }: DataGridTableBodyProps<T>) {
    return (
        <tbody className="divide-y divide-gray-200 bg-white dark:bg-gray-700">
            {Array.from({ length: 10 }, (_, i) => i).map((i) => {
                return (
                    <tr key={i}>
                        {table.getHeaderGroups()[0]?.headers.map((header) => (
                            <td
                                key={header.id + (header.column.columnDef.header as string)}
                                className="whitespace-nowrap py-[18.25px] pl-4 pr-3 text-left text-sm font-medium text-gray-900 dark:text-white sm:pl-6"
                            >
                                <GridSkeleton />
                            </td>
                        ))}
                    </tr>
                );
            })}
        </tbody>
    );
}
