import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { GridFiltersApi } from "../../api/gridFiltersApi";
import { QueryKeys } from "../../helpers";
import { type DropdownProps, FilterDropdown } from "./filterDropDown";

interface Props extends DropdownProps {
    campaignId: number;
}

export const FormTemplatesDropdown: FC<Props> = (props) => {
    const query = useQuery({
        queryKey: [QueryKeys.FORM_TEMPLATES, props.campaignId],
        queryFn: () => GridFiltersApi.getFormTemplates(props.campaignId),
        enabled: !!props.campaignId,
    });

    return <FilterDropdown id="formTemplateId" label="Form Template" {...props} query={query} />;
};
