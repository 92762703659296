import { useQuery } from "@tanstack/react-query";

import { ReportingApi } from "../../../api/reportingApi";
import { QueryKeys } from "../../../helpers";
import { type ReportingFilter } from "../../../models";
import { ChartSkeleton } from "../../basic";
import { BarChart } from "../../basic";

export interface Props {
    filterData: ReportingFilter;
}

export function NoSaleDispositionsWidget(props: Props) {
    const { filterData } = props;

    const agentLeaderboardQuery = useQuery({
        queryKey: [QueryKeys.NO_SALE_DISPOSITIONS, filterData],
        queryFn: async () => {
            const outcomes = await ReportingApi.getNoSaleDispositions(filterData);
            return outcomes.map((outcome) => ({
                name: outcome.name,
                data: [{ name: outcome.name, y: outcome.count }],
            }));
        },
    });

    if (agentLeaderboardQuery.isLoading) return <ChartSkeleton />;

    return <BarChart id="no-sales-bar" title="TPV Failure Dispositions" data={agentLeaderboardQuery.data ?? []} />;
}
