import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { GridFiltersApi } from "../../api/gridFiltersApi";
import { QueryKeys } from "../../helpers";
import { type DropdownProps, FilterDropdown } from "./filterDropDown";

export const ChannelsDropdown: FC<DropdownProps> = (props) => {
    const query = useQuery({ queryKey: [QueryKeys.CHANNELS_FILTER], queryFn: () => GridFiltersApi.getChannels() });

    return <FilterDropdown id="channels" label="Channel" {...props} query={query} />;
};
