import { type MouseEvent } from "react";

/** Fires if specific key is pressed, along with specified combo button */
export const comboKeyPress =
    (ctrl: boolean, alt = false, shift = false) =>
    (keyCode: number, key?: string) =>
    (fn: (e?: React.KeyboardEvent | KeyboardEvent | MouseEvent) => void) =>
    (event: React.KeyboardEvent | KeyboardEvent) => {
        const altValid = alt === event.altKey;
        const ctrlValid = ctrl === event.ctrlKey;
        const shiftValid = shift === event.shiftKey;
        if (altValid && ctrlValid && shiftValid) {
            specificKeyPress(keyCode, key)(fn)(event);
        }
    };

/** Fires if  specific key is pressed with no combo key */
export const soloKeyPress = comboKeyPress(false, false, false);
/** Fires if specific key is pressed with combo key */
export function specificKeyPress(keyCode: number, key?: string) {
    return function (fn: (e?: React.KeyboardEvent | KeyboardEvent | MouseEvent) => void) {
        return function (event: React.KeyboardEvent | KeyboardEvent) {
            let keyPressed = key && key.toLowerCase() === event.key.toLowerCase();
            keyPressed = keyPressed || event.keyCode === keyCode;
            if (keyPressed) fn(event);
        };
    };
}

/** Allows optional definition for whether kicks off is ctrl key is pressed or whatnot */
export const optionalSoloKeyPress = (solo?: boolean) => (solo ? soloKeyPress : specificKeyPress);

/** onEnter fn happens when enter key pressed */
export const enterKey = soloKeyPress(13, "Enter");
/** onTab fn happens when tab key pressed */
export const tabKey = soloKeyPress(8, "Tab");
/** onEnter fn happens when enter key pressed */
export const pgUpKey = soloKeyPress(33, "PageUp");
/** onEnter fn happens when enter key pressed */
export const pgDownKey = soloKeyPress(34, "PageDown");

/** Concatenates keyDown listeners */
export const keyListeners =
    (...listeners: ((event: React.KeyboardEvent | KeyboardEvent) => void)[]) =>
    (event: React.KeyboardEvent | KeyboardEvent) => {
        listeners.forEach((l) => l(event));
    };
