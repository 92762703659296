import {
    type ForwardRefExoticComponent,
    type MouseEvent,
    type PropsWithoutRef,
    type ReactNode,
    type SVGProps,
    useCallback,
    useContext,
    useMemo,
} from "react";

import { styles, useBreakpoint, useOneShot } from "../../../helpers";
import { TabsContext } from "./tabs";

interface TabProps {
    title: string | ReactNode | ReactNode[];
    id: string | number;
    delayLoad?: boolean;
    alwaysReload?: boolean;
    className?: string;
    children?: ReactNode | ReactNode[];
}

export function Tab(props: TabProps) {
    const { activeTab, isLink, tabBodyClass } = useContext(TabsContext);
    const { id, children, delayLoad, alwaysReload } = props;

    const isActive = useMemo(() => activeTab === id, [activeTab, id]);
    const activeOnce = useOneShot(isActive);

    if (isLink) return <TabHeader {...props} />;

    if (delayLoad && !activeOnce) return null;
    if (alwaysReload && !isActive) return null;

    return (
        <div key={id} style={{ display: isActive ? "block" : "none" }} className={tabBodyClass}>
            {children}
        </div>
    );
}

export function TabHeader(props: TabProps) {
    const { title, id } = props;
    const { activeTab, setActiveTab, tabHeaderClass } = useContext(TabsContext);
    const { isSm } = useBreakpoint("sm");

    const onClick = useCallback(() => setActiveTab(id), [setActiveTab, id]);
    const isActive = useMemo(() => activeTab === id, [activeTab, id]);

    return (
        <button
            key={id}
            onClick={onClick}
            className={styles(
                isActive
                    ? "border-indigo-400 text-indigo-400 hover:border-indigo-600"
                    : "border-transparent hover:border-gray-300",
                !isSm && "w-1/6",
                "border-b-2 px-1 py-4 text-center text-sm font-medium",
                tabHeaderClass,
            )}
            aria-current={isActive ? "page" : undefined}
        >
            {title}
        </button>
    );
}

type IconType = ForwardRefExoticComponent<PropsWithoutRef<SVGProps<SVGElement>>>;
interface TabTitleProps {
    text: string;
    icon?: IconType;
    rightIcon?: IconType;
    onClickRight?: (e?: MouseEvent) => void;
}

export function TabTitle(props: TabTitleProps) {
    return (
        <div className="grid grid-cols-3 items-center justify-items-center">
            <div></div>
            <div className="flex flex-row items-center">
                {renderIcon(props.icon, "justify-self-end")}
                {props.text}
            </div>
            {renderIcon(props.rightIcon, undefined, props.onClickRight)}
        </div>
    );
    function renderIcon(Icon?: IconType, className?: string, onClick?: (e?: MouseEvent) => void) {
        if (!Icon) return null;
        const clickHandler =
            onClick &&
            function (e: MouseEvent) {
                e.preventDefault();
                e.stopPropagation();
                onClick(e);
            };
        return <Icon className={styles("h-7 w-7 p-1", className, onClick && "hover:shadow")} onClick={clickHandler} />;
    }
}
