import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";

import { ReportingApi } from "../../../api/reportingApi";
import { QueryKeys } from "../../../helpers";
import { type ReportingFilter } from "../../../models";
import { ChartSkeleton } from "../../basic";
import { ComboChart } from "../../basic";
import { DistrictsDropdown } from "../../dropdowns/districtsDropdown";

export interface Props {
    filterData: ReportingFilter;
}

export function Districts(props: Props) {
    const { filterData } = props;
    const [axis, setAxis] = useState<string[]>([]);
    const [district, setDistrict] = useState<string>("Statewide");
    const [columns, setColumns] = useState<{ name: string; data: number[] }[] | undefined>(undefined);
    const [spline, setSpline] = useState<{ name: string; data: number[] } | undefined>(undefined);

    const reportQuery = useQuery({
        queryKey: [QueryKeys.CAMPAIGN_GOALS, filterData],
        queryFn: () => {
            return ReportingApi.getCampaignGoalProgress(filterData);
        },
    });

    const data = useMemo(
        () => reportQuery.data?.filter((item) => item.boundary === district),
        [district, reportQuery.data],
    );

    useEffect(() => {
        const map = new Map<string, number[]>();
        const days = data?.pop()?.days ?? [];

        map.set("Known Voter", Array(days.length).fill(0) as number[]);
        map.set("Unknown Voter", Array(days.length).fill(0) as number[]);
        map.set("Goal", Array(days.length).fill(0) as number[]);

        days.forEach((item, index) => {
            const count = map.get("Known Voter") ?? [];
            const unknown = map.get("Unknown Voter") ?? [];
            const goal = map.get("Goal") ?? [];

            count[index] = item.runningKnownVoter;
            unknown[index] = item.runningUnknownVoter;
            goal[index] = item.runningGoal;
        });

        setAxis(days.map((item) => format(item.date, "yyyy-MM-dd")) ?? []);
        // setSeries(Array.from(map.entries()).map(([name, count]) => ({ name, data: count })));

        const newColumns = [
            { name: "Known Voter", data: map.get("Known Voter") ?? [] },
            { name: "Unknown Voter", data: map.get("Unknown Voter") ?? [] },
        ];

        setColumns(newColumns);
        setSpline({ name: "Goal", data: map.get("Goal") ?? [] });
    }, [data]);

    if (reportQuery.isLoading) return <ChartSkeleton />;

    if (!reportQuery.data || reportQuery.data.length === 0) return null;

    return (
        <div className="card">
            <h2 className="title">Cumulative Completed TPV</h2>
            <DistrictsDropdown
                filterData={filterData}
                value={district}
                selectVal={(val) => setDistrict(val.value.toString())}
            />
            <ComboChart
                id="rep-production-combo"
                title=""
                axis={axis}
                areas={columns ?? []}
                spline={spline}
                columns={[]}
                noCard
            />
        </div>
    );
}
