import { FolderArrowDownIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import XLSX from "xlsx";

import { Button, useErrorToast, useSuccessToast } from "../../basic";
import { DropdownButton, type DropdownOption } from "../../basic/button/dropdownButton";

interface ExportProps<F> {
    exportData?: () => Promise<string | undefined>;
    filters?: F;
    exportFileName?: string;
    exportFiles?: () => Promise<any>;
}

export function Export<F>(props: ExportProps<F>) {
    const successToast = useSuccessToast();
    const errorToast = useErrorToast();

    const download = async (type: "csv" | "xlsx") => {
        if (!props.exportData) return;

        const csv = await props.exportData();

        if (!csv) return;

        const fileName = `${props.exportFileName || "export"}_${new Date().toISOString()}`;

        const workbook = XLSX.read(csv, { type: "string" });

        if (type === "csv") {
            XLSX.writeFile(workbook, `${fileName}.csv`);
        } else {
            XLSX.writeFileXLSX(workbook, `${fileName}.xlsx`);
        }
    };

    const downloadMutation = useMutation({
        mutationFn: download,
        onSuccess: () => {
            successToast(
                "Export Complete",
                "Your export has been downloaded. Look in your downloads folder if you don't see it.",
            );
        },
        onError: () => {
            errorToast("Export Error", "Something went wrong with your export. Please try again.");
        },
    });

    const downloadFileMutation = useMutation({
        mutationFn: props.exportFiles,
        onSuccess: () => {
            successToast(
                "File Export Complete",
                "Your export has been downloaded. Look in your downloads folder if you don't see it.",
            );
        },
        onError: () => {
            errorToast("Export Error", "Something went wrong with your export. Please try again.");
        },
    });

    const options = useMemo(
        () =>
            [
                {
                    label: "Export as CSV",
                    onClick: () => downloadMutation.mutate("csv"),
                },
                {
                    label: "Export as XLSX",
                    onClick: () => downloadMutation.mutate("xlsx"),
                },
                props.exportFiles && {
                    label: "Export Files",
                    onClick: () => downloadFileMutation.mutate(),
                    icon: <PaperClipIcon className="w-4" />,
                },
            ].filter((o) => o) as DropdownOption[],
        [props.exportFiles, downloadMutation, downloadFileMutation],
    );

    if (!props.exportData) return null;

    const isPending = downloadMutation.isPending || downloadFileMutation.isPending;

    return isPending ? (
        <div className="flex gap-2">
            <Button variant="secondary" isLoading>
                {downloadFileMutation.isPending ? "Downloading Files..." : "Downloading..."}
            </Button>
        </div>
    ) : (
        <DropdownButton
            name={
                <span className="flex items-center justify-center">
                    <FolderArrowDownIcon className="mr-1 w-5" />
                    Export Data
                </span>
            }
            options={options}
        />
    );
}
