import { Severity, logger } from "../helpers";
import { type CheckInFilter, type GridResult } from "../models";
import { type FormScanBatchDto } from "../models/formScanBatchDto";
import { type ScanBatchSummaryDto } from "../models/scanBatchSummaryDto";
import { type ScanBatchDirection, type ScanResponseDto } from "../models/scanResponseDto";
import { doGet, doPost } from "./httpHandler";

const controller = "/api/Scan";

export class ScanApi {
    public static getAll = (
        signal: AbortSignal,
        itemsPerPage = 10,
        currentPage = 1,
        filters?: Partial<CheckInFilter>,
    ) => {
        try {
            return doPost<GridResult<FormScanBatchDto>, Partial<CheckInFilter>>(
                controller,
                "GetAll",
                { itemsPerPage, currentPage },
                filters,
                { signal },
            );
        } catch (e) {
            logger(Severity.Error, e);
        }
    };

    public static getUserPreviousScan = () => {
        return doGet<ScanBatchSummaryDto>(controller, "", undefined);
    };

    public static getBatchSummary = (params: { batchId: number }) => {
        return doGet<ScanBatchSummaryDto>(controller, "GetBatchSummary", params);
    };

    public static createBatch = (params: { campaignId: number; direction: ScanBatchDirection }) => {
        return doPost<ScanBatchSummaryDto, undefined>(controller, "CreateBatch", params);
    };

    public static scan = (params: { batchId: number; formLinkGuid: string }) => {
        return doPost<ScanResponseDto, undefined>(controller, "Scan", params);
    };

    public static completeBatch = (params: { batchId: number }) => {
        return doPost<ScanBatchSummaryDto, undefined>(controller, "CompleteBatch", params);
    };
}
