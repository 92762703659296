/* eslint-disable no-console */

export enum Severity {
    Info,
    Warning,
    Error,
}

export function logger(severity: Severity, message: unknown) {
    // TODO: In production log to external service, application insights?
    if (import.meta.env.PROD && severity !== Severity.Error) {
        return;
    }

    switch (severity) {
        case Severity.Info:
            console.log(message);
            break;
        case Severity.Warning:
            console.warn(message);
            break;
        case Severity.Error:
            console.error(message);
            break;
        default:
            console.log(message);
    }
}
