import { type FC } from "react";

export const ImageSkeleton: FC = () => {
    return (
        <>
            <div className="h-[48rem] w-full animate-pulse rounded bg-gray-300 dark:bg-gray-700" />
            <span className="sr-only">loading image</span>
        </>
    );
};
