import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useCallback, useContext, useMemo, useState } from "react";

import { UserContext } from "../../../contexts/userContext";
import { formatName } from "../../../helpers";
import { useMapVerbiage, useReplaceVerbiage } from "../../../hooks/verbiageHooks";
import { type RepresentativeDto } from "../../../models";
import { UserPermissionFlags } from "../../../models/permissions";
import { Button, Dialog } from "../../basic";
import { CreateUpdateRepresentative } from "../createUpdateRepresentative";
import { useToggleRepresentative } from "../useToggleRepresentative";

interface Props {
    rep: RepresentativeDto;
    disabled?: boolean;
}

export function RepBasicInfoView(props: Props) {
    const { rep, disabled } = props;
    const { user } = useContext(UserContext);
    const mapVerbiage = useMapVerbiage();
    const replaceVerbiage = useReplaceVerbiage();

    return (
        <div className="grid p-3">
            <div className="flex flex-row gap-4">
                {!disabled && <ManageRep rep={rep} />}
                <div>
                    <BasicInfoItem label={replaceVerbiage("Rep", "Rep ID")} value={rep.repId} />
                    <BasicInfoItem label="External ID" value={rep.repId} />
                    <BasicInfoItem label="Name" value={formatName(rep.firstName, rep.lastName)} />
                    <BasicInfoItem label="Status" value={rep.active ? "Active" : "Inactive"} />
                </div>
                <div>
                    {!user?.clientId && <BasicInfoItem label="Client" value={rep.clientName} />}
                    <BasicInfoItem label={mapVerbiage("Campaign")} value={rep.campaignName} />
                    {!user?.channelId && <BasicInfoItem label="Channel" value={rep.channelName} />}
                    {!user?.stateId && <BasicInfoItem label="State" value={rep.stateName} />}
                </div>
                <div>
                    <div>
                        <span className="label">Address</span>
                        <span>
                            <p>
                                {rep.address1} {rep.address2}
                            </p>
                            <p>
                                {rep.city}, {rep.stateName} {rep.zip}
                            </p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function BasicInfoItem(props: ItemProps) {
    return (
        <div className="grid grid-rows-2" key={props.label}>
            <label className="label">{props.label}</label>
            <span>{props.value}</span>
        </div>
    );
}

interface ItemProps {
    label: string;
    value: string | undefined;
}

function ManageRep(props: ManageRepProps) {
    const { hasPermission } = useContext(UserContext);
    const canManage = useMemo(() => hasPermission(UserPermissionFlags.RepresentativesManage), [hasPermission]);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const toggle = useToggleRepresentative();
    const toggleRep = useCallback(() => toggle([props.rep.id], !props.rep.active), [toggle, props.rep]);
    if (!canManage) return null;
    return (
        <div id="commands" className="grid grid-rows-2 gap-2">
            <Button variant="secondary" onClick={() => setOpenEditDialog(true)}>
                Edit <PencilSquareIcon className="mr-1 w-6 pl-1" />
            </Button>
            <Dialog
                open={openEditDialog}
                setOpen={setOpenEditDialog}
                title="Edit Representative"
                body={<CreateUpdateRepresentative rep={props.rep} setOpen={setOpenEditDialog} />}
            />
            {props.rep.active && (
                <Button onClick={toggleRep} variant="destructive">
                    Deactivate
                </Button>
            )}
            {!props.rep.active && (
                <Button onClick={toggleRep} variant="success">
                    Activate
                </Button>
            )}
        </div>
    );
}
interface ManageRepProps {
    rep: RepresentativeDto;
}
