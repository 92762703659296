import { type FC, type ReactNode } from "react";

import { styles } from "../../../helpers";

interface TabPageProps {
    className?: string;
    children: ReactNode;
}

export const TabPage: FC<TabPageProps> = ({ children, className }) => {
    return <article className={styles("border-x border-b border-gray-50", className)}>{children}</article>;
};
