import { useEffect, useState } from "react";
import ReactSelect, {
    type ClearIndicatorProps,
    type ControlProps,
    type DropdownIndicatorProps,
    type GroupBase,
    type GroupProps,
    type IndicatorsContainerProps,
    type InputProps,
    type MenuListProps,
    type MenuProps,
    type OptionProps,
    type Props,
    type SingleValueProps,
    type ValueContainerProps,
    components,
} from "react-select";
import AsyncSelect from "react-select/async";

import { styles } from "../../../helpers";

export interface SelectOption {
    value: string | number;
    label: string | number;
}

export interface SelectProps extends Props {
    label?: string;
    inline?: boolean;
    changeHandler?: (value?: any, name?: any) => void;
    textProp?: string;
    valueProp?: string;
    value?: SelectOption | number | string | SelectOption[] | number[] | string[];
    options?: SelectOption[];
    loadOptions?: (inputValue: string, callback: (options: SelectOption[]) => void) => Promise<SelectOption[]> | void;
}

function Control<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    children,
    ...props
}: ControlProps<T, IsMulti, Group>) {
    const { isFocused, isDisabled } = props;

    return (
        <components.Control
            {...props}
            className={styles(
                !isDisabled ? "!bg-white dark:!bg-gray-700" : "",
                isFocused ? "border-none border-pink-300 ring-2 ring-pink-300" : "",
                isDisabled ? "cursor-not-allowed !bg-gray-200 dark:!bg-gray-600" : "",
            )}
        >
            {children}
        </components.Control>
    );
}

function ValueContainer<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    children,
    ...props
}: ValueContainerProps<T, IsMulti, Group>) {
    return (
        <components.ValueContainer {...props}>
            <span className="text-lef flex flex-1 items-center gap-2 py-0.5">
                <span className="flex w-full items-center text-gray-900 dark:text-white">{children}</span>
            </span>
        </components.ValueContainer>
    );
}

function SingleValue<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    children,
    ...props
}: SingleValueProps<T, IsMulti, Group>) {
    return (
        <components.SingleValue {...props}>
            <span className="text-gray-900 dark:text-white">{children}</span>
        </components.SingleValue>
    );
}

function Option<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    children,
    ...props
}: OptionProps<T, IsMulti, Group>) {
    return (
        <components.Option
            {...props}
            className={styles(
                props.isSelected ? "!bg-indigo-500" : "",
                props.isFocused ? "bg-indigo-200 dark:bg-indigo-900" : "",
                "w-full hover:bg-indigo-200 focus:bg-indigo-300 hover:dark:bg-indigo-800 focus:dark:bg-indigo-900",
            )}
        >
            <span className="flex w-full flex-1 items-center gap-2 text-left">
                <span className="flex w-full items-center text-gray-900 dark:text-white">{children}</span>
            </span>
        </components.Option>
    );
}

function Group<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    children,
    ...props
}: GroupProps<T, IsMulti, Group>) {
    return (
        <components.Group {...props} className="w-full border-none bg-white dark:bg-gray-700">
            {children}
        </components.Group>
    );
}

function Menu<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    children,
    ...props
}: MenuProps<T, IsMulti, Group>) {
    return (
        <components.Menu {...props} className="bg-white dark:bg-gray-700">
            <span className="flex flex-1 items-center gap-2 bg-white text-left dark:bg-gray-700">
                <span className="flex w-full items-center text-gray-900 dark:text-white">{children}</span>
            </span>
        </components.Menu>
    );
}

function MenuList<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    children,
    ...props
}: MenuListProps<T, IsMulti, Group>) {
    return (
        <components.MenuList {...props} className="w-full bg-white text-gray-900 dark:bg-gray-700 dark:text-white">
            {children}
        </components.MenuList>
    );
}

function IndicatorsContainer<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    children,
    ...props
}: IndicatorsContainerProps<T, IsMulti, Group>) {
    return <components.IndicatorsContainer {...props}>{children}</components.IndicatorsContainer>;
}

function ClearIndicator<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    children,
    ...props
}: ClearIndicatorProps<T, IsMulti, Group>) {
    return (
        <components.ClearIndicator {...props} className="bg-transparent">
            {children}
        </components.ClearIndicator>
    );
}

function DropdownIndicator<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    children,
    ...props
}: DropdownIndicatorProps<T, IsMulti, Group>) {
    return (
        <components.DropdownIndicator {...props} className="bg-transparent">
            {children}
        </components.DropdownIndicator>
    );
}

function Input<T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    children,
    ...props
}: InputProps<T, IsMulti, Group>) {
    return (
        <components.Input {...props} className="text-gray-900 dark:text-white">
            {children}
        </components.Input>
    );
}

export function Select(props: SelectProps) {
    const [val, setVal] = useState<SelectOption | undefined>(undefined);
    const { value, label, inline, changeHandler, className, ...rest } = props;

    useEffect(() => {
        if (typeof value === "string" || typeof value === "number" || Array.isArray(value)) {
            setVal(
                rest.options
                    ?.filter((item) => item.value?.toString()?.toLowerCase() === value?.toString()?.toLowerCase())
                    .pop(),
            );
        } else {
            setVal(value as SelectOption);
        }
    }, [value, rest.options]);

    useEffect(() => {
        if (rest.defaultValue) {
            changeHandler?.(rest.defaultValue);
        }
        // disabling exhaustive-deps because we only want to run this when default value is changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest.defaultValue]);

    return (
        <div>
            <div className={inline ? "flex items-center" : ""}>
                <label htmlFor={props.id} className="label">
                    {label} {rest.required && "*"}
                </label>
                <div className={inline ? "ml-2" : "mt-2"}>
                    {props.loadOptions ? (
                        <AsyncSelect
                            {...rest}
                            value={val}
                            components={{
                                Control,
                                Option,
                                ValueContainer,
                                SingleValue,
                                Group,
                                Menu,
                                MenuList,
                                IndicatorsContainer,
                                ClearIndicator,
                                DropdownIndicator,
                                Input,
                            }}
                            className={className}
                            onChange={changeHandler}
                        />
                    ) : (
                        <ReactSelect
                            {...rest}
                            value={val}
                            components={{
                                Control,
                                Option,
                                ValueContainer,
                                SingleValue,
                                Group,
                                Menu,
                                MenuList,
                                IndicatorsContainer,
                                ClearIndicator,
                                DropdownIndicator,
                                Input,
                            }}
                            className={className}
                            onChange={changeHandler}
                            menuPlacement="auto"
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
