import { Menu, Transition } from "@headlessui/react";
import { type FC, Fragment, type ReactNode } from "react";

import { buttonVariants } from "./button";

export interface DropdownOption {
    label: string;
    icon?: ReactNode;
    onClick: () => void;
}

interface DropdownButtonProps {
    name: string | ReactNode;
    options: DropdownOption[];
}

export const DropdownButton: FC<DropdownButtonProps> = (props) => {
    return (
        <div>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className={buttonVariants({ variant: "default" })}>{props.name}</Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute left-0 z-50 mt-2 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800">
                        <div className="px-1 py-1">
                            {props.options.map((option) => (
                                <Menu.Item key={option.label}>
                                    {({ active }) => (
                                        <button
                                            className={`${
                                                active ? "bg-indigo-500 text-white" : ""
                                            } flex w-36 items-center rounded-md px-2 py-2 text-sm`}
                                            onClick={option.onClick}
                                        >
                                            {option.icon && <div className="mr-2">{option.icon}</div>}
                                            {option.label}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
};
