import type { FC } from "react";
import { Outlet } from "react-router-dom";

import { CheckInProvider } from "../../contexts/checkInContext";

export const CheckInProviders: FC = () => {
    return (
        <CheckInProvider>
            <Outlet />
        </CheckInProvider>
    );
};
