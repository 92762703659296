import { type FC } from "react";

import { type AuditScanResponseDto } from "../../models";

interface MissingTpvProps {
    results: AuditScanResponseDto | undefined;
}

export function missing(results: AuditScanResponseDto | undefined) {
    return !results?.verificationId;
}

export const MissingTpv: FC<MissingTpvProps> = ({ results }) => {
    if (!results)
        //No results yet, don't show anything
        return null;
    if (results.verificationId) return null;

    return (
        <div className="card mb-8">
            <h3 className="title">Missing TPV</h3>
            <p className="body">The scanned form does not have an associated TPV.</p>
        </div>
    );
};
