import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { GridFiltersApi } from "../../api/gridFiltersApi";
import { QueryKeys } from "../../helpers";
import { useMapVerbiage } from "../../hooks/verbiageHooks";
import { type DropdownProps, FilterDropdown } from "./filterDropDown";

interface CampaignDropdownProps extends DropdownProps {
    segment?: boolean;
    clientId?: number;
    channelId?: number;
    stateId?: number;
}

export const CampaignDropdown: FC<CampaignDropdownProps> = (props) => {
    const mapVerbiage = useMapVerbiage();
    const query = useQuery({
        queryKey: [QueryKeys.CAMPAIGNS_FILTER, props.segment, props.clientId, props.channelId, props.stateId],
        queryFn: () => {
            if (props.segment) {
                return GridFiltersApi.getCampaignsBySegment(props.clientId, props.channelId, props.stateId);
            }
            return GridFiltersApi.getCampaigns();
        },
    });

    return <FilterDropdown id="campaigns" label={mapVerbiage("Campaign")} {...props} query={query} />;
};
