import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { type FC, useContext, useEffect } from "react";

import { styles } from "../../../helpers";
import { Button } from "../button";
import { ToastContext } from "./toastContext";

function typeToColor(type: string) {
    switch (type) {
        case "success":
            return "!bg-emerald-500";
        case "error":
            return "!bg-rose-500";
        case "warning":
            return "!bg-amber-500";
        case "info":
            return "!bg-indigo-500";
        default:
            return "!bg-indigo-500";
    }
}

export const Toast: FC = () => {
    const { toast, setToast } = useContext(ToastContext);

    useEffect(() => {
        const handleToastTimeout = () => {
            setTimeout(() => {
                setToast(undefined);
            }, toast?.timeout);
        };

        if (toast?.timeout) {
            handleToastTimeout();
        }
    }, [setToast, toast]);

    const handleToastClose = () => {
        setToast(undefined);
    };

    if (!toast) return null;

    return (
        <Transition
            show={!!toast}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="absolute bottom-0 z-50 flex w-full justify-center">
                <div
                    className={styles(
                        typeToColor(toast.type),
                        "card container mx-8 my-8 flex items-center justify-between text-white",
                    )}
                >
                    <div>
                        <h4 className="text-lg font-bold">{toast.title}</h4>
                        <p>{toast.message}</p>
                    </div>
                    <Button variant="outline" size="icon" onClick={handleToastClose}>
                        <XMarkIcon className="w-4" />
                    </Button>
                </div>
            </div>
        </Transition>
    );
};
