import { Tabs, type TabsProps } from "../tabs";

export function DataItemView(props: Props) {
    return (
        <Tabs containerClass="p-4" {...props}>
            {props.children}
        </Tabs>
    );
}

type Props = TabsProps;
