import { useQueryClient } from "@tanstack/react-query";
import { type FC } from "react";

import { QueryKeys } from "../../helpers";
import { type UserDto } from "../../models";
import { Button } from "../basic";
import { useToggleUsers } from "./useToggleUser";

interface SelectedActionsProps {
    users: UserDto[];
}

export const SelectedActions: FC<SelectedActionsProps> = (props) => {
    const queryClient = useQueryClient();
    const activeUsers = props.users.filter((u) => u.active).map((item) => item.id);
    const inactiveUsers = props.users.filter((u) => !u.active).map((item) => item.id);

    const toggle = useToggleUsers();

    const isLoading = !!queryClient.isMutating({ mutationKey: [QueryKeys.USERS_TOGGLE_MUTATION] });

    return (
        <div className="flex gap-2 p-4">
            {inactiveUsers.length > 0 ? (
                <Button isLoading={isLoading} variant="secondary" onClick={() => toggle(inactiveUsers, true)}>
                    Activate
                </Button>
            ) : null}
            {activeUsers.length > 0 ? (
                <Button isLoading={isLoading} variant="secondary" onClick={() => toggle(activeUsers, false)}>
                    Deactivate
                </Button>
            ) : null}
        </div>
    );
};
