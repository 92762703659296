export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;
export const MS_IN_SECOND = 1000;

export const TOAST_TIMEOUT = 5000;

const MS_IN_MINUTE = MS_IN_SECOND * SECONDS_IN_MINUTE;
const MS_IN_HOUR = MS_IN_MINUTE * MINUTES_IN_HOUR;
const MS_IN_DAY = MS_IN_HOUR * HOURS_IN_DAY;

export const MS_IN = {
    SECOND: MS_IN_SECOND,
    MINUTE: MS_IN_MINUTE,
    HOUR: MS_IN_HOUR,
    DAY: MS_IN_DAY,
};

export default {
    HOURS_IN_DAY,
    MINUTES_IN_HOUR,
    SECONDS_IN_MINUTE,
    MS_IN_SECOND,
    MS_IN,
};

export const MAX_INT = 2147483647;
export const MIN_INT = -2147483648;
