import { PlusIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";

import { RepresentativesApi } from "../../api/representativesApi";
import { UserContext } from "../../contexts/userContext";
import { Severity, formatName, formatShortDateOf, logger } from "../../helpers";
import { QueryKeys, usePropReducer, useTransitionState } from "../../helpers";
import { useMapVerbiage, useReplaceVerbiage } from "../../hooks/verbiageHooks";
import { type IFilter, type RepresentativeDto, type RepresentativesFilter } from "../../models";
import { UserPermissionFlags } from "../../models/permissions";
import { Button, Column, Dialog } from "../basic";
import { FilterGroups } from "../gridFilters/types";
import { GridPage } from "../gridPage";
import { CreateUpdateRepresentative } from "./createUpdateRepresentative";
import { RepGridDetail } from "./details/repGridDetail";
import { SelectedActions } from "./selectedActions";

export default function RepresentativeGrid() {
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [filterData, dispatchFilterData] = usePropReducer({} as RepresentativesFilter);
    const mapVerbiage = useMapVerbiage();
    const replaceVerbiage = useReplaceVerbiage();
    const [itemsPerPage, setItemsPerPage] = useTransitionState(10);
    const [currentPage, setCurrentPage] = useTransitionState(1);
    const [sortFilter, setSortFilter] = useState<IFilter>({});
    const { user, hasPermission } = useContext(UserContext);

    const columns = [
        new Column(replaceVerbiage("Rep", "Rep ID"), "repId"),
        new Column("Name").withFormat((r: RepresentativeDto) => formatName(r.firstName, r.lastName)),
        new Column("Status").withFormat(formatStatus),
        new Column("Segment").withFormat(formatSegmentData),
        new Column(mapVerbiage("Campaign"), "campaignName"),
        new Column("External ID", "externalId"),
        new Column("Last Verification Info").withFormat(formatVerification),
    ] as Column<RepresentativeDto>[];

    async function getData(signal: AbortSignal) {
        try {
            return await RepresentativesApi.getRepresentatives(signal, itemsPerPage, currentPage, {
                ...filterData,
                ...sortFilter,
            });
        } catch (ex) {
            logger(Severity.Error, ex);
        }
    }

    if (!user) return null;

    const initialRep: RepresentativeDto = {
        campaignId: undefined,
        createdOn: new Date(),
        id: 0,
        updatedOn: new Date(),
        active: true,
        firstName: "",
        lastName: "",
        repId: "",
        externalId: undefined,
        address1: "",
        address2: undefined,
        city: "",
        state: undefined,
        zip: "",
        channelName: user.channelName,
        channelId: user.channelId,
        clientName: user.clientName,
        clientId: user.clientId,
        stateName: user.stateName,
        stateId: user.stateId,
        events: undefined,
    };

    return (
        <>
            <GridPage<RepresentativesFilter, RepresentativeDto>
                title="Representatives"
                actionButton={
                    hasPermission(UserPermissionFlags.RepresentativesCreate) ? (
                        <Button variant="default" onClick={() => setOpenCreateDialog(true)}>
                            <PlusIcon className="mr-1 w-5" /> New Rep
                        </Button>
                    ) : undefined
                }
                filters={filterData}
                updateFilter={dispatchFilterData}
                visibleFilters={[FilterGroups.Segment, FilterGroups.Search, FilterGroups.Campaign, FilterGroups.Active]}
                queryKey={QueryKeys.REPS}
                getData={getData}
                columns={columns}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                sortFilter={sortFilter}
                setSortFilter={setSortFilter}
                selectedActions={
                    hasPermission(UserPermissionFlags.RepresentativesManage)
                        ? (rows) => <SelectedActions reps={rows} filterData={filterData} />
                        : undefined
                }
                expandable
                pinnable
                getRowId={(row) => row.id.toString()}
                renderSubComponent={(row) => <RepGridDetail row={row.row} />}
                getRowCanExpand={() => true}
            />
            <Dialog
                open={openCreateDialog}
                setOpen={setOpenCreateDialog}
                title="Create Representative"
                body={<CreateUpdateRepresentative rep={initialRep} setOpen={setOpenCreateDialog} create />}
            />
        </>
    );
}

function formatSegmentData(rep: RepresentativeDto) {
    if (!rep) return null;
    return `${rep.clientCode ?? ""}-${rep.channelCode ?? ""}-${rep.stateCode ?? ""}`;
}

function formatStatus(rep: RepresentativeDto) {
    return rep.active ? "Active" : "Inactive";
}

function formatVerification(rep: RepresentativeDto) {
    if (!rep?.lastVerification) return null;
    const { id, createdOn, statusText } = rep.lastVerification;
    return `${formatShortDateOf(createdOn) ?? ""} - ${id} - ${statusText}`;
}
