import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { GridFiltersApi } from "../../api/gridFiltersApi";
import { QueryKeys } from "../../helpers";
import { type DropdownProps, FilterDropdown } from "./filterDropDown";

interface StatesDropdownProps extends DropdownProps {
    all?: boolean;
    abbreviation?: boolean;
}

export const StatesDropdown: FC<StatesDropdownProps> = (props) => {
    const query = useQuery({
        queryKey: [QueryKeys.STATES_FILTER, props.abbreviation, props.all],
        queryFn: async () => {
            if (props.abbreviation) {
                return await GridFiltersApi.getStateAbbreviations(props.all);
            }

            return await GridFiltersApi.getStates(props.all);
        },
    });

    return <FilterDropdown id="states" label="State" {...props} query={query} />;
};
