import { type ChangeEvent, type FC, type InputHTMLAttributes, useCallback } from "react";

import { styles } from "../../../helpers";
import "./style.scss";

interface TextBoxProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    inline?: boolean;
    changeHandler?: (value: any, name?: any) => void;
    outerClassName?: string;
}

export const TextBox: FC<TextBoxProps> = (props) => {
    const { label, inline, changeHandler, className, outerClassName, onChange, id, ...rest } = props;

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (changeHandler) {
                changeHandler(e.target.value, id);
            } else if (onChange) {
                onChange(e);
            }
        },
        [changeHandler, onChange, id],
    );

    return (
        <div className={styles(outerClassName)}>
            <div className={styles(inline && label && "flex items-center")}>
                <label htmlFor={id} className="label">
                    {label} {rest.required && "*"}
                </label>
                <div className={inline ? "ml-2" : "mt-2"}>
                    <input id={id} {...rest} className={styles("input", className)} onChange={handleChange} />
                </div>
            </div>
        </div>
    );
};
