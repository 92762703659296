export function first<T>(list: T[]): T | undefined {
    return list.at(0);
}

export function last<T>(list: T[]): T | undefined {
    return list.at(list.length - 1);
}

export function insertIf<T>(condition: boolean, ...elements: T[]): T[] {
    return condition ? elements : [];
}
