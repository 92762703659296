import { useQuery } from "@tanstack/react-query";
import { type FC, useMemo } from "react";

import { UsersApi } from "../../../api/usersApi";
import { QueryKeys, formatDateTimeOf, splitWordsOnUpperCase } from "../../../helpers";
import { type EventDto, type UserDto } from "../../../models";
import { Column, DataGridClient } from "../../basic";

interface UserWidgetsGridProps {
    user: UserDto;
}

export const UserWidgetEventGrid: FC<UserWidgetsGridProps> = ({ user }) => {
    const columns = useMemo(() => {
        return [
            new Column("At", "at").withFormat(formatDateTimeOf),
            new Column("Event", "eventType").withFormat(splitWordsOnUpperCase),
            new Column("By", "by"),
            new Column("Remarks", "remarks"),
        ] as Column<EventDto>[];
    }, []);

    const userEventsQuery = useQuery({
        queryKey: [QueryKeys.USER_EVENTS, user.id],
        queryFn: ({ signal }) => UsersApi.getEvents(user.id, signal),
    });

    return <DataGridClient data={userEventsQuery.data ?? []} loading={userEventsQuery.isLoading} columns={columns} />;
};
