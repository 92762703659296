import { useQuery } from "@tanstack/react-query";

import { ReportingApi } from "../../../api/reportingApi";
import { QueryKeys } from "../../../helpers";
import { type ReportingData, type ReportingFilter } from "../../../models";
import { ChartSkeleton } from "../../basic";
import { PieChart } from "../../basic";

export interface Props {
    filterData: ReportingFilter;
}

export function SalesOutcomeWidget(props: Props) {
    const { filterData } = props;

    const salesOutcomeQuery = useQuery({
        queryKey: [QueryKeys.SALES_OUTCOMES, filterData],
        queryFn: async () => {
            const map = new Map<string, ReportingData>();
            const outcomes = await ReportingApi.getSaleOutcomes(filterData);
            outcomes.forEach((outcome) => map.set(outcome.name, outcome));

            return [
                { name: "Successful", y: map.get("Successful")?.count ?? 0, order: 1 },
                { name: "Failed", y: map.get("Failed")?.count ?? 0, order: 2 },
                { name: "Pending", y: map.get("Pending")?.count ?? 0, order: 3 },
            ];
        },
    });

    if (salesOutcomeQuery.isLoading) return <ChartSkeleton />;

    return <PieChart id="sales-outcome-pie" title="TPV Results" data={salesOutcomeQuery.data ?? []} />;
}
