import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { type Header, type SortDirection, flexRender } from "@tanstack/react-table";
import { type DragEvent, type ReactNode, useCallback, useMemo, useRef, useState } from "react";

import { styles } from "../../../helpers";
import { type IFilter } from "../../../models";
import { type CustomTable } from "./dataGrid";

interface Props<T> {
    table: CustomTable<T>;
    options?: (row: T) => ReactNode;
    rightOptions?: (row: T) => ReactNode;
    setSortFilter?: (sortFilter: IFilter) => void;
}

export function DataGridTableHead<T = object>(props: Props<T>) {
    const { table, options, rightOptions, setSortFilter } = props;
    const columnBeingDragged = useRef<number>(-1);

    const [columnOver, setColumnOver] = useState(-1);
    const onDragStart = (e: DragEvent<HTMLElement>): void => {
        columnBeingDragged.current = Number(e.currentTarget.dataset.columnIndex);
    };

    //TODO: don't allow drag over action columns
    const onDragOver = (e: DragEvent<HTMLElement>): void => {
        e.preventDefault();
        const index = Number(e.currentTarget.dataset.columnIndex);
        /*
        const headers = table.getHeaderGroups().reverse()[0]?.headers;
        const matchingHeader = headers?.[index];

        console.log(matchingHeader);
        */
        setColumnOver(index);
    };

    const onDrop = (e: DragEvent<HTMLElement>): void => {
        e.preventDefault();
        const newPosition = Number(e.currentTarget.dataset.columnIndex);
        const currentCols = table.getVisibleLeafColumns().map((c) => c.id);
        const colToBeMoved = currentCols.splice(columnBeingDragged.current, 1)[0] as string;

        currentCols.splice(newPosition, 0, colToBeMoved);
        table.setColumnOrder(currentCols); //TODO: possibly save elsewhere?
        setColumnOver(-1);
    };

    //TODO: maybe color header groups?
    return (
        <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                    {rightOptions ? <th colSpan={1} /> : null}
                    {headerGroup.headers.map((header) => {
                        const isGroup = header.column.columns.length > 0;
                        return (
                            <th
                                key={header.id}
                                colSpan={header.colSpan}
                                data-column-index={header.index}
                                draggable={!table.getState().columnSizingInfo.isResizingColumn}
                                onDragStart={onDragStart}
                                onDragOver={onDragOver}
                                onDrop={onDrop}
                                className={styles(
                                    header.index === columnOver &&
                                        columnOver < columnBeingDragged.current &&
                                        "border-l-4",
                                    header.index === columnOver &&
                                        columnOver > columnBeingDragged.current &&
                                        "border-r-4",
                                    isGroup && "",
                                )}
                            >
                                <HeaderCell
                                    header={header}
                                    setSortFilter={setSortFilter}
                                    isGroup={header.column.columns.length > 0}
                                    sortable={header.column.getCanSort()}
                                    sortDirection={header.column.getIsSorted()}
                                />
                            </th>
                        );
                    })}
                    {options ? <th colSpan={1} /> : null}
                </tr>
            ))}
        </thead>
    );
}

interface HeaderCellProps<T> {
    header: Header<T, any>;
    setSortFilter?: (sortFilter: IFilter) => void;
    sortable: boolean;
    sortDirection: SortDirection | false;
    isGroup?: boolean;
}
function HeaderGroupCell<T = any>(props: HeaderCellProps<T>) {
    return (
        <span className="flex flex-row justify-center border-x-4 sm:ml-4 sm:pr-6">
            <HeaderCell {...props} isGroup={false} />
        </span>
    );
}
function HeaderCell<T = any>(props: HeaderCellProps<T>) {
    const { header, setSortFilter, sortable, isGroup, sortDirection } = props;
    const titleClass = useMemo(
        () =>
            styles(
                sortable && "cursor-pointer select-none",
                "py-3.5 px-2 text-left flex",
                "text-sm font-semibold text-gray-900 dark:text-white",
            ),
        [sortable],
    );

    const setSort = useCallback(() => {
        if (!sortable) return;
        setSortFilter?.({
            sortBy: {
                name: header.column.id.split("|")[0] ?? "", //Pull out property name
                order:
                    // Get what the current sorting is, then change to what it should be next before toggling
                    sortDirection === "desc" ? -1 : sortDirection === "asc" ? 0 : 1,
            },
        });
        header.column.toggleSorting();
    }, [header, sortable, setSortFilter, sortDirection]);

    if (header.isPlaceholder) return null;
    if (isGroup) return <HeaderGroupCell {...props} />;

    return (
        <button className={titleClass} onClick={setSort}>
            {flexRender(header.column.columnDef.header, header.getContext())}
            {
                {
                    asc: <ChevronUpIcon className="ml-2 w-4" />,
                    desc: <ChevronDownIcon className="ml-2 w-4" />,
                    none: null,
                }[sortDirection || "none"]
            }
        </button>
    );
}
