import { type FC, type PropsWithChildren, type SyntheticEvent, useState } from "react";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";

import { formatDateTimeOf, styles, toFixed } from "../../helpers";
import { useMapVerbiage } from "../../hooks/verbiageHooks";
import { type AuditScanResponseDto } from "../../models";
import { TextOrLoading } from "../basic/loading/textOrLoading";
import { missing } from "./missingTpv";
import "./style.scss";

interface AuditPanelProps {
    results: AuditScanResponseDto;
}

export const AuditPanels: FC<AuditPanelProps> = ({ results }) => {
    const mapVerbiage = useMapVerbiage();

    if (missing(results)) return null;

    return (
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-3">
            <div className="card col-span-2">
                <dl className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                    <dt className="title">TPV ID</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={results?.verificationId} loading={false} />
                    </dd>
                    <dt className="title">{mapVerbiage("DateOfSale")}</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={formatDateTimeOf(results?.dateOfSale ?? "", false)} loading={false} />
                    </dd>
                    <dt className="title">TPV Completed</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={formatDateTimeOf(results?.completed ?? "", false)} loading={false} />
                    </dd>
                    <dt className="title">Disposition</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={results?.disposition} loading={false} />
                    </dd>
                    <dt className="title">{mapVerbiage("Rep")}</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={results?.rep} loading={false} />
                    </dd>
                    <dt className="title">Phone Number</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={results?.phoneNumber} loading={false} />
                    </dd>
                    <dt className="title">Name / CallerId</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={results?.name} loading={false} />
                        <span> / </span>
                        <TextOrLoading text={results?.callerId} loading={false} />
                    </dd>
                    <dt className="title">Address</dt>
                    <dd className="subtitle">
                        <TextOrLoading text={results?.serviceAddress} loading={false} />
                    </dd>
                    <dt className="title">Location</dt>
                    <dd className="subtitle">
                        <IfMissing item={results?.satelliteImageUrl} msg="Missing Location Image">
                            <ZoomImage
                                url={results.satelliteImageUrl}
                                desc="Satallite map of service address"
                                height={200}
                            />
                        </IfMissing>
                    </dd>
                    <dt className="title">Signature</dt>
                    <dd className="subtitle rounded bg-white p-2">
                        <IfMissing item={results?.signatureImageUrl} msg="Missing Signature Image">
                            <ZoomImage url={results.signatureImageUrl} desc="Signature" />
                        </IfMissing>
                    </dd>
                </dl>
            </div>
            <div className="card subtitle w-full">
                <IfMissing item={results?.formImageUrls?.length} msg="Missing TPV Image">
                    {results?.formImageUrls?.map((url) => <ZoomImage key={url} url={url} desc="TPV" height={550} />)}
                </IfMissing>
            </div>
        </div>
    );
};

const ZoomImage: FC<ZoomImageProps> = ({ url, height, desc }) => {
    // Adjusts zoom scaling in case image is too small to naturally zoom on its own
    // CSS exists (defined above) to scale size of zoomed image, class applied if smaller than certain size
    // If small enough, calculate scaling to 3 decimal places based on adjustment from forced size
    // Adjustment from 550 necessary to avoid empty space on zoom
    const [zoomScale, setZoomScale] = useState(1);
    const [errored, setErrored] = useState(false);

    function onLoad(e: SyntheticEvent<HTMLImageElement>) {
        if (!height) return;
        const element = e.target as HTMLImageElement;
        if (element.naturalHeight > height * 2) {
            setZoomScale(1);
            return;
        }
        const zs = toFixed((2 * height) / element.naturalHeight, 3);
        setZoomScale(zs);
    }
    function onError() {
        setErrored(true);
    }
    const imageClass = styles(`h-[${height ?? "0"}px]`, "rounded", "shadow");

    if (errored) return <TextOrLoading text="Error: Failed to Load" />;

    return (
        <InnerImageZoom
            src={url}
            hideHint={!height}
            height={height}
            imgAttributes={{ alt: desc, className: imageClass, onLoad, onError }}
            zoomType="hover"
            className={zoomScale !== 1 ? "small_zoom_image" : ""}
            zoomScale={zoomScale}
        />
    );
};
interface ZoomImageProps {
    url: string;
    height?: number;
    desc: string;
}

const IfMissing: FC<PropsWithChildren<{ item: any; msg: string }>> = ({ item, msg, children }) => {
    if (!item) return <TextOrLoading text={msg} />;
    return children;
};
