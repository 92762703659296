import { DocumentArrowUpIcon, FolderOpenIcon } from "@heroicons/react/24/outline";
import { type FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";

interface DropzoneProps {
    files: File[];
    setFiles: (files: File[]) => void;
    maxFiles: number;
    accept?: { [key: string]: string[] };
}

export const Dropzone: FC<DropzoneProps> = (props) => {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            props.setFiles(acceptedFiles);
        },
        [props],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: props.maxFiles,
        accept: props.accept,
    });

    return (
        <div className="grid grid-cols-1 gap-2">
            <div {...getRootProps()} className="rounded border border-gray-300 px-4 py-16 dark:border-gray-50">
                <input {...getInputProps()} />
                {isDragActive ? (
                    <div className="flex flex-col items-center justify-center">
                        <FolderOpenIcon className="w-16" />
                        <span>Drop the files here ...</span>
                    </div>
                ) : (
                    <div className="flex flex-col items-center justify-center">
                        <DocumentArrowUpIcon className="w-16" />
                        <span>Drag and drop your files here, or click to open file viewer</span>
                    </div>
                )}
            </div>
            <ul>
                {props.files?.map((file) => (
                    <li key={file.name}>
                        {file.name} - {file.size} B - {file.type}
                    </li>
                ))}
            </ul>
        </div>
    );
};
