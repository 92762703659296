import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type FC, type FormEvent, useState } from "react";

import { RepresentativesApi } from "../../api/representativesApi";
import { QueryKeys } from "../../helpers";
import { useReplaceVerbiage } from "../../hooks/verbiageHooks";
import { type RepresentativeDto } from "../../models";
import { Button, TextBox, ToggleButton, useErrorToast, useSuccessToast } from "../basic";
import { type SelectOption } from "../basic";
import { CampaignDropdown } from "../dropdowns/campaignDropdown";
import { ChannelsDropdown } from "../dropdowns/channelsDropdown";
import { ClientsDropdown } from "../dropdowns/clientsDropdown";
import { StatesDropdown } from "../dropdowns/statesDropdown";

interface State {
    repId: string;
    externalId: string;
    active: boolean;
    firstName: string;
    lastName: string;
    stateId: number | undefined;
    address1: string;
    address2: string;
    city: string;
    zip: string;
    remarks: string;
}

interface CreateUpdateRepresentativeProps {
    rep: RepresentativeDto;
    setOpen: (open: boolean) => void;
    create?: boolean;
}

export const CreateUpdateRepresentative: FC<CreateUpdateRepresentativeProps> = ({ rep, setOpen, create }) => {
    const [clientId, setClientId] = useState<number | undefined>(rep.clientId);
    const [channelId, setChannelId] = useState<number | undefined>(rep.channelId);
    const [campaignId, setCampaignId] = useState<number | undefined>(rep.campaignId);
    const [state, setState] = useState<State>({
        repId: rep.repId ?? "",
        externalId: rep.externalId ?? "",
        active: rep.active ?? false,
        firstName: rep.firstName ?? "",
        lastName: rep.lastName ?? "",
        stateId: rep.stateId,
        address1: rep.address1 ?? "",
        address2: rep.address2 ?? "",
        city: rep.city ?? "",
        zip: rep.zip ?? "",
        remarks: rep.remarks ?? "",
    });
    const queryClient = useQueryClient();
    const successToast = useSuccessToast();
    const errorToast = useErrorToast();
    const replaceVerbiage = useReplaceVerbiage();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        repMutation.mutate({
            ...state,
            id: rep.id,
            active: state.active,
            events: undefined,
            lastVerification: undefined,
            createdOn: new Date(),
            updatedOn: new Date(),
            clientId,
            channelId,
            campaignId,
        });
    };

    const repMutation = useMutation({
        mutationFn: RepresentativesApi.saveRepresentative,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.REPS] });
            successToast(
                create ? "Create Representative" : "Update Representative",
                create ? "Representative create successfully" : "Representative updated successfully",
            );
            setOpen(false);
        },
        onError: () => {
            errorToast(
                create ? "Create Representative" : "Update Representative",
                create ? "Representative create failed" : "Representative updated failed",
            );
        },
    });

    return (
        <form className="form-dialog" onSubmit={handleSubmit}>
            {!rep.channelId ? (
                <ChannelsDropdown
                    id="channelId"
                    value={channelId}
                    selectVal={(value: SelectOption | undefined) => {
                        setChannelId(value?.value ? (value.value as number) : undefined);
                    }}
                    required
                    chooseFirst={create}
                    isClearable
                />
            ) : null}
            {!rep.clientId ? (
                <ClientsDropdown
                    id="clientId"
                    value={clientId}
                    selectVal={(value: SelectOption | undefined) => {
                        setClientId(value?.value ? (value.value as number) : undefined);
                    }}
                    required
                    chooseFirst={create}
                    isClearable
                />
            ) : null}
            <StatesDropdown
                value={state.stateId}
                selectVal={(value: SelectOption | undefined) =>
                    setState({
                        ...state,
                        stateId: value?.value ? (value.value as number) : undefined,
                    })
                }
                required
                isClearable
            />
            <CampaignDropdown
                id="campaignId"
                value={campaignId}
                selectVal={(value: SelectOption | undefined) => {
                    setCampaignId(value?.value ? (value.value as number) : undefined);
                }}
                required
                chooseFirst={create}
                segment
                clientId={clientId}
                channelId={channelId}
                stateId={state.stateId}
                isClearable
            />
            <div className="grid grid-cols-2 gap-2">
                <TextBox
                    id="repId"
                    label={replaceVerbiage("Rep", "Rep ID")}
                    value={state.repId}
                    changeHandler={(value: string) => {
                        setState({
                            ...state,
                            repId: value,
                        });
                    }}
                    required
                    disabled={!!rep.lastVerification}
                />
                <TextBox
                    id="externalId"
                    label="External ID"
                    value={state.externalId}
                    changeHandler={(value: string) => setState({ ...state, externalId: value })}
                />
            </div>
            <div className="grid grid-cols-2 gap-2">
                <TextBox
                    id="firstName"
                    label="First Name"
                    value={state.firstName}
                    changeHandler={(value: string) => {
                        setState({
                            ...state,
                            firstName: value,
                        });
                    }}
                    required
                />
                <TextBox
                    id="lastName"
                    label="Last Name"
                    value={state.lastName}
                    changeHandler={(value: string) => {
                        setState({
                            ...state,
                            lastName: value,
                        });
                    }}
                    required
                />
            </div>
            <ToggleButton
                label="Active"
                values={[
                    { id: false, value: "Inactive" },
                    { id: true, value: "Active" },
                ]}
                value={state.active}
                changeHandler={(value: boolean) => {
                    setState({
                        ...state,
                        active: value,
                    });
                }}
                id="active"
            />
            <TextBox
                id="address1"
                label="Address Line 1"
                value={state.address1}
                changeHandler={(value: string) => {
                    setState({
                        ...state,
                        address1: value,
                    });
                }}
            />
            <TextBox
                id="address2"
                label="Address Line 2"
                value={state.address2}
                changeHandler={(value: string) => {
                    setState({
                        ...state,
                        address2: value,
                    });
                }}
            />
            <div className="grid grid-cols-2 gap-2">
                <TextBox
                    id="city"
                    label="City"
                    value={state.city}
                    changeHandler={(value: string) => {
                        setState({
                            ...state,
                            city: value,
                        });
                    }}
                />
                <TextBox
                    id="zip"
                    label="Zip"
                    value={state.zip}
                    changeHandler={(value: string) => {
                        setState({
                            ...state,
                            zip: value,
                        });
                    }}
                />
            </div>
            {!create ? (
                <TextBox
                    id="remarks"
                    label="Remarks"
                    value={state.remarks}
                    changeHandler={(value: string) => {
                        setState({
                            ...state,
                            remarks: value,
                        });
                    }}
                />
            ) : null}
            <div className="mt-4 grid">
                <div className="grid grid-cols-2 gap-2">
                    <Button
                        isLoading={repMutation.isPending}
                        type="button"
                        variant="secondary"
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button isLoading={repMutation.isPending} type="submit" variant="default">
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
};
