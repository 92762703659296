import { type FC } from "react";
import Barcode from "react-barcode";

interface BarcodeGeneratorProps {
    value: string;
}

export const BarcodeGenerator: FC<BarcodeGeneratorProps> = (props) => {
    return <Barcode value={props.value} background="" lineColor="#000" displayValue={false} />;
};
