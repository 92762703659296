import { type Row } from "@tanstack/react-table";
import { type ReactNode } from "react";

import { styles } from "../../helpers";
import { useColumnMapper } from "../../helpers/gridCustomization";
import { type AllGridFilters, type GridResult, type IFilter } from "../../models";
import { type Column, DataGrid } from "../basic";
import GridFilters from "../gridFilters";
import { type FilterGroups } from "../gridFilters/types";

interface GridPageProps<T, F extends Partial<AllGridFilters>> {
    // Title Props
    title?: string;
    actionButton?: ReactNode;

    // Filters Props
    updateFilter: (...filters: { name: string; value: any }[]) => void;
    filters: F;
    visibleFilters: FilterGroups[];

    // DataGrid Props
    queryKey: string;
    getData: (signal: AbortSignal) => Promise<GridResult<T> | undefined>;
    columns: Column<T>[];
    itemsPerPage: number;
    setItemsPerPage: (itemsPerPage: number) => void;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    sortFilter?: IFilter;
    setSortFilter?: (sortFilter: IFilter) => void;
    options?: (row: T) => ReactNode;
    rightOptions?: (row: T) => ReactNode;
    selectedActions?: (rows: T[]) => ReactNode;
    expandable?: boolean;

    /** If true, can pin rows to top, persist across pages */
    pinnable?: boolean;
    getRowId?: (row: T) => string;

    renderSubRow?: string;
    renderSubComponent?: (props: { row: Row<T> }) => ReactNode;
    getRowCanExpand?: (row?: Row<T>) => boolean;
    getRowClassNames?: (row: Row<T>) => string;

    pinnedIds?: string[];
    setPinnedIds?: (ids: string[]) => void;
}

export function GridPage<F extends Partial<AllGridFilters>, T extends object>(props: GridPageProps<T, F>) {
    const { title, actionButton, queryKey, ...passThruProps } = props;
    const mapColumnName = useColumnMapper(title ?? "no title");
    return (
        <div className="flex flex-col gap-2">
            <div className={styles(title ? "py-2" : "", "flex items-center justify-between")}>
                <h2 className="headline">{title}</h2>
                {actionButton}
            </div>
            <GridFilters<F> {...passThruProps} />
            <DataGrid<T>
                {...passThruProps}
                mapColumnName={mapColumnName}
                queryKey={[queryKey, props.filters, props.sortFilter, props.currentPage, props.itemsPerPage]}
                page={props.currentPage}
                setPage={props.setCurrentPage}
            />
        </div>
    );
}
