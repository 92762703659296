import { type Row, flexRender } from "@tanstack/react-table";
import { type ReactNode } from "react";

import { styles } from "../../../helpers";
import { type CustomTable } from "./dataGrid";

interface Props<T> {
    table: CustomTable<T>;
    options?: (row: T) => ReactNode;
    rightOptions?: (row: T) => ReactNode;
    renderSubComponent?: (props: { row: Row<T> }) => ReactNode;
}

export function DataGridTableBody<T>(props: Props<T>) {
    const { table } = props;

    return (
        <tbody className="divide-y divide-gray-200 bg-white dark:bg-gray-700">
            {renderPinnedRows()}
            {table.getCenterRows().map((row) => (
                <TableRow key={row.id} {...props} row={row} />
            ))}
        </tbody>
    );

    function renderPinnedRows() {
        if (!table.getTopRows().length) return null;

        const numColumns = table.getAllColumns().filter((c) => c.getIsVisible()).length;

        return (
            <>
                <tr className="text-left">
                    <th colSpan={numColumns} className="px-8 py-2 text-lg">
                        Pinned
                    </th>
                </tr>
                {table.getTopRows().map((row) => (
                    <TableRow key={row.id} {...props} row={row} />
                ))}
                <tr className="text-left">
                    <th colSpan={numColumns} className="px-8 py-2 text-lg">
                        Unpinned
                    </th>
                </tr>
            </>
        );
    }
}

interface RowProps<T> extends Props<T> {
    row: Row<T>;
}

function TableRow<T>(props: RowProps<T>) {
    const { table, row } = props;
    const { options, rightOptions, renderSubComponent } = props;
    return (
        <>
            <tr
                className={styles(
                    table?.options?.meta?.getRowClassNames ? table.options.meta.getRowClassNames(row) : "",
                    row.getIsPinned() ? "!bg-gray-100 dark:!bg-gray-700" : "",
                )}
            >
                {rightOptions ? <td>{rightOptions(row.original)}</td> : null}
                {row.getVisibleCells().map((cell) => {
                    return (
                        <td
                            key={cell.id}
                            className={styles(
                                "whitespace-nowrap px-2 py-4 text-left text-sm font-medium text-gray-900 dark:text-white",
                            )}
                        >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    );
                })}
                {options ? <td>{options(row.original)}</td> : null}
            </tr>
            {renderSubComponent && (
                <tr className={!row.getIsExpanded() ? "hidden" : ""}>
                    <td colSpan={row.getVisibleCells().length}>{renderSubComponent({ row })}</td>
                </tr>
            )}
        </>
    );
}
