import { doGet, doPost } from "./httpHandler";

export class HomeApi {
    public static getTitle = () => {
        return doGet<string>("/api", "GetTitle");
    };

    public static getBaseUrl = () => {
        return doGet<string>("/api", "GetBaseUrl");
    };

    public static getTempToken = (params: { scope: string }) => {
        return doPost<string, undefined>("/api", "GetTempToken", params);
    };
}
