import { useMemo } from "react";

import { type Position, type ReportingLocation, type VerificationDto } from "../../../models";
import { MapChart } from "../../basic";

interface Props {
    verification: VerificationDto;
    visible?: boolean;
}

export function SingleVerificationMap(props: Props) {
    const { verification } = props;

    const positions = useMemo(() => {
        const arr: ReportingLocation[] = [];

        addIfValid(verification.enrollmentPosition, "sale", verification.dateOfSale);
        addIfValid(verification.position, "tpv", verification.endDateTime ?? new Date(verification.position?.asOf));

        return arr;

        function addIfValid(pos: Position, name: string, date?: Date) {
            if (!isValid(pos)) return;
            if (!date) return;

            arr.push({
                datetime: date,
                repId: verification.repId,
                repName: name === "sale" ? "" : "TPV Location",
                lat: pos.lat,
                lon: pos.lon,
                tpvId: verification.id,
                status: verification.statusText,
                type: name === "sale" ? "userPin" : "flag",
                disposition: verification.dispositionName,
            });
        }
        function isValid(pos: Position) {
            return pos && pos.lat && pos.lon && true;
        }
    }, [verification]);

    if (!positions.length) return <h2>No Positions Found</h2>;

    return (
        <>
            <MapChart
                id={`verification-locations-${verification.id}`}
                title="Locations"
                data={positions ?? []}
                type="userPin"
            />
        </>
    );
}
