import { type FC, type ReactNode, createContext, useContext, useState } from "react";

import { TOAST_TIMEOUT } from "../../../helpers";
import { Toast } from "./toast";

export interface IToast {
    type: "success" | "error" | "warning" | "info";
    title: string;
    message: string;
    timeout?: number;
}

interface ToastContextType {
    toast: IToast | undefined;
    setToast: (toast: IToast | undefined) => void;
}

export const ToastContext = createContext<ToastContextType>({
    toast: undefined,
    setToast: () => undefined,
});

interface ToastProviderProps {
    children: ReactNode;
}

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
    const [toast, setToast] = useState<IToast | undefined>(undefined);

    return (
        <ToastContext.Provider value={{ toast, setToast }}>
            {children}
            <Toast />
        </ToastContext.Provider>
    );
};

export const useSuccessToast = () => {
    const { setToast } = useContext(ToastContext);

    return (title: string, message: string) => {
        setToast({
            type: "success",
            title,
            message,
            timeout: TOAST_TIMEOUT,
        });
    };
};

export const useErrorToast = () => {
    const { setToast } = useContext(ToastContext);

    return (title: string, message: string) => {
        setToast({
            type: "error",
            title,
            message,
            timeout: TOAST_TIMEOUT,
        });
    };
};
