import { useQuery } from "@tanstack/react-query";
import { type FC, useMemo } from "react";

import { ReportingApi } from "../../../api/reportingApi";
import { QueryKeys, toStartCase } from "../../../helpers";
import { type ReportingFilter } from "../../../models";
import { Column, DataGridClient } from "../../basic";

interface Props {
    filterData: ReportingFilter;
}

interface ResponseCount {
    name: string;
    [key: string]: string;
}

async function getResposeCounts(filterData: ReportingFilter): Promise<ResponseCount[]> {
    const counts = await ReportingApi.getSelfGeneratedResponseCounts(filterData);
    const mapped: ResponseCount[] = [];

    counts.forEach((c) => {
        const row: ResponseCount = { name: c.theme };
        c.responses.forEach((r) => {
            row[r.value] = r.count.toString();
        });
        mapped.push(row);
    });

    return mapped;
}

function mapColumns(data?: ResponseCount[]) {
    const mapped: Column<ResponseCount>[] = [];
    const keys = new Set<string>();

    data?.forEach((d) => {
        Object.keys(d).forEach((k) => {
            if (!keys.has(k)) {
                keys.add(k);
                mapped.push(new Column(toStartCase(k), k));
            }
        });
    });

    return mapped;
}

export const GeneratedResponseCountTable: FC<Props> = ({ filterData }) => {
    const query = useQuery({
        queryKey: [QueryKeys.RESPONSE_COUNTS, filterData],
        queryFn: () => getResposeCounts(filterData),
    });

    const columns = useMemo(() => mapColumns(query.data), [query.data]);

    if (!query.data) return null;

    return (
        <DataGridClient
            title="Generated Responses"
            data={query.data ?? []}
            columns={columns}
            loading={query.isLoading}
            pageSizeOptions={[5]}
        />
    );
};
