import { useMutation } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { type FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserApi } from "../../api/loginApi";
import { Button, Link, TextBox, useErrorToast } from "../../components/basic";
import { RouteValues, clearPayloadCookie } from "../../helpers";

interface State {
    email: string;
}

export default function ForgotPassword() {
    const [state, setState] = useState<State>({
        email: "",
    });
    const errorToast = useErrorToast();
    const navigate = useNavigate();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        forgotPasswordMutation.mutate({
            email: state.email,
        });
    };

    const forgotPasswordMutation = useMutation({
        mutationFn: UserApi.forgotPassword,
        onSuccess: () => {
            clearPayloadCookie();
            navigate("/", { replace: true });
        },
        onError: (e: AxiosError) => {
            errorToast("Forgot Password Error", e.message);
        },
    });

    return (
        <div className="flex h-screen w-screen flex-col items-center justify-center">
            <img src="/images/logo.png" alt="AVL Logo" className="w-96 dark:hidden" />
            <img src="/images/logo-white.png" alt="AVL Logo" className="hidden w-96 dark:block" />
            <form className="card w-full sm:max-w-xl" onSubmit={handleSubmit}>
                <TextBox
                    id="forgot-password-email"
                    label="Email"
                    type="email"
                    autoComplete="email"
                    value={state.email}
                    changeHandler={(value: string) => setState({ ...state, email: value })}
                />
                <div className="my-4">
                    <span className="text-xs text-gray-600 dark:text-gray-200">
                        A link will be sent allowing you to reset your password.
                    </span>
                </div>
                <Button isLoading={forgotPasswordMutation.isPending} variant="default" className="w-full" type="submit">
                    Send password reset email
                </Button>
            </form>
            <div className="my-2 flex w-full flex-col rounded border p-2 text-center sm:max-w-xl">
                <span>Remember your password now?</span>
                <Link to={`/${RouteValues.LOGIN}`}>Log In</Link>
            </div>
        </div>
    );
}
