import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { type FC, Fragment, type ReactNode } from "react";

import { Button } from "../button";

interface DialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    title?: ReactNode;
    body?: ReactNode;
    actions?: ReactNode;
    closeOnBackdropClick?: boolean;
    closeIcon?: boolean;
}

export const Dialog: FC<DialogProps> = ({ open, setOpen, title, body, actions, closeOnBackdropClick, closeIcon }) => {
    return (
        <Transition.Root show={open} as={Fragment}>
            <HeadlessDialog
                as="div"
                className="relative z-50"
                onClose={closeOnBackdropClick ? () => setOpen(false) : () => undefined}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <HeadlessDialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all dark:bg-gray-700">
                                {closeIcon ? (
                                    <div className="flex w-full justify-end">
                                        <Button variant="ghost" size="icon" onClick={() => setOpen(false)}>
                                            <XMarkIcon className="w-4" />
                                        </Button>
                                    </div>
                                ) : null}
                                <div className="px-6">
                                    <div className="mt-3 text-center sm:mt-5">
                                        <HeadlessDialog.Title as="h3" className="text-2xl">
                                            {title}
                                        </HeadlessDialog.Title>

                                        <div className="mt-2">{body}</div>
                                    </div>
                                </div>
                                <div className="mt-5 grid grid-cols-2 gap-2">{actions}</div>
                            </HeadlessDialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </HeadlessDialog>
        </Transition.Root>
    );
};
