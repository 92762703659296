import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import { ReportingApi } from "../../../api/reportingApi";
import { QueryKeys } from "../../../helpers";
import { type ReportingFilter } from "../../../models";
import { ChartSkeleton } from "../../basic";
import { SplineChart } from "../../basic";

export interface Props {
    filterData: ReportingFilter;
}

export function PercentTPV(props: Props) {
    const { filterData } = props;
    const [axis, setAxis] = useState<string[]>([]);
    const [series, setSeries] = useState<{ name: string; data: number[] }[]>([]);

    const reportQuery = useQuery({
        queryKey: [QueryKeys.CAMPAIGN_REPORTS, filterData],
        queryFn: () => {
            return ReportingApi.getCampaignReportData(filterData);
        },
    });

    useEffect(() => {
        if (!reportQuery.data?.successData) return;

        const map = new Map<string, number[]>();
        map.set("GPS Percent", Array(reportQuery.data.successData.length).fill(0) as number[]);
        map.set("Known Voter", Array(reportQuery.data.successData.length).fill(0) as number[]);

        reportQuery.data.successData.forEach((item, index) => {
            const known = map.get("Known Voter");
            const gps = map.get("GPS Percent");

            if (known) known[index]! += item.voterIdPct * 100;
            if (gps) gps[index]! += item.gpsPct * 100;
        });

        setAxis(reportQuery.data.successData.map((item) => format(item.date, "yyyy-MM-dd")));
        setSeries(Array.from(map.entries()).map(([name, count]) => ({ name, data: count })));
    }, [reportQuery.data?.successData]);

    if (reportQuery.isLoading) return <ChartSkeleton />;

    if (!reportQuery.data) return null;
    if (!reportQuery.data.successData || reportQuery.data.successData.length === 0) return null;

    return <SplineChart id="percent-completed-tpv-spline" title="% of Completed TPV" axis={axis} data={series} />;
}
