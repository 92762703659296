import { endOfDay, startOfDay, startOfMonth, startOfWeek, startOfYear } from "date-fns";
import { type DateRange } from "react-day-picker";

import { type IDateFilter } from "../../../models";
import { Button, DateRangePicker } from "../../basic";
import { type DateFilterProps } from "../types";

interface Props extends DateFilterProps {
    label?: string;
    startKey?: keyof IDateFilter;
    endKey?: keyof IDateFilter;
}

export default function DateFilters(props: Props) {
    const { label = "Date Range", startKey = "startDate", endKey = "endDate", visible, filters, changeHandler } = props;

    function setDates(startDate?: Date, endDate?: Date) {
        changeHandler(startDate && startOfDay(startDate), startKey as string);
        changeHandler(endDate && endOfDay(endDate), endKey as string);
    }

    function setDate(date: DateRange | undefined) {
        setDates(date?.from, date?.to);
    }

    function setToday() {
        const today = new Date();
        setDates(today, today);
    }

    function setWeek() {
        const startDate = startOfWeek(new Date());
        const today = new Date();
        setDates(startDate, today);
    }

    function setMtd() {
        const startDate = startOfMonth(new Date());
        const endDate = new Date();
        setDates(startDate, endDate);
    }

    function setYtd() {
        const startDate = startOfYear(new Date());
        const endDate = new Date();
        setDates(startDate, endDate);
    }

    function setAllTime() {
        setDates(undefined, undefined);
    }

    if (!visible) return null;

    const from = filters[startKey] as Date;
    const to = filters[endKey] as Date;

    return (
        <div className="row-span-2">
            <DateRangePicker label={label} date={{ from, to }} setDate={setDate} />
            <div className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-5">
                <Button variant="secondary" onClick={setToday}>
                    Today
                </Button>
                <Button variant="secondary" onClick={setWeek}>
                    Week
                </Button>
                <Button variant="secondary" onClick={setMtd}>
                    Month
                </Button>
                <Button variant="secondary" onClick={setYtd}>
                    Year
                </Button>
                <Button variant="secondary" onClick={setAllTime}>
                    All
                </Button>
            </div>
        </div>
    );
}
