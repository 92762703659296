import { type FC, type ReactNode } from "react";

import { styles } from "../../../helpers";

interface Props {
    children: ReactNode[];
    className?: string;
}

export const TabsHeader: FC<Props> = ({ children, className }) => {
    return (
        <div className="border-b border-gray-200">
            <nav className={styles("-mb-px flex", className)} aria-label="Tabs">
                {children}
            </nav>
        </div>
    );
};
