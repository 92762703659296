import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsAccessibility from "highcharts/modules/accessibility";
import { type FC, useEffect, useRef, useState } from "react";

import "./charts.scss";
import { NoData } from "./noData";

interface SplineChartProps extends HighchartsReact.Props {
    id: string;
    title: string;
    axis: string[];
    data: { name: string; data: number[] }[];
}

highchartsAccessibility(Highcharts);

export const SplineChart: FC<SplineChartProps> = (props) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const [options, setOptions] = useState<Highcharts.Options>();

    useEffect(() => {
        if (props.data) {
            setOptions({
                chart: {
                    styledMode: true,
                },
                credits: {
                    enabled: false,
                },
                title: {
                    text: props.title,
                    align: "left",
                },
                accessibility: {
                    point: {
                        valueSuffix: "%",
                    },
                },
                xAxis: {
                    categories: props.axis,
                },
                yAxis: {
                    labels: {
                        format: "{value}%",
                    },
                    title: {
                        text: "Percent",
                    },
                },
                tooltip: {
                    formatter: function () {
                        return `${this.series.name}: ${this.point.y?.toFixed(0) ?? "0"}%`;
                    },
                },
                plotOptions: {
                    area: {
                        marker: {
                            enabled: false,
                            symbol: "circle",
                            radius: 2,
                            states: {
                                hover: {
                                    enabled: true,
                                },
                            },
                        },
                    },
                },
                series: props.data.map((item) => ({
                    type: "spline",
                    name: item.name,
                    data: item.data,
                    accessibility: {
                        enabled: true,
                    },
                    tooltip: {
                        valueSuffix: "%",
                    },
                })),
            });
        }
    }, [props.axis, props.data, props.title]);

    if (!options) return null;

    if (props.data.length === 0) return <NoData title={props.title} />;

    return (
        <div className="card">
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
        </div>
    );
};
