import { type Row } from "@tanstack/react-table";
import { type FC } from "react";

import { type UserDto } from "../../../models";
import { UserWidgetEventGrid } from "./userWidgetEventGrid";

interface GridProps {
    row: Row<UserDto>;
}

export const UserGridDetail: FC<GridProps> = ({ row }) => {
    const expanded = row.getIsExpanded();

    if (!expanded) return null;

    return <UserWidgetEventGrid user={row.original} />;
};
