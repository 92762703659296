import axios, { type AxiosError, AxiosHeaders, type AxiosRequestConfig, type AxiosResponse } from "axios";
import { parseISO } from "date-fns";

export interface Params {
    [key: string]: string | number | boolean | undefined;
}

export interface HttpError {
    status: number;
    message: string;
}

export async function handlePost<TOut, TIn>(url: string, params?: Params, data?: TIn, config?: AxiosRequestConfig) {
    try {
        const response = await getApi().post<TOut>(url, data, { params, ...config });
        return response && response.data;
    } catch (error: unknown) {
        throw handleError(error as AxiosError);
    }
}

export async function handlePut<TOut, TIn>(url: string, params?: Params, data?: TIn, config?: AxiosRequestConfig) {
    try {
        const response = await getApi().put<TOut>(url, data, { params, ...config });
        return response && response.data;
    } catch (error: unknown) {
        throw handleError(error as AxiosError);
    }
}

export async function handleGet<TOut>(url: string, params?: Params, config?: AxiosRequestConfig) {
    try {
        const response = await getApi().get<TOut>(url, { params, ...config });
        return response && response.data;
    } catch (error: unknown) {
        throw handleError(error as AxiosError);
    }
}

export async function handleDelete<TOut>(url: string, id: number) {
    try {
        const response = await getApi().delete<TOut>(`${url}/${id}`);
        return response && response.data;
    } catch (error: unknown) {
        throw handleError(error as AxiosError);
    }
}

export async function handleDownload(url: string, fileName: string, params?: Params, config?: AxiosRequestConfig) {
    try {
        const response = await getApi().get<File>(url, { params, ...config });
        createDownload(response.data, fileName);
    } catch (error: unknown) {
        throw handleError(error as AxiosError);
    }
}

export async function handleDownloadPost<TIn>(
    url: string,
    fileName: string,
    params?: Params,
    data?: TIn,
    config?: AxiosRequestConfig,
) {
    try {
        const response = await getApi().post<TIn, File>(url, data, { params, ...config });
        createDownload(response, fileName);
    } catch (error: unknown) {
        throw handleError(error as AxiosError);
    }
}

function createDownload(file: File, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

function handleError(error: AxiosError<any>) {
    error = error || ({} as AxiosError<any>);
    const resp = error.response || ({} as AxiosResponse<any>);
    return {
        status: resp.status,
        message: (resp.data || error.message) as string,
    } as HttpError;
}

function getApi(config?: AxiosRequestConfig) {
    config = config ?? ({ headers: new AxiosHeaders() } as AxiosRequestConfig);
    const client = axios.create({ ...config, withCredentials: true });

    client.interceptors.response.use((originalResponse) => {
        handleDates(originalResponse.data);
        return originalResponse;
    });

    return client;
}

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString<T>(value: T): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value);
}

function handleDates<T>(body: T) {
    if (body === null || body === undefined || typeof body !== "object") return;

    for (const key of Object.keys(body)) {
        const value = body[key as keyof T];
        if (isIsoDateString(value)) {
            // @ts-ignore
            body[key] = parseISO(value);
        } else if (typeof value === "object") handleDates(value);
    }
}
