import { Severity, logger } from "../helpers";
import { type CampaignDto, type GridResult, type VerificationFilter } from "../models";
import { doPost } from "./httpHandler";

const controller = "/api/Campaign";

export class CampaignApi {
    public static getCampaigns = (
        signal: AbortSignal,
        itemsPerPage = 10,
        currentPage = 1,
        filters?: Partial<VerificationFilter>,
    ) => {
        try {
            return doPost<GridResult<CampaignDto>, Partial<VerificationFilter>>(
                controller,
                "GetAll",
                { itemsPerPage, currentPage },
                filters,
                { signal },
            );
        } catch (e) {
            logger(Severity.Error, e);
        }
    };
}
