import { useMutation, useQueryClient } from "@tanstack/react-query";

import { UsersApi } from "../../api/usersApi";
import { QueryKeys } from "../../helpers";
import { useErrorToast, useSuccessToast } from "../basic";

export const useToggleUsers = () => {
    const queryClient = useQueryClient();
    const successToast = useSuccessToast();
    const errorToast = useErrorToast();

    const toggleMutation = useMutation({
        mutationKey: [QueryKeys.USERS_TOGGLE_MUTATION],
        mutationFn: UsersApi.toggle,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.USERS] });
            successToast("Success", "Users updated successfully");
        },
        onError: () => {
            errorToast("Error", "Users update failed");
        },
    });

    return (ids: number[], toggleTo: boolean) => {
        toggleMutation.mutate({ ids, activate: toggleTo });
    };
};
