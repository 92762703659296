import { type FC } from "react";

export const TextSkeleton: FC = () => {
    return (
        <>
            <div>
                <div className="h-6 w-24 animate-pulse rounded-xl bg-gray-300 dark:bg-gray-700" />
                <span className="sr-only">loading</span>
            </div>
        </>
    );
};
