import GridFilters from "../components/gridFilters";
import { FilterGroups } from "../components/gridFilters/types";
import useRealTimeData from "../components/reporting/realtime/data";
import { RealtimeAgentLeaderboard } from "../components/reporting/realtime/grid";
import { RealTimeGraphs } from "../components/reporting/realtime/realtime";
import { usePropReducer } from "../helpers";
import { type ReportingFilter } from "../models";

const initialState: ReportingFilter = {
    campaigns: [],
    channels: [],
    clients: [],
    endDate: undefined,
    language: undefined,
    representativeId: undefined,
    startDate: undefined,
    states: [],
    verMode: undefined,
    verType: undefined,
    startTime: undefined,
    endTime: undefined,
    location: undefined,
    locationWithinFeet: undefined,
    includesDataEndDate: undefined,
    includesDataStartDate: undefined,
};

export default function RealTime() {
    const [filterData, dispatchFilterData] = usePropReducer(initialState);
    const { data, refresh, loadingSignalR, loading } = useRealTimeData(filterData);

    if (loadingSignalR) return null;
    if (loading) return null;

    return (
        <div>
            <GridFilters<ReportingFilter>
                updateFilter={dispatchFilterData}
                filters={filterData}
                visibleFilters={[
                    FilterGroups.Segment,
                    FilterGroups.Campaign,
                    FilterGroups.Verification,
                    FilterGroups.Language,
                ]}
            />
            <RealTimeGraphs data={data} loading={loading} />
            <RealtimeAgentLeaderboard data={data} update={refresh} loading={loading} loadingSignalR={loadingSignalR} />
        </div>
    );
}
