import { Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { type FC, Fragment, useCallback, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { usePopper } from "react-popper";

import { Severity, logger } from "../../../helpers";

interface Action {
    name: string;
    onClick: () => void;
}

interface DataGridOptionsProps {
    actions: Action[];
}

export const DataGridOptions: FC<DataGridOptionsProps> = ({ actions }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, { placement: "bottom-end" });

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const openMenu = useCallback(() => {
        setOpen(!open);
        if (update) {
            update().catch((error) => {
                logger(Severity.Error, error);
            });
        }
    }, [open, setOpen, update]);

    if (actions.length === 0) return null;

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                <div className="flex justify-end">
                    <button aria-label="open menu" type="button" ref={setReferenceElement} onClick={openMenu}>
                        <EllipsisVerticalIcon className="w-6" />
                    </button>
                </div>
                <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="z-50">
                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="z-50 w-28 rounded bg-white shadow-2xl dark:bg-gray-700">
                            {actions.map(renderAction)}
                        </div>
                    </Transition>
                </div>
            </div>
        </ClickAwayListener>
    );

    function renderAction(action: Action) {
        return (
            <button
                key={action.name}
                onClick={action.onClick}
                className="block w-full px-4 py-2 text-left text-sm leading-6 hover:bg-gray-200 dark:hover:bg-gray-600"
            >
                {action.name}
            </button>
        );
    }
};
