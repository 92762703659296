import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type FC, type FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AuditApi } from "../../api/auditApi";
import { QueryKeys, RouteValues } from "../../helpers";
import { Button, type SelectOption, useErrorToast } from "../basic";
import { CampaignDropdown } from "../dropdowns/campaignDropdown";

interface State {
    campaignId: number | undefined;
}

interface CreateAuditProps {
    setOpenCreateDialog: (open: boolean) => void;
}

export const CreateAudit: FC<CreateAuditProps> = ({ setOpenCreateDialog }) => {
    const [state, setState] = useState<State>({
        campaignId: undefined,
    });
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const errorToast = useErrorToast();

    const formMutation = useMutation({
        mutationFn: AuditApi.createBatch,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.PREVIOUS_AUDIT] });
            navigate(`/${RouteValues.AUDIT}/${data.id}`);
        },
        onError: () => {
            errorToast("Start Audit", "Starting a new audit has failed");
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!state.campaignId) return;

        formMutation.mutate({
            campaignId: state.campaignId,
        });
    };

    return (
        <form className="form-dialog" onSubmit={handleSubmit}>
            <CampaignDropdown
                value={state.campaignId}
                selectVal={(value: SelectOption) => setState({ ...state, campaignId: value.value as number })}
                required
                chooseFirst
            />
            <div className="mt-4 grid">
                <div className="grid grid-cols-2 gap-2">
                    <Button
                        isLoading={formMutation.isPending}
                        type="button"
                        variant="secondary"
                        onClick={() => setOpenCreateDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button isLoading={formMutation.isPending} type="submit" variant="default">
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
};
