import axios from "axios";

import { Severity, logger } from "../helpers";
import { type VersionDto } from "../models";

const controller = "/api/ping";

export class PingApi {
    public static version = async () => {
        try {
            const response = await axios.get(`${controller}/Version`);

            return response.data as VersionDto;
        } catch (e) {
            logger(Severity.Error, e);
        }
    };
}
