import { decodeBase64 } from "./base64";

export function getCookieValue(name: string) {
    const matchingRegex = /([\w_-]+) ?= ?(.*?)(;|$)/g;
    const matches = document.cookie.matchAll(matchingRegex);
    if (matches === null) return null;
    for (const match of matches) {
        if (match.length < 3) continue;
        if (match[1] === name) {
            return match[2];
        }
    }
    return null;
}

export function getPayloadCookie() {
    return getCookieValue(cookieName);
}

export function getPayloadCookieAsType<T = object>() {
    const cookie = getPayloadCookie();
    if (!cookie) return null;
    return JSON.parse(decodeBase64(cookie).toString()) as T;
}

export function clearPayloadCookie() {
    setCookie(cookieName, "", -1);
}

export function setPayloadCookie(value: string) {
    setCookie(cookieName, value, 168);
}

export function setCookie(name: string, value: string, hours: number) {
    const seconds = 60 * 60 * hours;
    document.cookie = `${name}=${value}; path=/; samesite=strict; max-age=${seconds}`;
}

let cookieName = "payload";

export function setCookieName(name: string) {
    cookieName = name;
}
