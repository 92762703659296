import { type AllGridFilters } from "../../models";
import { type SelectOption } from "../basic";
import ActiveFilter from "./filters/activeFilter";
import CampaignFilter from "./filters/campaignFilter";
import DateFilters from "./filters/dateFilters";
import DispositionFilter from "./filters/dispositionFilter";
import LanguageFilter from "./filters/languageFilter";
import AddressFilter from "./filters/mapFilter";
import RepFilter from "./filters/repFilter";
import SearchFilter from "./filters/searchFilter";
import SearchItemFilter from "./filters/searchItemFilter";
import SegmentFilter from "./filters/segmentFilters";
import TimeFilters from "./filters/timeFilters";
import VerificationFilters from "./filters/verificationFilters";
import type Props from "./types";
import { FilterGroups } from "./types";

export default function GridFilters<T extends Partial<AllGridFilters>>(props: Props<T>) {
    const { filters, visibleFilters, updateFilter } = props;

    function selectVal(value?: SelectOption | SelectOption[], id?: string) {
        if (!id) return;

        if (Array.isArray(value)) {
            updateFilter({ name: id, value: value.map((v) => v.value) });
        } else {
            updateFilter({ name: id, value: value?.value });
        }
    }

    function changeHandler(value: string | undefined, name: string) {
        updateFilter({ name, value });
    }

    const filterInfo = { filters, updateFilter, selectVal, changeHandler };

    return (
        <div className="card">
            <div className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                <SegmentFilter {...filterInfo} visible={visibleFilters.includes(FilterGroups.Segment)} />
                <CampaignFilter {...filterInfo} visible={visibleFilters.includes(FilterGroups.Campaign)} />
                <LanguageFilter {...filterInfo} visible={visibleFilters.includes(FilterGroups.Language)} />
                <RepFilter {...filterInfo} visible={visibleFilters.includes(FilterGroups.Rep)} />
                <ActiveFilter {...filterInfo} visible={visibleFilters.includes(FilterGroups.Active)} />
                <VerificationFilters {...filterInfo} visible={visibleFilters.includes(FilterGroups.Verification)} />
                <DispositionFilter {...filterInfo} visible={visibleFilters.includes(FilterGroups.Disposition)} />
                <SearchFilter {...filterInfo} visible={visibleFilters.includes(FilterGroups.Search)} />
                <SearchItemFilter {...filterInfo} visible={visibleFilters.includes(FilterGroups.SearchItems)} />
                <DateFilters
                    startKey="startDate"
                    endKey="endDate"
                    {...filterInfo}
                    visible={visibleFilters.includes(FilterGroups.Date)}
                />
                <DateFilters
                    label="Export Date Range"
                    startKey="includesDataStartDate"
                    endKey="includesDataEndDate"
                    {...filterInfo}
                    visible={visibleFilters.includes(FilterGroups.DataPeriod)}
                />
                <TimeFilters {...filterInfo} visible={visibleFilters.includes(FilterGroups.Time)} />
                <AddressFilter {...filterInfo} visible={visibleFilters.includes(FilterGroups.Location)} />
            </div>
        </div>
    );
}
