import { useMediaQuery } from "react-responsive";
// This will use the default theme from tailwindcss. If we update the breakpoints in tailwind.config.js, we will need to update this file as well.
import defaultTheme from "tailwindcss/defaultTheme";

const breakpoints = defaultTheme.screens;

type BreakpointKey = keyof typeof breakpoints;

export function useBreakpoint<K extends BreakpointKey>(breakpointKey: K) {
    const bool = !useMediaQuery({
        query: `(min-width: ${breakpoints[breakpointKey]})`,
    });
    const capitalizedKey = `${breakpointKey?.at(0)?.toUpperCase() ?? ""}${breakpointKey.substring(1)}`;
    type Key = `is${Capitalize<K>}`;
    return {
        [`is${capitalizedKey}`]: bool,
    } as Record<Key, boolean>;
}
