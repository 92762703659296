import { type AxiosRequestConfig } from "axios";

import {
    type Params,
    handleDelete,
    handleDownload,
    handleDownloadPost,
    handleGet,
    handlePost,
    handlePut,
} from "./httpHandlerBase";

export async function doPost<TOut, TIn>(
    controller: string,
    action: string,
    params?: Params,
    data?: TIn,
    config?: AxiosRequestConfig,
) {
    return await handlePost<TOut, TIn>(buildUrl(controller, action), params, data, config);
}

export async function doPut<TOut, TIn>(
    controller: string,
    action: string,
    params?: Params,
    data?: TIn,
    config?: AxiosRequestConfig,
) {
    return await handlePut<TOut, TIn>(buildUrl(controller, action), params, data, config);
}

export async function doGet<TOut>(controller: string, action: string, params?: Params, config?: AxiosRequestConfig) {
    return await handleGet<TOut>(buildUrl(controller, action), params, config);
}

export async function doDelete<TOut>(controller: string, action: string, id: number) {
    return await handleDelete<TOut>(buildUrl(controller, action), id);
}

export async function doDownload(
    controller: string,
    action: string,
    fileName: string,
    params?: Params,
    config?: AxiosRequestConfig,
) {
    return await handleDownload(buildUrl(controller, action), fileName, params, config);
}

export async function doDownloadPost<TIn>(
    controller: string,
    action: string,
    fileName: string,
    params?: Params,
    data?: TIn,
    config?: AxiosRequestConfig,
) {
    return await handleDownloadPost<TIn>(buildUrl(controller, action), fileName, params, data, config);
}

export function buildUrl(controller: string, action: string) {
    return controller + (action ? `/${action}` : "");
}
