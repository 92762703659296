import { type FilterDto } from "../models";
import { doGet, doPost } from "./httpHandler";

const controller = "/api/gridfilters";

export class GridFiltersApi {
    static async getFunction(
        action: string,
        all?: boolean,
        ids?: number[],
    ): Promise<{ value: number; label: string }[]> {
        const id = ids ? ids.join(",") : undefined;
        const filters = await doGet<FilterDto[]>(controller, action, { all, id });

        return filters.map((item) => ({ value: item.id, label: item.name }));
    }

    public static getClients = () => GridFiltersApi.getFunction("GetClients");
    public static getChannels = () => GridFiltersApi.getFunction("GetChannels");
    public static getStates = (all?: boolean) => GridFiltersApi.getFunction("GetUSStates", all);
    public static getStateAbbreviations = async (all?: boolean) => {
        const filters = await doGet<FilterDto[]>(controller, "GetUSStates", { all });

        return filters.map((item) => ({ value: item.id, label: item.code }));
    };
    public static getVerTypes = () => GridFiltersApi.getFunction("GetVerTypes");
    public static getVerModes = () => GridFiltersApi.getFunction("GetVerModes");
    public static getLanguages = () => GridFiltersApi.getFunction("GetLanguages");
    public static getCampaigns = () => GridFiltersApi.getFunction("GetCampaigns");
    public static getCampaignsBySegment = async (
        clientId: number | undefined,
        channelId: number | undefined,
        stateId: number | undefined,
    ) => {
        const filters = await doGet<FilterDto[]>(controller, "GetCampaignsBySegment", { clientId, channelId, stateId });
        return filters.map((item) => ({ value: item.id, label: item.name }));
    };
    public static getDistricts = () => GridFiltersApi.getFunction("GetDistricts");
    public static getFormTemplates = (id: number) => GridFiltersApi.getFunction("GetFormTemplates", undefined, [id]);
    public static getRepresentatives = async (ids?: number[]) => {
        const body = {
            campaigns: ids ?? [],
        };

        const filters = await doPost<FilterDto[], { campaigns: number[] }>(
            controller,
            "GetRepresentatives",
            undefined,
            body,
        );

        return filters.map((item) => ({ value: item.id, label: item.name }));
    };
}
