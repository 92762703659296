import { VerModesDropdown } from "../../dropdowns/verModesDropdown";
import { VerTypesDropdown } from "../../dropdowns/verTypesDropdown";
import { type VerificationFilterProps } from "../types";

export default function VerificationFilters(props: VerificationFilterProps) {
    const { visible, filters, selectVal } = props;

    if (!visible) return null;

    return (
        <>
            <VerTypesDropdown selectVal={selectVal} value={filters.verType} autoHide isClearable />
            <VerModesDropdown selectVal={selectVal} value={filters.verMode} autoHide isClearable />
        </>
    );
}
