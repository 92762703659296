import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { type FC } from "react";

import { type AuditScanResponseDto } from "../../models";
import { Button } from "../basic";
import { useChangeLineMutation } from "./auditHooks";
import { AuditPanels } from "./auditPanels";
import { AuditQuestionnaire } from "./auditQuestionnaire";
import { MissingTpv } from "./missingTpv";

interface MultiAuditProps {
    batchId: number;
    results: AuditScanResponseDto | undefined;
    barcode: string;
    setResults: (results: AuditScanResponseDto | undefined) => void;
    setBarcode: (barcode: string) => void;
}

export const MultiAudit: FC<MultiAuditProps> = ({ batchId, results, barcode, setBarcode, setResults }) => {
    const changeLineMutation = useChangeLineMutation(batchId, setResults);

    if (!results) return null;

    return (
        <div>
            <div className="flex flex-col">
                <div className="flex items-center justify-between">
                    <div className="m-4 flex items-center justify-between">
                        <Button
                            variant="outline"
                            size="icon"
                            hidden={!results?.scanLine?.previousLineGuid}
                            onClick={() => changeLineMutation.mutate(results?.scanLine?.previousLineGuid)}
                        >
                            <ChevronLeftIcon className="w-8 text-indigo-400 dark:text-white" />
                        </Button>
                    </div>
                    <span className="headline">
                        {results?.scanLine?.lineNumber} of {results?.scanLine?.totalOnPage} signatures
                    </span>
                    <div className="m-4 flex items-center justify-between">
                        <Button
                            variant="outline"
                            size="icon"
                            hidden={
                                !results?.scanLine?.nextLineGuid ||
                                results.scanLine.lineNumber > results.scanLine.auditedOnPage
                            }
                            onClick={() => changeLineMutation.mutate(results?.scanLine?.nextLineGuid)}
                        >
                            <ChevronRightIcon className="w-8 text-indigo-400 dark:text-white" />
                        </Button>
                    </div>
                </div>
                <div>
                    <MissingTpv results={results} />
                    <AuditPanels results={results} />
                    <AuditQuestionnaire
                        key={results.scanLine?.lineGuid}
                        results={results}
                        barcode={barcode}
                        batchId={batchId}
                        setBarcode={setBarcode}
                        setResults={setResults}
                        multi
                    />
                </div>
            </div>
        </div>
    );
};
