import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import printJS from "print-js";
import { type FC, type FormEvent, useState } from "react";

import { FormApi } from "../../api/formApi";
import { QueryKeys, sleep } from "../../helpers";
import { type FormPrintBatchDto } from "../../models";
import { Button, type SelectOption, TextBox, useErrorToast } from "../basic";
import { CampaignDropdown } from "../dropdowns/campaignDropdown";
import { FormTemplatesDropdown } from "../dropdowns/formTemplatesDropdown";
import { RepsDropdown } from "../dropdowns/repsDropdown";

interface State {
    campaignId: number | undefined;
    representativeId: string;
    formTemplateId: string;
    size: string;
}

interface CreateUpdateFormProps {
    printForm: Partial<FormPrintBatchDto>;
    setOpen: (open: boolean) => void;
    create: boolean;
}

export const CreatePrintFormBatch: FC<CreateUpdateFormProps> = ({ printForm, setOpen, create }) => {
    const [state, setState] = useState<State>({
        campaignId: printForm.campaignId,
        representativeId: printForm.representativeId?.toString() ?? "",
        formTemplateId: printForm.formTemplateId?.toString() ?? "",
        size: printForm.size?.toString() ?? "",
    });
    const queryClient = useQueryClient();
    const errorToast = useErrorToast();

    const printMutation = useMutation({
        mutationFn: async () => {
            handlePrint({
                representativeId: parseInt(state.representativeId),
                formTemplateId: parseInt(state.formTemplateId),
                size: parseInt(state.size),
            });
            await sleep(1000);
        },
        onSuccess: () => {
            setOpen(false);
        },
        onError: (error: AxiosError) => {
            errorToast(
                create ? "Create Form" : "Update Form",
                error.message ? error.message : create ? "Form create failed" : "Form updated failed",
            );
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        printMutation.mutate();
    };

    const formTemplateQuery = useQuery({
        queryKey: [QueryKeys.FORM_TEMPLATE, state.formTemplateId],
        queryFn: () => {
            if (!state.formTemplateId) return null;

            return FormApi.getFormTemplate(parseInt(state.formTemplateId));
        },
        enabled: !!state.formTemplateId && state.formTemplateId !== "0",
    });

    const handlePrint = (params: { representativeId: number; formTemplateId: number; size: number }) => {
        if (!params.representativeId) {
            printJS({
                printable: `/api/Form/CreateFormPrintBatches?formTemplateId=${params.formTemplateId}&size=${params.size}`,
                type: "pdf",
                showModal: true,
                onLoadingEnd: () => {
                    queryClient.invalidateQueries({ queryKey: [QueryKeys.FORMS] }).catch((error: AxiosError) => {
                        errorToast("Create Form", error.message ? error.message : "Form create failed");
                    });
                },
            });
        } else {
            printJS({
                printable: `/api/Form/CreateFormPrintBatches?representativeId=${params.representativeId}&formTemplateId=${params.formTemplateId}&size=${params.size}`,
                type: "pdf",
                showModal: true,
                onLoadingEnd: () => {
                    queryClient.invalidateQueries({ queryKey: [QueryKeys.FORMS] }).catch((error: AxiosError) => {
                        errorToast("Create Form", error.message ? error.message : "Form create failed");
                    });
                },
            });
        }
    };

    return (
        <form className="form-dialog" onSubmit={handleSubmit}>
            <CampaignDropdown
                value={state.campaignId}
                selectVal={(val: SelectOption) =>
                    setState({
                        ...state,
                        formTemplateId: "",
                        representativeId: "",
                        campaignId: val.value as number,
                    })
                }
                required
                chooseFirst
            />
            {state.campaignId ? (
                <FormTemplatesDropdown
                    key={`form-templates-${state.campaignId}`}
                    campaignId={state.campaignId}
                    value={state.formTemplateId}
                    selectVal={(val: SelectOption) => {
                        setState({
                            ...state,
                            formTemplateId: val.value.toString(),
                        });
                    }}
                    required
                    chooseFirst
                />
            ) : null}
            {state.campaignId ? (
                <RepsDropdown
                    key={`reps-${state.campaignId}`}
                    campaignIds={[state.campaignId]}
                    value={state.representativeId}
                    selectVal={(val: SelectOption) => {
                        setState({
                            ...state,
                            representativeId: val.value.toString(),
                        });
                    }}
                    required={formTemplateQuery.data?.repSpecific ?? false}
                    chooseFirst
                />
            ) : null}
            {state.campaignId ? (
                <TextBox
                    id="form-batch-size"
                    label="Size"
                    type="number"
                    value={state.size}
                    changeHandler={(value: string) => {
                        setState({
                            ...state,
                            size: value,
                        });
                    }}
                />
            ) : null}
            <div className="mt-4 grid">
                <div className="grid grid-cols-2 gap-2">
                    <Button
                        isLoading={printMutation.isPending}
                        type="button"
                        variant="secondary"
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button isLoading={printMutation.isPending} type="submit" variant="default">
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
};
