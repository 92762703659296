import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { App } from "./app";
import "./index.css";

export default function render() {
    const rootElement = document.getElementById("root");
    if (!rootElement) return; // This should not happen
    const root = createRoot(rootElement);
    root.render(
        <StrictMode>
            <App />
        </StrictMode>,
    );
}
render();
