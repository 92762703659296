import printJS from "print-js";
import { type FC } from "react";

import { type FormPrintBatchDto } from "../../models";
import { Button } from "../basic";

interface SelectedActionsProps {
    forms: FormPrintBatchDto[];
}

export const SelectedActions: FC<SelectedActionsProps> = (props) => {
    const handlePrint = (forms: FormPrintBatchDto[]) => {
        const formParams = forms.map((form) => `formPrintBatchIds=${form.id ?? ""}`).join("&");

        printJS({
            printable: `/api/Form/GetFormPrintBatches?${formParams}`,
            type: "pdf",
            showModal: true,
        });
    };

    return (
        <div className="flex gap-2 p-4">
            <Button variant="secondary" onClick={() => handlePrint(props.forms)}>
                Print
            </Button>
        </div>
    );
};
