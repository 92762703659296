import { type FC } from "react";

import { type AuditScanResponseDto } from "../../models";
import { AuditPanels } from "./auditPanels";
import { AuditQuestionnaire } from "./auditQuestionnaire";
import { MissingTpv } from "./missingTpv";

interface SingleAuditProps {
    batchId: number;
    results: AuditScanResponseDto | undefined;
    barcode: string;
    setResults: (results: AuditScanResponseDto | undefined) => void;
    setBarcode: (barcode: string) => void;
}

export const SingleAudit: FC<SingleAuditProps> = ({ batchId, results, barcode, setBarcode, setResults }) => {
    if (!results) return null;

    return (
        <>
            <MissingTpv results={results} />
            <AuditQuestionnaire
                results={results}
                barcode={barcode}
                batchId={batchId}
                setBarcode={setBarcode}
                setResults={setResults}
                multi={false}
            />
            <AuditPanels results={results} />
        </>
    );
};
