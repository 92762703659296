import { type FC } from "react";

import { TextSkeleton } from "./textSkeleton";

export const ChartSkeleton: FC = () => {
    return (
        <div className="card">
            <TextSkeleton />
            <div className="mt-2 h-[380px] w-full animate-pulse rounded bg-gray-300 dark:bg-gray-700" />
            <span className="sr-only">loading</span>
        </div>
    );
};
