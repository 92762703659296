import { type FC } from "react";

import AuditGrid from "../components/audit/auditGrid";
import { useRoutePermissions } from "../helpers";
import { UserPermissionFlags } from "../models/permissions";

export const StartAuditPage: FC = () => {
    useRoutePermissions(UserPermissionFlags.FormsAudit);

    return <AuditGrid />;
};
