import { Severity, logger } from "../helpers";
import {
    type EventDto,
    type FileLinkDto,
    type FilterDto,
    type GridResult,
    type PhoneVerificationDto,
    type VerificationDto,
    type VerificationFilter,
    type WebVerificationDto,
} from "../models";
import { doDownload, doDownloadPost, doGet, doPost } from "./httpHandler";

const controller = "/api/Verifications";

export class VerificationsApi {
    public static getVerifications = (
        signal: AbortSignal,
        itemsPerPage = 10,
        currentPage = 1,
        filters?: Partial<VerificationFilter>,
    ) => {
        try {
            return doPost<GridResult<VerificationDto>, Partial<VerificationFilter>>(
                controller,
                "GetAll",
                { itemsPerPage, currentPage },
                filters,
                { signal },
            );
        } catch (e) {
            logger(Severity.Error, e);
        }
    };

    public static getAdditionalEvents = (verificationGuid: string) =>
        doGet<EventDto[]>(controller, "GetAdditionalEvents", { verificationGuid });

    public static getWebVerification = (verificationGuid: string) => {
        try {
            return doGet<WebVerificationDto>(controller, "GetWebVerification", { verificationGuid });
        } catch (e) {
            logger(Severity.Error, e);
        }
    };

    public static getPhoneVerification = (verificationGuid: string) => {
        try {
            return doGet<PhoneVerificationDto>(controller, "GetPhoneVerification", { verificationGuid });
        } catch (e) {
            logger(Severity.Error, e);
        }
    };

    public static exportVerifications = (filters?: Partial<VerificationFilter>) => {
        try {
            return doPost<string, Partial<VerificationFilter>>(controller, "ExportCsv", undefined, filters);
        } catch (e) {
            logger(Severity.Error, e);
        }
    };

    public static getDispositions = (filters?: Partial<VerificationFilter>) => {
        try {
            return doPost<FilterDto[], Partial<VerificationFilter>>(controller, "GetDispositions", undefined, filters);
        } catch (e) {
            logger(Severity.Error, e);
        }
    };

    public static getFiles = (verificationGuid: string) =>
        doGet<FileLinkDto[]>(controller, "GetFiles", { verificationGuid });

    public static getZippedFiles = (verificationGuid: string) =>
        doGet<File>(controller, "GetFilesZip", { verificationGuid });

    public static downloadFilesZip = (verificationGuid: string, id: number) =>
        doDownload(controller, "GetFilesZip", `${id}.zip`, { verificationGuid });

    public static downloadAllFilesZip = (filters?: Partial<VerificationFilter>) =>
        doDownloadPost(controller, "ExportFilesZip", `AVL File Export.zip`, {}, filters);
}
