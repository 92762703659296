import { type FC } from "react";

import { type ReportingFilter, type RepresentativeDto } from "../../../models";
import { NoSaleDispositionsWidget } from "../../reporting/historical/noSaleDispositions";
import { SalesOutcomeWidget } from "../../reporting/historical/salesOutcome";

interface RepWidgetGraphsProps {
    rep: RepresentativeDto;
}

export const RepWidgetGraphs: FC<RepWidgetGraphsProps> = ({ rep }) => {
    const filter: ReportingFilter = {
        campaigns: [],
        channels: [],
        clients: [],
        endDate: undefined,
        language: undefined,
        representativeId: rep.id,
        startDate: undefined,
        states: [],
        verMode: undefined,
        verType: undefined,
        startTime: undefined,
        endTime: undefined,
        location: undefined,
        locationWithinFeet: undefined,
        includesDataEndDate: undefined,
        includesDataStartDate: undefined,
    };

    return (
        <div className="grid grid-cols-1 gap-2 py-2 sm:grid-cols-2">
            <SalesOutcomeWidget filterData={filter} key={`sales-${rep.id}`} />
            <NoSaleDispositionsWidget filterData={filter} key={`no-sales-${rep.id}`} />
        </div>
    );
};
