import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { type FC, type FormEvent, useState } from "react";

import { FormApi } from "../../api/formApi";
import { QueryKeys } from "../../helpers";
import { useReplaceVerbiage } from "../../hooks/verbiageHooks";
import { type FormTemplateDto } from "../../models/formTemplateDto";
import {
    Button,
    Checkbox,
    DatePicker,
    Dropzone,
    TextBox,
    ToggleButton,
    useErrorToast,
    useSuccessToast,
} from "../basic";

interface State {
    activeStart: Date | undefined;
    activeEnd: Date | undefined;
    repSpecific: boolean;
    active: boolean;
    frontPages: number;
    backPages: number;
    lineBarcodesInvisible: boolean;
    notes: string;
}

interface UpdateFormTemplateProps {
    formTemplate: FormTemplateDto;
    setOpen: (open: boolean) => void;
    create: boolean;
}

export const CreateUpdateFormTemplate: FC<UpdateFormTemplateProps> = ({ formTemplate, setOpen, create }) => {
    const [state, setState] = useState<State>({
        activeStart: formTemplate.activeStart ? formTemplate.activeStart : undefined,
        activeEnd: formTemplate.activeEnd ? formTemplate.activeEnd : undefined,
        repSpecific: formTemplate.repSpecific ?? false,
        active: formTemplate.active ?? true,
        frontPages: formTemplate.frontPages ?? 0,
        backPages: formTemplate.backPages ?? 0,
        lineBarcodesInvisible: formTemplate.lineBarcodesInvisible ?? false,
        notes: formTemplate.notes ?? "",
    });
    const [file, setFile] = useState<File[]>([]);
    const queryClient = useQueryClient();
    const successToast = useSuccessToast();
    const errorToast = useErrorToast();
    const replaceVerbiage = useReplaceVerbiage();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        templateMutation.mutate({
            ...state,
            id: formTemplate.id,
            campaignId: formTemplate.campaignId,
            formPdf: file.at(0),
            active: state.active,
            repSpecific: state.repSpecific,
            lineBarcodesInvisible: state.lineBarcodesInvisible,
            activeStart: state.activeStart,
            activeEnd: state.activeEnd,
        } as FormTemplateDto);
    };

    const templateMutation = useMutation({
        mutationFn: FormApi.saveFormTemplate,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.CAMPAIGNS] });
            setOpen(false);
            successToast(
                create ? "Create Form Template" : "Update Form Template",
                create ? "Form Template created successfully" : "Form Template updated successfully",
            );
        },
        onError: (error: AxiosError) => {
            errorToast(
                create ? "Create Form Template" : "Update Form Template",
                error.message ? error.message : create ? "Form Template create failed" : "Form Template updated failed",
            );
        },
    });

    return (
        <form className="form-dialog" onSubmit={handleSubmit}>
            {create ? (
                <Dropzone
                    accept={{ "application/pdf": [] }}
                    maxFiles={1}
                    files={file}
                    setFiles={(newFile) => (newFile[0] ? setFile([newFile[0]]) : setFile([]))}
                />
            ) : null}
            <ToggleButton
                label="Active"
                values={[
                    { id: false, value: "Inactive" },
                    { id: true, value: "Active" },
                ]}
                value={state.active}
                changeHandler={(value: boolean) => setState({ ...state, active: value })}
                id="active"
            />
            <div className="grid grid-cols-2 gap-2">
                <DatePicker
                    id="activeStart"
                    label="Active Start"
                    setValue={(value?: Date) => setState({ ...state, activeStart: value })}
                    value={state.activeStart}
                />
                <DatePicker
                    id="activeEnd"
                    label="Active End"
                    setValue={(value?: Date) => setState({ ...state, activeEnd: value })}
                    value={state.activeEnd}
                />
            </div>
            {create ? (
                <Checkbox
                    id="repSpecific"
                    label={replaceVerbiage("Rep", "Rep Specific")}
                    changeHandler={(value: boolean) => {
                        setState({ ...state, repSpecific: value });
                    }}
                    checked={state.repSpecific}
                />
            ) : null}
            {create ? (
                <div className="grid grid-cols-2 gap-2">
                    <TextBox
                        id="frontPages"
                        label="Front Pages"
                        value={state.frontPages}
                        changeHandler={(value: number) => {
                            setState({ ...state, frontPages: value });
                        }}
                        required
                    />
                    <TextBox
                        id="backPages"
                        label="Back Pages"
                        value={state.backPages}
                        changeHandler={(value: number) => {
                            setState({ ...state, backPages: value });
                        }}
                        required
                    />
                </div>
            ) : null}
            {create ? (
                <Checkbox
                    id="lineBarcodesInvisible"
                    label="Line Barcodes Invisible"
                    changeHandler={(value: boolean) => {
                        setState({ ...state, lineBarcodesInvisible: value });
                    }}
                    checked={state.lineBarcodesInvisible}
                />
            ) : null}
            <TextBox
                id="notes"
                label="Notes"
                value={state.notes}
                changeHandler={(value: string) => {
                    setState({ ...state, notes: value });
                }}
            />
            <div className="mt-4 grid">
                <div className="grid grid-cols-2 gap-2">
                    <Button
                        isLoading={templateMutation.isPending}
                        type="button"
                        variant="secondary"
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button isLoading={templateMutation.isPending} type="submit" variant="default">
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
};
