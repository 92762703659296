import {
    BriefcaseIcon,
    ChartPieIcon,
    CheckCircleIcon,
    DocumentIcon,
    FolderIcon,
    HomeIcon,
    UserGroupIcon,
    UsersIcon,
} from "@heroicons/react/24/outline";
import { type FC, type ReactNode, useContext, useMemo, useState } from "react";

import { UserContext } from "../../contexts/userContext";
import { RouteValues } from "../../helpers";
import { useReplaceVerbiage } from "../../hooks/verbiageHooks";
import { UserPermissionFlags } from "../../models/permissions";
import { Header } from "./header";
import { MobileSidebar } from "./mobileSidebar";
import { Sidebar } from "./sidebar";
import { type Route } from "./types";

interface LayoutProps {
    children: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
    const { hasPermission } = useContext(UserContext);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const replaceVerbiage = useReplaceVerbiage();

    const routes: Route[] = useMemo(() => {
        const route = (name: string, route: string, IconType: ReactNode) => {
            return {
                name,
                base: route,
                href: `/${route}`,
                icon: IconType,
            } as Route;
        };

        const routeWithPermission = (
            permission: UserPermissionFlags,
            name: string,
            base: string,
            IconType: ReactNode,
        ) => {
            if (!hasPermission(permission)) return undefined;
            return route(name, base, IconType);
        };

        return [
            route("Home", RouteValues.HOME, <HomeIcon className="w-6 shrink-0" />),
            routeWithPermission(
                UserPermissionFlags.ReportsView,
                "Reports",
                RouteValues.REPORTS,
                <ChartPieIcon className="w-6 shrink-0" />,
            ),
            routeWithPermission(
                UserPermissionFlags.FormsScan,
                "Check In/Out",
                RouteValues.CHECK_IN,
                <DocumentIcon className="w-6 shrink-0" />,
            ),
            routeWithPermission(
                UserPermissionFlags.FormsAudit,
                "Audit",
                RouteValues.AUDIT,
                <CheckCircleIcon className="w-6 shrink-0" />,
            ),
            routeWithPermission(
                UserPermissionFlags.RepresentativesCreate,
                replaceVerbiage("Rep", "Reps"),
                RouteValues.REPS,
                <UserGroupIcon className="w-6 shrink-0" />,
            ),
            routeWithPermission(
                UserPermissionFlags.FormsPrint,
                "Forms",
                RouteValues.FORMS,
                <FolderIcon className="w-6 shrink-0" />,
            ),
            routeWithPermission(
                UserPermissionFlags.UserManage,
                "Users",
                RouteValues.USERS,
                <UsersIcon className="w-6 shrink-0" />,
            ),
            routeWithPermission(
                UserPermissionFlags.CampaignsManage,
                "Campaigns",
                RouteValues.CAMPAIGNS,
                <BriefcaseIcon className="w-6 shrink-0" />,
            ),
        ].filter(Boolean) as Route[];
    }, [hasPermission, replaceVerbiage]);

    return (
        <>
            <Header setSidebarOpen={setSidebarOpen} />
            <div className="layout flex flex-row">
                <MobileSidebar routes={routes} setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
                <Sidebar routes={routes} />
                <div className="h-full w-full bg-gray-200 dark:bg-gray-900 md:px-2 lg:pl-28 lg:pr-2">
                    <div className="h-full flex-1 overflow-y-auto rounded-lg bg-gray-100 dark:bg-gray-800">
                        <main className="container h-full px-2 py-8 md:px-4 lg:px-8">{children}</main>
                    </div>
                </div>
            </div>
        </>
    );
};
