import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { ReportingApi } from "../../api/reportingApi";
import { QueryKeys } from "../../helpers";
import { type ReportingFilter } from "../../models";
import { type DropdownProps, FilterDropdown } from "./filterDropDown";

interface Props extends DropdownProps {
    filterData: ReportingFilter;
}

export const DistrictsDropdown: FC<Props> = (props) => {
    const query = useQuery({
        queryKey: [QueryKeys.DISTRICTS_FILTER],
        queryFn: async () => {
            const goals = await ReportingApi.getCampaignGoalProgress(props.filterData);

            return goals.map((goal) => ({ label: goal.boundary, value: goal.boundary }));
        },
    });

    return <FilterDropdown id="districts" {...props} query={query} chooseFirst />;
};
